import axios from "axios"
import { URL_API } from "../config/connection";

const soap = (method, url, {
  xml,
  timeout = 0,
  headers = {}
} = {}) => {
  return new Promise(async (resolve, reject) => {
    const bodyRequest = (`
      <soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:phon="http://phonecash.pranasys.com/">
        <soapenv:Header/>
        <soapenv:Body>
        ${xml}
        </soapenv:Body>
      </soapenv:Envelope>
    `).split("\n").join("").split("\t").join("")
    axios({
      method: 'POST',
      url: `${URL_API}${url}`,
      headers: {
        'Content-Type': 'application/soap+xml',
        'charset': 'UTF-8',
        'action': 'http://phonecash.pranasys.com/' + method,
        ...headers
      },
      timeout,
      data: bodyRequest
    }).then((response) => {
      console.log('RESPONSE:', response);
      resolve(response.data)
    }).catch((error) => {
      console.log('ERROR:', error);
      reject(error?.response?.data)
    })
  })
}

export default soap;