import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { MenuPanel, WorkArea } from '../components';
import { setSession } from '../redux/slices/sessionSlice';
import { getUserLoginService, refreshSessionTimeService } from '../services/security/userServices';
import './MainScreen.css'
import { setTitle } from '../redux/slices/uiSlice';
import { Grid, Icon } from '@mui/material';
import menu from '../helpers/menu';
import { backgroundColor, menuColor } from '../config/theme';
import Image from '../assets/images';

const MainScreen = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { title, sidebar, loading } = useSelector(store => store.ui)
  const [icons, setIcons] = useState(null)
  const [icon, setIcon] = useState(null)

  useEffect(() => {
    if (location.search === '?session_expired')
      return;
    if (location.pathname === '/') {
      dispatch(setTitle(null))
      setIcon(null)
    }
    getUserLoginService().then((resp) => {
      dispatch(setSession(resp));
    })
    processIcons();
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    const icon = icons?.[title]
    if (icon)
      setIcon(icon)
    // eslint-disable-next-line
  }, [title, icons])

  const processIcons = () => {
    const icons = {}
    menu.forEach(({ name, icon, list }) => {
      icons[name] = icon
      if (list) {
        list.forEach(({ name, icon, iconTitle, list }) => {
          icons[name] = iconTitle || icon
          if (list) {
            list.forEach(({ name, icon, iconTitle }) => {
              icons[name] = iconTitle || icon
            })
          }
        })
      }
    })
    setIcons(icons)
  }

  const handlerUpdateSessionTime = () => {
    refreshSessionTimeService().then((token) => {
      dispatch(setSession({ token }));
    })
  }

  return (
    <Grid container sx={{ height: '100%' }} onClick={handlerUpdateSessionTime}>
      <Grid item xs={3} sx={{ display: sidebar ? 'block' : 'none', overflow: 'auto', backgroundColor: menuColor }}>
        <MenuPanel />
      </Grid>
      <Grid item xs={sidebar ? 9 : 12} sx={{ backgroundColor: backgroundColor }}>
        <WorkArea>
          {
            title && <div className='title-screen'>
              <Icon>{icon}</Icon>
              {title}
            </div>
          }
          <Outlet />
        </WorkArea>
      </Grid>
      {
        loading && (
          <div className='loading-screen'>
            <img src={Image.icon.loading} alt='loading' />
          </div>
        )
      }
    </Grid>
  )
}

export default MainScreen;
