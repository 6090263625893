import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  users: [],
  current: null,
};

export const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUsers: (state, action) => ({
      ...state,
      users: action.payload,
    }),
    addUser: (state, action) => ({
      ...state,
      users: [...state.users, action.payload],
    }),
    updateUser: (state, action) => ({
      ...state,
      users: state.users.map((user) => {
        if (user.id === action.payload.id)
          return { ...user, ...action.payload.data };
        return user;
      }),
    }),
    removeUser: (state, action) => ({
      ...state,
      users: state.users.filter((user) => {
        return user.id !== action.payload;
      }),
    }),
    setCurrentUser: (state, action) => ({
      ...state,
      current: action.payload,
    })
  },
});

export const {
  setUsers,
  addUser,
  updateUser,
  removeUser,
  setCurrentUser
} = userSlice.actions;

export default userSlice.reducer;
