import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    categories: [],
}

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    setCategories: (state, action) => ({
      ...state,
      categories: action.payload
    }),
    createCategory: (state, action) => ({
      ...state,
      ...action.payload,
    })
  }
})

export const { setCategories, createCategory } = categoriesSlice.actions

export default categoriesSlice.reducer

