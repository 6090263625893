import { Checkbox, FormControlLabel, FormHelperText, Grid, InputLabel } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

const CheckboxListFieldGrid = ({ xs, name, control, required, label, style, disabled = false, list, direction = 'row', spacing = 0 }) => {
  return (
    <Grid
      item
      xs={xs}
      alignContent="center"
      justifyContent={"center"}
      style={{ ...style, width: "100%" }}
    >
      <InputLabel className="title" sx={{ marginBottom: 2 }}>{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        rules={!disabled && { required }}
        render={({
          field: { value, onChange },
          fieldState: { error },
        }) => (
          <>
            <Grid container alignContent={'center'} justifyContent={"space-evenly"} flexDirection={direction} spacing={spacing}>
              {
                list.map(({ label, name }) =>
                  <Grid item>
                    <FormControlLabel
                      label={label}
                      control={
                        <Checkbox
                          checked={value?.includes(name)}
                          onChange={(e) => {
                            onChange(e.target.checked ? [...value, name] : value.filter((item) => item !== name))
                          }}
                        />
                      }
                    />
                  </Grid>
                )
              }
            </Grid>
            {
              error && <FormHelperText sx={{ color: '#d32f2f' }}>{error.message}</FormHelperText>
            }
          </>
        )}
      />
    </Grid>
  );
};

export default CheckboxListFieldGrid;
