import React, { useState } from "react";
import { CustomButton, FilterBar, TableData } from "../../components";
import DownloadIcon from "@mui/icons-material/Download";
import { useDispatch } from "react-redux";
import { errorAlert } from "../../helpers/alerts";
import moment from "moment";
import { currencyFormatter, downloadFile } from "../../helpers/util";
import { downloadDailyTotalReportService, getDailyTotalReportService } from "services/report/reportService";

const DailyTotalReportScreen = () => {
  const dispatch = useDispatch();
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingDownload, setLoadingDownload] = useState(false)
  const [downloadFilters, setDownloadFilters] = useState({})

  const handlerSubmit = async (data) => {
    setLoading(true)
    setDownloadFilters(data)
    getDailyTotalReportService(data).then((result) => {
      setList(result)
    }).catch((error) => errorAlert(error))
      .finally(() => setLoading(false));
  }

  const handlerDownload = async () => {
    setLoadingDownload(true)
    downloadDailyTotalReportService(downloadFilters)
      .then((data) => downloadFile(data, 'Reporte de Saldos Diario.xlsx'))
      .catch((e) => errorAlert(e))
      .finally(() => setLoadingDownload(false));
  }

  return (
    <>
      <FilterBar
        handlerFilter={handlerSubmit}

        defaultValues={{
          date: moment().format('YYYY-MM-DD'),
        }}
        allValue={null}
        filters={[
          {
            name: "date",
            label: "Fecha",
            type: "date",
          },
        ]}
      />
      <TableData
        loading={loading}
        dataRows={list}
        headerButtons={[
          <CustomButton
            icon={<DownloadIcon />}
            key="dowload"
            name={loadingDownload ? 'Descargando...' : "Descargar"}
            click={!loadingDownload && handlerDownload}
          />,
        ]}
        dataTitle={[
          'Comercio',
          'Bolsa',
          'Saldo',
        ]}
        formatter={(data) => [
          data.hoster?.username,
          data.balance_bag?.hashtag,
          currencyFormatter.format(data.balance),
        ]}
      />
    </>
  );
};

export default DailyTotalReportScreen;
