import { Button, Grid, Checkbox, FormControlLabel } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { validateEmptyFields } from '../../helpers/util'
import BackButton from 'components/BackButton'
import InputTextFieldGrid from 'components/common/InputTextFieldGrid'
import InputSelectFieldGrid from 'components/common/InputSelectFieldGrid'

const _operadoras = [
  "61d489e27c8f1974cefd7350",
  "622295933fce972ce655ec75",
  "622295b13fce972ce655ec77",
  "64a87148e63756d4c6b26dcf",
  "64a8715ce63756d4c6b26dd2",
  "658c626b764fd40a7a4a687f",
  "658c8570764fd40a7a4a688b",
  "658c8624764fd40a7a4a6891"
];

const _operadorasNames = [
  "TELCEL",
  "MOVISTAR",
  "ATT",
  "UNEFON",
  "BAIT",
  "VIRGIN",
  "OUI",
  "FREEDOM"
];

const _regiones = [
  "615ded2ba5d9ae881474d222",
  "615ded95a5d9ae881474d2a1",
  "615ded9ca5d9ae881474d2ad",
  "61609394a5d9ae8814772b6b",
  "616093a1a5d9ae8814772b7d",
  "616093a8a5d9ae8814772b86",
  "616093aea5d9ae8814772b91",
  "616093b4a5d9ae8814772b98",
  "616093bba5d9ae8814772ba4"
];

const CredsProviderForm = ({ title, goBack, defaultValues, submit, validate }) => {
  const { control, handleSubmit } = useForm({ defaultValues });
  const watch = useWatch({ control });
  const [disabled, setDisabled] = useState(true);

  const [operadoras, setOperadoras] = useState(defaultValues['operadoras'] || []);
  const [regiones, setRegiones] = useState(defaultValues['regiones'] || []);

  useEffect(() => {
    setDisabled(validateEmptyFields(watch, { validate }));
    // eslint-disable-next-line
  }, [watch]);

  const _renderRegiones = () => {
    if (operadoras.indexOf(_operadoras[0]) > -1) {
      return (
        <fieldset style={{ width: '100%' }}>
          <legend>Regiones</legend>
          {
              _regiones.map((region, i) => {
              return (
                <>
                  <FormControlLabel
                    label={"Region " + (i+1)}
                    control={<Checkbox
                    onChange={() => {
                      let reg = [ ...regiones ];
                      const index = reg.indexOf(region);
                      if (index > -1) {
                        reg.splice(index, 1);
                      } else {
                        reg.push(region);
                      }
                      setRegiones(reg);
                    }}
                    defaultChecked={regiones.indexOf(region) > -1}
                  />} />
                  <br/>
                </>
              );
            })
          }
        </fieldset>
      );
    }
  }

  return (
    <>
      <BackButton name={title} click={goBack} />
      <Grid container direction="column" justifyContent="center" alignItems="center" >
        <Grid container direction="column" m={"40px"} maxWidth={"40%"} justifyContent="center" alignItems="center" spacing={3}>
          <InputTextFieldGrid
            name="name_connector"
            label="Nombre descriptivo"
            required="Debes agregar el nombre descriptivo"
            control={control}
          />
          <InputTextFieldGrid
            name="client_id"
            label="Client Id"
            required="Debes agregar el client id"
            control={control}
          />
          <InputTextFieldGrid
            name="user_name"
            label="Nombre de Usuario"
            required="Debes agregar el nombre de usuario"
            control={control}
          />
          <InputTextFieldGrid
            name="password_digest"
            label="Contraseña"
            required="Debes agregar la contraseña"
            inputType={"password"}
            control={control}
          />
          <InputSelectFieldGrid
            name="active"
            label="Disponible"
            required="Debes seleccionar si requiere conciliación"
            control={control}
            data={[
              { id: 1, name: 'Activa' },
              { id: 2, name: 'Inactiva' }
            ]}
          />
          <InputSelectFieldGrid
            name="conciliacion"
            label="Conciliación"
            required="Debes seleccionar si requiere conciliación"
            control={control}
            data={[
              { id: 1, name: 'Activa' },
              { id: 2, name: 'Inactiva' }
            ]}
          />
          <fieldset style={{ width: '100%' }}>
            <legend>Operadoras</legend>
            {
              _operadoras.map((operadora, i) => {
                return (
                  <>
                    <FormControlLabel 
                      label={_operadorasNames[i]}
                      control={<Checkbox
                      onChange={() => {
                        let ops = [ ...operadoras ];
                        const index = ops.indexOf(operadora);
                        if (index > -1) {
                          ops.splice(index, 1);
                        } else {
                          ops.push(operadora);
                        }
                        setOperadoras(ops);
                      }}
                      defaultChecked={operadoras.indexOf(operadora) > -1}
                    />} />
                    <br/>
                  </>
                );
              })
            }
          </fieldset>
          { _renderRegiones() }
        </Grid>
        <Grid item justifyContent="center" alignItems="center" >
          <Button
            sx={{ m: 2, fontWeight: 'bold', backgroundColor: '#0E73ED' }}
            onClick={handleSubmit((data) => {
              data['operadoras'] = operadoras;
              data['regiones'] = regiones;
							return submit(data);
						})}
            disabled={disabled}
            variant="contained"
          >Guardar</Button>
        </Grid>
      </Grid>
    </>
  )
}

export default CredsProviderForm