import { AddCircleOutline } from '@mui/icons-material';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CustomButton, TableData } from '../../../components'
import { confirmAlert, errorAlert } from '../../../helpers/alerts';
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { getAlertsService, removeAlertService, updateAlertService } from 'services/admin/alertsService';
import { removeAlert, setAlerts, setCurrentAlert, updateAlert } from 'redux/slices/admin/alertSlice';
import moment from 'moment';

const AlertsScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { alerts } = useSelector(store => store.alert);

  useEffect(() => {
    getAlertsService().then((result) => {
      dispatch(setAlerts(result))
    }).catch(error => errorAlert(error))
    // eslint-disable-next-line
  }, []);

  const handlerClick = () => {
    navigate('crear')
  }

  const handlerEdit = (row) => {
    dispatch(setCurrentAlert(row));
    navigate("editar");
  };

  const handlerDelete = ({ id, name }) => {
    confirmAlert({
      title: "Estas seguro que deseas eliminar esta alerta?",
      text: `La alerta ${name} será eliminado.`,
      onConfirm: async () => {
        removeAlertService(id).then(() => {
          dispatch(removeAlert(id));
        }).catch(error => errorAlert(error))
      }
    })
  };

  const handlerCheck = async ({ id }, currentValue) => {
    const value = !currentValue;
    updateAlertService(id, { state: value }).then(({ state, updatedAt }) => {
      dispatch(updateAlert({ id, data: { state, updatedAt } }));
    }).catch(error => errorAlert(error))
  };

  return (
    <TableData
      dataRows={alerts}
      headerButtons={[
        <CustomButton
          icon={<AddCircleOutline fontSize="large" />}
          key="add"
          name={"Alerta"}
          click={handlerClick}
        />,
      ]}
      dataTitle={[
        "Nombre",
        "Tipo",
        "Transacciones",
        "Emails",
        "Estado",
        "Activacion/Desactivacion"
      ]}
      formatter={({ name, txnState, txns, emails, state, updatedAt }) => [
        name,
        txnState === "approved" ? "APROBADAS" : "DENEGADAS",
        txns,
        emails.join("\n\r"),
        { name: "state", type: "boolean", value: state },
        moment(updatedAt).format('DD/MM/YYYY HH:mm:ss '),
      ]}
      onChecked={handlerCheck}
      actions={[
        {
          label: "Editar",
          icon: <EditIcon fontSize="medium" />,
          onClick: (data) => handlerEdit(data)
        },
        {
          label: "Eliminar",
          icon: <DeleteForeverIcon fontSize="medium" />,
          onClick: (data) => handlerDelete(data)
        },
      ]}
    />

  )
}

export default AlertsScreen