import './IconUser.css'

function IconUser({ name = 'N' }) {
	const first = name.substr(0, 1);
	name = first.toUpperCase();
	return (
		<div className="iconUser">
			<span>{name}</span>
		</div>
	);
}

export default IconUser;
