import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  admins: [],
  current: null,
};

export const userSlice = createSlice({
  name: "admins",
  initialState,
  reducers: {
    setAdmins: (state, action) => ({
      ...state,
      admins: action.payload,
    }),
    addAdmin: (state, action) => ({
      ...state,
      admins: [...state.admins, action.payload],
    }),
    updateAdmin: (state, action) => ({
      ...state,
      admins: state.admins.map((user) => {
        if (user.id === action.payload.id)
          return { ...user, ...action.payload.data };
        return user;
      }),
    }),
    removeAdmin: (state, action) => ({
      ...state,
      admins: state.admins.filter((user) => {
        return user.id !== action.payload;
      }),
    }),
    setCurrentAdmin: (state, action) => ({
      ...state,
      current: action.payload,
    })
  },
});

export const {
  setAdmins,
  addAdmin,
  updateAdmin,
  removeAdmin,
  setCurrentAdmin
} = userSlice.actions;

export default userSlice.reducer;
