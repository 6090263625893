import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";
import { InputSelectFieldGrid, TableData } from "../../../components";
import { getProviderCredentialsService, getTodayProviderTransactions } from "../../../services/admin/providerService";
import { setCredentials } from "../../../redux/slices/admin/providerSlice";
import { errorAlert } from "../../../helpers/alerts";
import { setDetailedReportFilters } from "redux/slices/report/reportSlice";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

const MonitorProviderTransactionsScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const { token } = useSelector(store => store.session)
  const { credentials } = useSelector((store) => store.providers);
  const { control, watch } = useForm({ defaultValues: { time: 10 } });
  const [time, setTime] = useState(10)

  useEffect(() => {
    getProviderCredentialsService()
      .then(result => dispatch(setCredentials(result)))
      .catch(error => errorAlert(error))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (token) {
      if (watch('time') === time) {
        setTime(0);
        getTodayProviderTransactions().then((response) => {
          setList(response.filter((({ serviceName }) => serviceName)).reduce(function (result, transaction) {
            const credential = credentials.find(({ id }) => id === transaction.serviceCredId);
            const key = credential?.name_connector
            if (!key) return result;
            if (!result[key])
              result[key] = {
                credential,
                transactions: []
              }
            result[key].transactions.push(transaction)
            return result;
          }, {}))
        }).finally(() => {
          setLoading(false)
        })
      }
      const interval = setInterval(() => {
        setTime(time + 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [time, credentials]);

  useEffect(() => {
    setTime(0);
  }, [watch('time')]);

  const handlerSelectProvider = (credential) => {
    dispatch(setDetailedReportFilters({
      type: 1,
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      credential
    }))
    navigate('/reportes/detallado')
  }

  return (
    <>
      <Grid container >
        <Grid item xs={6}>
          <Typography variant='h6' color='primary' textAlign={'left'} mb={1} pb={0}>
            Se actualizará en {watch('time') - time} segundo{watch('time') - time > 1 ? 's' : ''}
          </Typography>
        </Grid>
        <Grid item container xs={6} justifyContent={"end"}>
          <InputSelectFieldGrid
            xs={3}
            control={control}
            name={'time'}
            label={'Tiempo de actualización'}
            data={[
              { id: 1, name: 1 },
              { id: 5, name: 5 },
              { id: 10, name: 10 },
              { id: 15, name: 15 },
              { id: 30, name: 30 },
              { id: 60, name: 60 },

            ]}
          // onSelect={(value) => onSelect(value, setValue)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        {
          Object.values(list).sort((a, b) => {
            const aName = a.credential?.name_connector;
            const bName = b.credential?.name_connector;
            if (aName < bName) return -1;
            if (aName > bName) return 1;
            return 0;
          }).map(({ credential, transactions }, key) => (
            <Grid item xs={6} md={4} key={key} >
              <Typography
                variant='subtitle1'
                color='primary'
                textAlign={'center'}
                mb={0}
                pb={0}
                onClick={() => handlerSelectProvider(credential.id)}
                style={{ cursor: 'pointer' }}
              >
                {
                  credential?.name_connector
                }
              </Typography>
              <TableData
                loading={loading}
                pagination={false}
                autonumber={false}
                rowsPerPageOpt={10}
                headerStyle={{
                  fontSize: 10,
                  padding: .5,
                }}
                dataRows={transactions.map((item) => ({
                  ...item,
                  style: {
                    color: item.ProviderResponseCode != "00" && 'red',
                    fontSize: 10,
                    padding: .5,
                  }
                }))}
                dataTitle={[
                  "Hora",
                  "Operadora",
                  "Número",
                  "Producto",
                ]}
                formatter={({
                  createdAt,
                  operator,
                  phoneNumber,
                  productId,
                }) => ([
                  moment(createdAt).format('HH:mm:ss'),
                  operator,
                  phoneNumber,
                  productId,
                ])}
              />
            </Grid>
          ))
        }
      </Grid>
    </>
  );
};

export default MonitorProviderTransactionsScreen;
