import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { errorAlert } from '../../../../helpers/alerts';
import { hasSameValues } from '../../../../helpers/util';
import { updatePINSegment } from 'redux/slices/admin/productSlice';
import { updatePINSegmentService } from 'services/admin/productService';
import SegmentProductForm from 'components/admin/SegmentProductForm';
import { setLoading } from 'redux/slices/uiSlice';

const EditSegmentPINScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentPINSegment } = useSelector((store) => store.products);
  const values = {
    name: currentPINSegment.name,
    logo: currentPINSegment.fd
  }

  const handlerSubmit = async (data) => {
    dispatch(setLoading(true))
    updatePINSegmentService(currentPINSegment.id, data)
      .then(response => {
        dispatch(updatePINSegment({
          id: currentPINSegment.id,
          data: response
        }))
        navigate(-1);
      })
      .catch(error => errorAlert(error))
      .finally(() => dispatch(setLoading(false)))
  }

  const validate = (data) => !hasSameValues(values, data)

  return (
    <SegmentProductForm
      title="Editar Segmento"
      goBack={() => navigate(-1)}
      defaultValues={values}
      submit={handlerSubmit}
      validate={validate}
    />
  );
}

export default EditSegmentPINScreen