import { Fade, Grid, Step, StepContent, StepLabel, Stepper } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const SteepsBox = ({ steps, formatter = content => content }) => {
  return (
    <Grid width={"90%"}>
      <Box sx={{ maxWidth: '100%' }}>
        <Stepper orientation="vertical">
          {steps.map((step, index) => (
            <Step key={"step" + index} active={true} expanded={true}>
              <Grid item xs={12} >
                <StepLabel>
                  <b>{step.label}</b>
                </StepLabel>
              </Grid>
              <StepContent TransitionComponent={Fade}>
                <Grid
                  container
                  direction="row"
                  maxWidth={"100%"}
                  // justifyContent="center"
                  alignContent={"center"}
                  spacing={3}
                >
                  {formatter(step.content)}
                </Grid>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>
    </Grid>
  );
};

export default SteepsBox;
