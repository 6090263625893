let theme = 'hebe'
let name = 'HEBE'
let pColor = '#0E73ED'
let sColor = '#30354A'
let mColor = '#30354A'
let bColor = '#FFFFFF'
let iColor = '#FFFFFF'

if (window.location.hostname.includes('marbu')) {
  theme = 'marbu'
  name = 'Marbu'
  pColor = '#D6BA95'
  sColor = '#a56e26'
  mColor = '#D6BA95'
  bColor = '#F6F7E7'
  iColor = '#FFFFFF'

} else if (window.location.hostname.includes('comtodotel')) {
  theme = 'hebe'
  name = 'COMTODOTEL'
  pColor = '#0E73ED'
  sColor = '#30354A'
  mColor = '#30354A'
  bColor = '#FFFFFF'
  iColor = '#FFFFFF'
} else if (window.location.hostname.includes('telemuti')) {
  theme = 'telemuti'
  name = 'TELEMUTI'
  pColor = '#006aeb'
  sColor = '#006aeb'
  mColor = '#d8dfe6'
  bColor = '#FFFFFF'
  iColor = '#0448aa'
}
import(`../assets/styles/${theme}.css`);

export const title = name;
export const primaryColor = pColor;
export const secundaryColor = sColor;
export const menuColor = mColor;
export const menuIcon = iColor;
export const backgroundColor = bColor;
export default theme;