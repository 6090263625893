import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentOperator: null,
  operators: []
}

export const operatorSlice = createSlice({
  name: 'operators',
  initialState,
  reducers: {
    setOperators: (state, action) => ({
      ...state,
      operators: action.payload
    }),
    addOperator: (state, action) => ({
      ...state,
      operators: [
        ...state.operators,
        action.payload
      ]
    }),
    updateOperator: (state, action) => ({
      ...state,
      operators: state.operators.map((operator) => {
        if (operator.id === action.payload.id)
          return { ...operator, ...action.payload.data };
        return operator;
      }),
    }),
    removeOperator: (state, action) => ({
      ...state,
      operators: state.operators.filter((operator) => {
        return operator.id !== action.payload;
      }),
    }),
    setCurrentOperator: (state, action) => ({
      ...state,
      currentOperator: action.payload
    })
  }

})
export const { setOperators, setCurrentOperator, updateOperator, removeOperator, addOperator } = operatorSlice.actions


export default operatorSlice.reducer