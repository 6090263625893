import {
  Button,
  Grid
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { categories } from '../../helpers/menu'
import { validateEmptyFields } from '../../helpers/util'
import { setPermissionTypes } from '../../redux/slices/security/permissionSlice'
import { getPermissionTypeService } from '../../services/security/permissionService'
import BackButton from '../BackButton'
import InputSelectFieldGrid from '../common/InputSelectFieldGrid'
import InputTextFieldGrid from '../common/InputTextFieldGrid'

const PermissionForm = ({ title, goBack, defaultValues, submit, validate }) => {
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(true);
  const { control, handleSubmit } = useForm({ defaultValues });
  const { types } = useSelector((store) => store.permissions);
  const watch = useWatch({ control });

  useEffect(() => {
    (async () => {
      const types = await getPermissionTypeService();
      dispatch(setPermissionTypes(types))
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setDisabled(validateEmptyFields(watch, { validate }));
    // eslint-disable-next-line
  }, [watch]);

  return (
    <>
      <BackButton
        name={title}
        click={goBack}
      />
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          container
          direction="column"
          xs={8}
          m={'40px'}
          maxWidth={"70%"}
          alignContent="center"
          spacing={3}
        >
          <InputTextFieldGrid
            xs={8}
            name={"name"}
            control={control}
            required={"Debes agregar el nombre"}
            label={"Nombre del permiso"}
          />
          <InputSelectFieldGrid
            xs={8}
            name={"category"}
            control={control}
            required={"Debes seleccionar una categoria"}
            label={"Selecciona una categoria"}
            data={categories.map((name) => ({ id: name, name }))}
          />
          <InputSelectFieldGrid
            xs={8}
            name={"type"}
            control={control}
            required={"Debes seleccionar un tipo"}
            label={"Selecciona un tipo"}
            data={types}
          />
        </Grid>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            sx={{ m: 2, fontWeight: 'bold', backgroundColor: '#0E73ED' }}
            onClick={handleSubmit(submit)}
            disabled={disabled}
            variant="contained"
          >Guardar</Button>
        </Grid>
      </Grid>
    </>
  )
}

export default PermissionForm