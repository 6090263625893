import React from 'react'
import { Outlet, useNavigate } from 'react-router-dom';
import { CustomTab } from '../../../components';

const RouteTabScreen = () => {
  const navigate = useNavigate();

  return (
    <CustomTab
      panels={[
        {
          name: "Recargas Celular",
          route: '/ruteo/panel/tae',
          onChange: () => navigate('tae'),
          content: <Outlet />
        },
        {
          name: "Pago de Servicios",
          route: '/ruteo/panel/pds',
          onChange: () => navigate('pds'),
          content: <Outlet />
        },
        {
          name: "Pines Electrónicos",
          route: '/ruteo/panel/pin',
          onChange: () => navigate('pin'),
          content: <Outlet />
        }
      ]}
    />
  );
}

export default RouteTabScreen