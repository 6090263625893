import React from 'react'
import { useNavigate } from 'react-router-dom';
import { PermissionForm } from '../../../components'
import { errorAlert } from '../../../helpers/alerts';
import { createPermissionService } from '../../../services/security/permissionService';

const CreatePermissionScreen = () => {
  const navigate = useNavigate();
  const values = {
    name: "",
    category: "",
  };

  const handlerBack = () => {
    navigate("/seguridad/permisos");
  };

  const handlerSubmit = async (result) => {
    try{
      await createPermissionService(result)
      navigate('/seguridad/permisos')
    }
    catch(error){
      errorAlert(error);
    }
  }

  return (
    <PermissionForm
      title={"Crear Permiso"}
      goBack={handlerBack}
      defaultValues={values}
      submit={handlerSubmit}
    />
  )
}

export default CreatePermissionScreen