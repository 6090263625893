import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LawfulOperatorForm } from "../../../../components";
import { errorAlert } from "../../../../helpers/alerts";
import { addLawfulOperator } from "../../../../redux/slices/admin/lawfulOperatorSlice";
import { createLawfulOperatorService } from "../../../../services/admin/lawfulOperatorService";

const CreateLawfulOperatorScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentOperator } = useSelector((store) => store.operators);
  const values = { name: "", description: "" };

  const handlerBack = () => navigate(-1);

  const handlerSubmit = async (value) => {
    value.state = true;
    try {
      const response = await createLawfulOperatorService(currentOperator.id, value);
      dispatch(addLawfulOperator(response));
      navigate(-1);
    } catch (error) {
      errorAlert(error);
    }
  };

  return (
    <>
      <div className="container-title">
        <div className="title">Operadora: {currentOperator.name} </div>
      </div>
      <div style={{ width: "170px" }}></div>
      <LawfulOperatorForm
        title=" Crear Operadora Legal"
        goBack={handlerBack}
        defaultValues={values}
        submit={handlerSubmit}
      />
    </>
  );
};

export default CreateLawfulOperatorScreen;
