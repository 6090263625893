import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BugReport } from '@mui/icons-material';
import { Modal, Button } from '@mui/material';
import { useSelector } from "react-redux";
import { TableData } from "components";
import { getTransactionsService, getLogsTxnService, getTransactionsIdsService } from "services/panelService";
import { setErrorCodesHistoryIdsProvider } from 'redux/slices/admin/providerSlice';
import { setLogsTxn } from "redux/slices/report/reportSlice";
import { validatePermission } from 'helpers/authentication';

const PanelTransactionsScreen = () => {
  const dispatch = useDispatch();
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [stop, setStop] = useState(false)
  const { token } = useSelector(store => store.session)
  const { logs } = useSelector(store => store.reports)
  const { hebeErrorCodesHistoryListIds } = useSelector(store => store.providers)
  const [isModalOpen, setIsModalOpen] = useState(false)

  let timer;

  useEffect(() => {
    if (isModalOpen && logs.length > 0) {
      let logsWin = window.open("about:blank", "logs", "width=640,height=320");
      logs.map(({ uuid, date, message }) => {
        logsWin.document.write(uuid + " " + date + " " + message + "<br/>");
      });
      setIsModalOpen(false);
    }
  }, [isModalOpen, logs]);

  useEffect(() => {
    if (token) {
      setStop(false)
      setLoading(true)
      console.log("hebeErrorCodesHistoryListIds", hebeErrorCodesHistoryListIds);
      if (hebeErrorCodesHistoryListIds && hebeErrorCodesHistoryListIds.length > 0) {
        getTransactionsIdsService(hebeErrorCodesHistoryListIds).then((response) => {
          console.log("hebeErrorCodesHistoryListIds.response", response);
          dispatch(setErrorCodesHistoryIdsProvider(null));
          setList(response);
        }).finally(() => setLoading(false))
      } else {
        getData();
      }
    }
    return () => {
      setStop(true);
      clearTimeout(timer);
    }
    // eslint-disable-next-line
  }, []);

  const getData = () => {
    getTransactionsService(0, 300).then((response) => {
      setList(response)
      timer = setTimeout(() => {
        if (!stop)
          getData();
      }, 5000);
    }).finally(() => setLoading(false))
  }

  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {/*<Modal open={isModalOpen} onClose={handleClose}>
        <div className="modal-content" style={{backgroundColor:'#FFF', paddingLeft: '20%', paddingRight: '20%', paddingTop: 40, paddingBottom: 40}}>
          <div><h2 style={{marginBottom: -30}}>Logs</h2> <Button onClick={handleClose} style={{float: 'right'}}>Cerrar</Button></div>
          <TableData
            dataRows={logs}
            dataTitle={[
              "Id",
              "Date",
              "Message"
            ]}
            formatter={({
              uuid,
              date,
              message
            }) => ([
              uuid,
              date,
              message
            ])}
            actions={[]}
          />
          
        </div>
          </Modal>*/}
      <TableData
        loading={loading}
        dataRows={list.map((item) => ({
          ...item,
          style: item.ProviderResponseCode != "00" && { color: 'red' }
        }))}
        dataTitle={[
          "Fecha Hora",
          "Bolsa",
          "Operadora",
          "Producto",
          "Monto",
          "Número",
          "Código",
          "Estado",
          "Tiempo",
        ]}
        formatter={({
          createdAt,
          hosterBagId,
          operator,
          amount,
          phoneNumber,
          productId,
          ProviderResponseCode,
          ProviderResponseMessage,
          time
        }) => ([
          moment(createdAt).format('DD/MM/YYYY HH:mm:ss '),
          hosterBagId?.hashtag,
          operator,
          productId,
          `$ ${Number(amount || 0).toFixed(2)}`,
          phoneNumber,
          ProviderResponseCode,
          ProviderResponseMessage,
          `${time} ms`,
        ])}
        actions={[
          validatePermission('proveedores', 'proveedores', ['agregar']) && {
            label: "Logs",
            icon: <BugReport fontSize="medium" />,
            onClick: async (data) => {
              dispatch(setLogsTxn([]));
              console.log("dsts.report", data);
              const result = await getLogsTxnService(data.id);
              dispatch(setLogsTxn(result));
              setIsModalOpen(true);
            },
          },
        ]}
      />
    </>
  );
};

export default PanelTransactionsScreen;
