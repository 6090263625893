import service from "../service";

export const getRolesService = () => (
  service('get', `v3/seguridad/rol`)
)

export const getRolePermissionsService = (idRole) => (
  service('get', `v3/seguridad/rol/${idRole}/permisos`)
)

export const getRolesTypeService = () => {
  return [
    { id: 'admin', name: 'Administrador' },
    { id: 'commerce', name: 'Comercio' }
  ]
}

export const createRoleService = (data) => (
  service('post', `v3/seguridad/rol`, {
    data
  })
)

export const updateRoleService = (idRole, data) => (
  service('patch', `v3/seguridad/rol/${idRole}`, {
    data
  })
)

export const removeRoleService = (idRole) => (
  service('delete', `v3/seguridad/rol/${idRole}`)
)