import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  providers: [],
  providerSelected: null,
  providerCreds: [],
  providerCredSelected: null,
  carrierList: [],
  segmentList: [],
  segmentSelected: null,
  hebeErrorCodesList: [],
  hebeErrorCodesHistoryList: [],
  hebeErrorCodesHistoryListIds: null,
  providerErrorCodeToCreate: null,
  carriersProvider: [],
  amountList: [],
  amountSelected: null,
  amountProvider: [],
  errorCodesProvider: [],
  errorCodeSelected: null,
  hebeCodeSelected: null,
  //TAE
  operators: [],
  regions: [],
  segments: [],
  denominations: [],
  credentials: [],
  current: null,
  currentOperator: null,
  currentRegion: null,
  currentSegment: null,
  currentTAEProduct: null,
  //PaymentService
  paymentServiceSegments: [],
  paymentServiceProducts: [],
  currentPaymentServiceSegment: null,
  currentPaymentServiceProduct: null,
  //PIN
  pinSegments: [],
  pinProducts: [],
  currentPINSegment: null,
  currentPINProduct: null,
}

export const providerSlice = createSlice({
  name: 'provider',
  initialState,
  reducers: {
    setProviders: (state, action) => ({
      ...state,
      providers: action.payload
    }),
    setProviderSelected: (state, action) => ({
      ...state,
      providerSelected: action.payload
    }),
    setProviderCredSelected: (state, action) => ({
      ...state,
      providerCredSelected: action.payload
    }),
    setProviderCreds: (state, action) => ({
      ...state,
      providerCreds: action.payload
    }),
    setCarrierList: (state, action) => ({
      ...state,
      carrierList: action.payload
    }),

    setSegmentList: (state, action) => ({
      ...state,
      segmentList: action.payload
    }),
    setSegmentSelected: (state, action) => ({
      ...state,
      segmentSelected: action.payload
    }),

    setHebeErrorCodesList: (state, action) => ({
      ...state,
      hebeErrorCodesList: action.payload
    }),
    setCarriersProvider: (state, action) => ({
      ...state,
      carriersProvider: action.payload
    }),
    setAmountProvider: (state, action) => ({
      ...state,
      amountProvider: action.payload
    }),
    setErrorCodesProvider: (state, action) => ({
      ...state,
      errorCodesProvider: action.payload
    }),
    setErrorCodesHistoryProvider: (state, action) => ({
      ...state,
      hebeErrorCodesHistoryList: action.payload
    }),
    setErrorCodesHistoryIdsProvider: (state, action) => ({
      ...state,
      hebeErrorCodesHistoryListIds: action.payload
    }),
    setProviderErrorCodeToCreate: (state, action) => ({
      ...state,
      providerErrorCodeToCreate: action.payload
    }),
    setErrorCodeSelected: (state, action) => ({
      ...state,
      errorCodeSelected: action.payload
    }),
    setHebeCodeSelected: (state, action) => ({
      ...state,
      hebeCodeSelected: action.payload
    }),
    setAmountList: (state, action) => ({
      ...state,
      amountList: action.payload
    }),
    setAmountSelected: (state, action) => ({
      ...state,
      amountSelected: action.payload
    }),
    setOperators: (state, action) => ({
      ...state,
      operators: action.payload
    }),
    setRegions: (state, action) => ({
      ...state,
      regions: action.payload
    }),
    setCredentials: (state, action) => ({
      ...state,
      credentials: action.payload
    }),
    updateCommissionRegions: (state, action) => ({
      ...state,
      regions: state.regions.map((region) => {
        if (region.id === action.payload.id)
          return { ...region, ...action.payload.data };
        return region;
      }),
    }),
    setSegments: (state, action) => ({
      ...state,
      segments: action.payload
    }),
    setDenominations: (state, action) => ({
      ...state,
      denominations: action.payload
    }),
    addProvider: (state, action) => ({
      ...state,
      providers: [
        ...state.providers,
        action.payload
      ]
    }),
    updateProvider: (state, action) => ({
      ...state,
      providers: state.providers.map((provider) => {
        if (provider.id === action.payload.id)
          return { ...provider, ...action.payload.data };
        return provider;
      }),
    }),
    removeProvider: (state, action) => ({
      ...state,
      providers: state.providers.filter((provider) => {
        return provider.id !== action.payload;
      }),
    }),
    setCurrentProvider: (state, action) => ({
      ...state,
      current: action.payload,
    }),
    setCurrentOperator: (state, action) => ({
      ...state,
      currentOperator: action.payload,
    }),
    setCurrentRegion: (state, action) => ({
      ...state,
      currentRegion: action.payload,
    }),
    setCurrentSegment: (state, action) => ({
      ...state,
      currentSegment: action.payload,
    }),
    setCurrentTAEProduct: (state, action) => ({
      ...state,
      currentTAEProduct: action.payload,
    }),
    //PaymentService
    setPaymentServiceSegments: (state, action) => ({
      ...state,
      paymentServiceSegments: action.payload
    }),
    setPaymentServiceProducts: (state, action) => ({
      ...state,
      paymentServiceProducts: action.payload
    }),
    setCurrentPaymentServiceSegment: (state, action) => ({
      ...state,
      currentPaymentServiceSegment: action.payload
    }),
    setCurrentPaymentServiceProduct: (state, action) => ({
      ...state,
      currentPaymentServiceProduct: action.payload
    }),
    //PIN
    setPINSegments: (state, action) => ({
      ...state,
      pinSegments: action.payload
    }),
    setPINProducts: (state, action) => ({
      ...state,
      pinProducts: action.payload
    }),
    setCurrentPINSegment: (state, action) => ({
      ...state,
      currentPINSegment: action.payload
    }),
    setCurrentPINProduct: (state, action) => ({
      ...state,
      currentPINProduct: action.payload
    }),

  }
})

export const {
  setProviders,
  setProviderSelected,
  setProviderCreds,
  setProviderCredSelected,
  setCarrierList,
  setSegmentList,
  setSegmentSelected,
  setHebeErrorCodesList,
  setCarriersProvider,
  setAmountProvider,
  setAmountSelected,
  setErrorCodesProvider,
  setErrorCodesHistoryProvider,
  setErrorCodesHistoryIdsProvider,
  setProviderErrorCodeToCreate,
  setErrorCodeSelected,
  setOperators,
  setRegions,
  setCredentials,
  updateCommissionRegions,
  setSegments,
  setDenominations,
  addProvider,
  updateProvider,
  removeProvider,
  setCurrentProvider,
  setCurrentOperator,
  setCurrentRegion,
  setCurrentSegment,
  setCurrentTAEProduct,
  setHebeCodeSelected,
  //PaymentService
  setPaymentServiceSegments,
  setPaymentServiceProducts,
  setCurrentPaymentServiceSegment,
  setCurrentPaymentServiceProduct,
  //PIN
  setPINSegments,
  setPINProducts,
  setCurrentPINSegment,
  setCurrentPINProduct
} = providerSlice.actions

export default providerSlice.reducer