import { Edit } from "@mui/icons-material";
import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import { BackButton, CardBox } from "components";
import { errorAlert } from "helpers/alerts";
import React, { useEffect } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCurrentPDSProduct, setCurrentPDSSegment, setJustProducts, setPDSProducts, setPDSSegments } from "redux/slices/client/bagSlice";
import { getAllBagPDSProductsService, getBagPDSProductsService, getBagPDSSegmentsService } from "services/client/bagService";

const BagPDSScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    justProducts,
    currentBag,
    pdsSegments,
    pdsProducts,
    currentPDSSegment,
    currentPDSProduct
  } = useSelector((store) => store.bags);


  useEffect(() => {
    getBagPDSSegmentsService(currentBag.id).then(list => {
      dispatch(setPDSSegments(list));
    }).catch(error => errorAlert(error))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (justProducts) {
      getAllBagPDSProductsService(currentBag.id).then(list => {
        dispatch(setPDSProducts(list));
      }).catch(error => errorAlert(error))
    } else {
      if (currentPDSSegment)
        getBagPDSProductsService(currentBag.id, currentPDSSegment.id).then(list => {
          dispatch(setPDSProducts(list));
        }).catch(error => errorAlert(error))
    }

    // eslint-disable-next-line
  }, [currentBag, currentPDSSegment, justProducts]);


  const handlerClickSegment = (segment) => {
    if (segment.id !== currentPDSSegment?.id) {
      batch(() => {
        dispatch(setCurrentPDSSegment(segment));
        dispatch(setCurrentPDSProduct());
      })
    }
  };

  const handlerClickProduct = (product) => {
    if (product.id !== currentPDSProduct?.id) {
      dispatch(setCurrentPDSProduct(product));
    }
  };

  return (
    <div>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <div>
          <BackButton click={() => navigate(-1)} />
        </div>
        <div className="container-title">
          <div className="commerce">{currentBag.hoster?.username} </div>
          <div className="bag">{currentBag.username} </div>
        </div>
        <div style={{ width: "170px" }}>
          <FormControlLabel
            label={'Ver solo produtos'}
            control={
              <Checkbox
                checked={justProducts}
                onChange={(e) => {
                  dispatch(setJustProducts(e.target.checked))
                }}
              />
            }
          />
        </div>
      </Grid>
      {
        !justProducts && (
          <CardBox
            name={"Segmentos"}
            values={pdsSegments}
            handleSelect={handlerClickSegment}
            isSelect={currentPDSSegment?.id}
            buttons={[
              {
                icon: <Edit sx={{ ml: 1 }} />,
                key: "segments",
                name: "Administrar",
                click: () => navigate("segmentos"),
              },
            ]}
            formatter={({ idSegment: item }) => ({
              ...item,
              image: `/v3/file/${item.fd}/`,
            })}
          />
        )
      }
      {
        (justProducts || currentPDSSegment) && (
          <CardBox
            name={"Servicios"}
            values={pdsProducts?.filter(({ state }) => state)}
            handleSelect={handlerClickProduct}
            isSelect={currentPDSProduct?.id}
            buttons={[
              {
                icon: <Edit sx={{ ml: 1 }} />,
                key: "products",
                name: "Administrar",
                click: () => navigate("productos"),
              },
            ]}
            formatter={({ idProduct: item }) => ({
              ...item,
              image: `/v3/file/${item.fd}/`,
            })}

          />
        )
      }
    </div >
  );
};

export default BagPDSScreen;
