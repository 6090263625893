import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { setTitle } from 'redux/slices/uiSlice';
import { CredsProviderForm } from 'components';
import { putProviderCredsService } from 'services/admin/providerService';

function CredsAddScreen() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { providerSelected } = useSelector(store => store.providers);

    useEffect(() => {
        dispatch(setTitle('Nueva Credencial de Proveedor'));
        // eslint-disable-next-line
    }, [])

    return (
        <CredsProviderForm
            title={"Cancelar"}
            defaultValues={{
                name_connector: "",
                client_id: "",
                user_name: "",
                password_digest: "",
                active: "1",
                conciliacion: "1"
            }}
            goBack={() => {
                navigate(-1);
            }}
            submit={(data) => {
                data['active'] = data['active'] == 1
                data['conciliacion'] = data['conciliacion'] == 1
                data['conciliacion_service'] = providerSelected.host;
                putProviderCredsService(data).then((result) => {
                    console.log(result);
                    navigate(-1);
                }).catch((e) => {
                    console.log(e);
                    navigate(-1);
                });
            }}
        />
    );
}

export default CredsAddScreen;