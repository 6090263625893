import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { RoleForm } from '../../../components'
import { errorAlert } from '../../../helpers/alerts';
import { createRoleService } from '../../../services/security/roleService';

const CreateRoleScreen = () => {
  const navigate = useNavigate();
  const [permissions, setPermissions] = useState([])
  const values = {
    name: "",
    type: "",
  };

  const handlerBack = () => {
    navigate('/seguridad/roles')
  }

  const handlerSubmit = async (result) => {
    try {
      await createRoleService({
        ...result,
        permissions: permissions.map(({ idPermiso, action }) => ({ id: idPermiso, action }))
      })
      navigate('/seguridad/roles')
    } catch (error) {
      errorAlert(error)
    }
  }

  const handlerCheck = ({ id, action, state }) => {
    setPermissions(state ?
      permissions.filter((permission) => (`${permission.idPermiso}_${permission.action}` !== `${id}_${action}`))
      : [
        ...permissions,
        { idPermiso: id, action }
      ])
  }

  return (
    <RoleForm
      title={"Crear Rol"}
      goBack={handlerBack}
      defaultValues={values}
      permissions={permissions}
      submit={handlerSubmit}
      onCheck={handlerCheck}
    />
  )
}

export default CreateRoleScreen