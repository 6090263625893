import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { DenominationForm } from '../../../../components'
import { errorAlert } from '../../../../helpers/alerts';
import { setDenominations } from '../../../../redux/slices/admin/denominationSlice';
import { createDenominationService } from '../../../../services/admin/denominationServices';

const ProductCreateDenominationScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentSegment: { id: idSegment } } = useSelector((store) => store.segments);
  const values = {
    name: "",
    imp: "",
    validity: "",
    min: "",
    sms: "",
    gb: "",
    gift: "",
  };

  const handlerBack = () => navigate(-1);

  const handlerSubmit = async (data) => {
    data = {
      ...data,
      idSegment,
      denomination: data.name,
      vig: data.validity,
    };
    try {
      const response = await createDenominationService(data);
      dispatch(setDenominations(response));
      navigate(-1);
    } catch (error) {
      errorAlert(error);
    }
  };

  return (
    <DenominationForm
      title="Crear Denominación"
      goBack={handlerBack}
      defaultValues={values}
      submit={handlerSubmit}
    />
  )
}

export default ProductCreateDenominationScreen