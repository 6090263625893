import React from 'react'
import './WorkArea.css'
import { ChevronRight } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { showSidebar } from '../../redux/slices/uiSlice';

const WorkArea = ({ children }) => {
  const dispatch = useDispatch();
  const { sidebar } = useSelector(store => store.ui)
  return (
    <div className='work-area'>
      {
        !sidebar && (
          <div className='work-sidebar-button' onClick={() => dispatch(showSidebar(true))}>
            <ChevronRight className='sidebar-button-icon' />
          </div>
        )
      }
      {children}
    </div>
  )
}

export default WorkArea;