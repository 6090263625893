import React, { useEffect } from "react";
import { AddCircleOutline } from "@mui/icons-material";
import { Grid } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { BackButton, CustomButton, TableData } from "../../../../components";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { confirmAlert, errorAlert } from "../../../../helpers/alerts";
import { getLawfulOperatorByOperatorService, removeLawfulOperatorService, updateLawfulOperatorService } from "../../../../services/admin/lawfulOperatorService";
import { removeLawfulOperator, setCurrentLawfulOperator, setLawfulOperators, updateLawfulOperator } from "../../../../redux/slices/admin/lawfulOperatorSlice";

const ProductLawfulOperatorScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentOperator } = useSelector((store) => store.operators);
  const { lawfulOperators } = useSelector((store) => store.lawfulOperators);

  useEffect(() => {
    (async () => {
      try {
        const lawfulOperator = await getLawfulOperatorByOperatorService(currentOperator.id);
        dispatch(setLawfulOperators(lawfulOperator));
      } catch (error) {
        errorAlert(error)
      }
    })()
    // eslint-disable-next-line
  }, []);

  const handlerBack = () => navigate(-1);

  const handlerAdd = () => navigate("crear");

  const handlerDelete = ({ id, name }) => {
    confirmAlert({
      title: "Estas seguro que deseas eliminar esta operadora legal?",
      text: `La operadora legal ${name} será eliminada.`,
      onConfirm: async () => {
        try {
          await removeLawfulOperatorService(currentOperator.id, id);
          dispatch(removeLawfulOperator(id));
        } catch (error) {
          errorAlert(error);
        }
      },
    });
  };
  const handlerEdit = (row) => {
    dispatch(setCurrentLawfulOperator(row));
    navigate("editar");
  };
  const handlerCheck = async ({ id }, currentValue) => {
    const value = !currentValue;
    await updateLawfulOperatorService(currentOperator.id, id, { state: value });
    dispatch(updateLawfulOperator({ id, data: { state: value } }));
  };
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <div>
          <BackButton name={"Legales"} click={handlerBack} />
        </div>
        <div className="container-title">
          <div className="title">Operadora: {currentOperator.name} </div>
        </div>
        <div style={{ width: "170px" }}></div>
      </Grid>
      <TableData
        dataRows={lawfulOperators}
        headerButtons={[
          //validatePermission('productos', 'productos', ['editar']) &&
          <CustomButton
            icon={<AddCircleOutline fontSize="large" />}
            key="addLawfulOperator"
            name={"Legal"}
            click={handlerAdd}
          />,
        ]}
        dataTitle={["Nombre", "Descripción", "Estado"]}
        formatter={({ name, description, state }) => [
          name,
          description,
          {
            type: "boolean",
            value: state,
            //   disabled: !validatePermission("productos", "productos", ["editar"]),
          },
        ]}
        onChecked={handlerCheck}
        actions={[
          //   validatePermission("productos", "productos", ["editar"]) && 
          {
            label: "Editar",
            icon: <EditIcon fontSize="medium" />,
            onClick: (data) => {
              handlerEdit(data);
            },
          },
          //  validatePermission("productos", "productos", ["eliminar"]) && 
          {
            label: "Eliminar",
            icon: <DeleteForeverIcon fontSize="medium" />,
            onClick: (data) => {
              handlerDelete(data);
            },
          },
        ]}
      />
    </>
  );
};

export default ProductLawfulOperatorScreen;
