const Images = {
    hebe: {
        background: require('./hebe/background.png'),
        logo: require('./hebe/logo.png'),
        favicon: require('./hebe/favicon.png'),
    },
    comtodotel: {
        background: require('./hebe/background.png'),
        logo: require('./hebe/logo.png'),
        favicon: require('./hebe/favicon.png'),
    },
    marbu: {
        logo: require('./marbu/logo.png'),
        favicon: require('./marbu/favicon.png'),
    },
    telemuti: {
        logo: require('./telemuti/logo.png'),
        favicon: require('./telemuti/favicon.png'),
    },
    icon: {
        control_panel: require('./icons/chart-custom.png'),
        gear: require('./icons/gear.png'),
        reports: require('./icons/reports.png'),
        security: require('./icons/security.png'),
        store: require('./icons/store.png'),
        account: require('./icons/account.png'),
        control_panel_white: require('./icons/chart-custom-white.png'),
        gear_white: require('./icons/gear-white.png'),
        reports_white: require('./icons/reports-white.png'),
        security_white: require('./icons/security-white.png'),
        store_white: require('./icons/store-white.png'),
        chevron_left: require('./icons/chevron-left.png'),
        edit: require('./icons/edit.png'),
        account_circle: require('./icons/account-circle.png'),
        search: require('./icons/search.png'),
        arrow_back: require('./icons/arrow-back.png'),
        arrow_next: require('./icons/arrow-next.png'),
        check_circle: require('./icons/check-circle.png'),
        uncheck_circle: require('./icons/uncheck-circle.png'),
        add_image: require('./icons/icon-add-image.png'),
        ladas: require('./icons/ladas.png'),
        ruteo: require('./icons/ruteo.png'),
        panel_ruteo: require('./icons/panel_ruteo.png'),
        sale: require('./icons/sale.png'),
        loading: require('./icons/loading.gif')
    }
}

export default Images;