import { Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { errorAlert } from "../../helpers/alerts";
import { validateEmptyFields } from "../../helpers/util";
import { setConnectorTypes } from "../../redux/slices/admin/connectorSlice";
import { getConnectorTypesService } from "../../services/admin/connectorService";
import BackButton from "../BackButton";
import InputSelectFieldGrid from "../common/InputSelectFieldGrid";
import InputTextFieldGrid from "../common/InputTextFieldGrid";

const ConnectorForm = ({ title, goBack, defaultValues, submit, validate }) => {
  const { control, handleSubmit } = useForm({ defaultValues });
  const [disabled, setDisabled] = useState(true);
  const dispatch = useDispatch();
  const { connectortypes: types } = useSelector((store) => store.connectors);
  const watch = useWatch({ control });

  useEffect(() => {
    (async () => {
      try {
        const types = await getConnectorTypesService();
        dispatch(setConnectorTypes(types));
      } catch (error) {
        errorAlert(error);
      }
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let disable = validateEmptyFields(watch, {validate, skip_fields: ["description"] });
    setDisabled(disable);
    // eslint-disable-next-line
  }, [watch]);

  return (
    <>
      <BackButton name={title} click={goBack} />
      <Grid container justifyContent="center" alignItems="center" mt={"40px"}>
        <Grid width={"70%"} container spacing={3}>
          <InputTextFieldGrid
            xs={4}
            name={"name"}
            control={control}
            required={"Debes agregar el nombre de conector"}
            label={"Nombre"}
          />
          <InputSelectFieldGrid
            xs={4}
            name={"type"}
            control={control}
            required={"Debes seleccionar un tipo"}
            label={"Selecciona un tipo"}
            data={types}
          />
          <InputTextFieldGrid
            xs={4}
            name={"collection"}
            control={control}
            required={"Debes agregar el nombre de colección"}
            label={"Nombre de colección"}
          />
          <Grid item xs={12} md={12} sx={{ textAlign: "center" }}>
            <b>Tipo de Respuesta (ms)</b>
          </Grid>
          <InputTextFieldGrid
            xs={4}
            name={"slow_response_time"}
            control={control}
            required={"Debes agregar tipo de respuesta lenta"}
            label={"Lenta"}
            inputProps={{
              type: 'number'
            }}
          />
          <InputTextFieldGrid
            xs={4}
            name={"optimal_response_time"}
            control={control}
            required={"Debes agregar tipo de respuesta normal"}
            label={"Normal"}
          />
          <InputTextFieldGrid
            xs={4}
            name={"critical_response_time"}
            control={control}
            required={"Debes agregar tipo de respuesta crítica"}
            label={"Crítica"}
          />
          <InputTextFieldGrid
            xs={12}
            name={"description"}
            control={control}
            label={"Descripción"}
          />
          <InputTextFieldGrid
            xs={6}
            name={"response_code"}
            control={control}
            required={"Debes agregar el campo de respuesta "}
            label={"Campo de respuesta"}
          />
          <InputTextFieldGrid
            xs={6}
            name={"success_code"}
            control={control}
            required={"Debes agregar el código de respuesta "}
            label={"Código de respuesta"}
          />
          <Grid container justifyContent="center" mt={"40px"}>
            <Button
              sx={{ m: 2, fontWeight: "bold", backgroundColor: "#0E73ED" }}
              onClick={handleSubmit(submit)}
              disabled={disabled}
              variant="contained"
            >
              Guardar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ConnectorForm;
