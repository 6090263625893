import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  permissions: [],
  current: null,
  types:[]
}

export const permissionSlice = createSlice({
  name: 'permission',
  initialState,
  reducers: {
    setPermissions: (state, action) => ({
      ...state,
      permissions: action.payload
    }),
    setPermissionTypes:(state,action) => ({
      ...state,
      types: action.payload
    }),
    setCurrentPermission: (state, action) => ({
      ...state,
      current: action.payload,
    }),
    createPermission: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    updatePermission: (state, action) => ({
      ...state,
      permissions: state.permissions.map((permission) => {
        if (permission.id === action.payload.id)
          return { ...permission, ...action.payload.data }
        return permission
      })
    }),
    removePermission: (state, action) => ({
      ...state,
      permissions: state.permissions.filter((permission) => {
        return permission.id !== action.payload
      })
    })
  }
})

export const {
  setPermissions,
  createPermission,
  updatePermission,
  removePermission,
  setCurrentPermission,
  setPermissionTypes
} = permissionSlice.actions

export default permissionSlice.reducer

