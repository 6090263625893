import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BackButton, TableData } from "components";
import { errorAlert } from "helpers/alerts";
import { getAllPINProductsService, getPINProductsService } from "services/admin/productService";
import { Grid } from "@mui/material";
import { setBagPINProductService } from "services/client/bagService";
import { setPINProducts } from "redux/slices/admin/productSlice";
import { setPINProducts as setBagPINProducts } from "redux/slices/client/bagSlice";

const BagPINProductScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    justProducts,
    currentBag,
    currentPINSegment,
    pinProducts: bagPINProducts,
  } = useSelector((store) => store.bags);
  const {
    pinProducts
  } = useSelector((state) => state.products);


  useEffect(() => {
    if (justProducts)
      getAllPINProductsService()
        .then((data) => dispatch(setPINProducts(data)))
        .catch(error => errorAlert(error))
    else
      getPINProductsService(currentPINSegment.id)
        .then((data) => dispatch(setPINProducts(data)))
        .catch(error => errorAlert(error))
    // eslint-disable-next-line
  }, []);

  const handlerCheck = async (row, state) => {
    setBagPINProductService(currentBag.id, row.idSegment.id, row.id, {
      state: !state
    }).then((result) => {
      dispatch(setBagPINProducts(bagPINProducts.map((item) => {
        if (item.idProduct.id === row.id) {
          return {
            ...item,
            state: !state
          };
        }
        return item;
      })))
    }).catch(error => errorAlert(error))
  };

  const handlerSetCommission = async (commission, row) => {
    setBagPINProductService(currentBag.id, row.idSegment.id, row.id, {
      commission
    }).then((result) => {
      dispatch(setBagPINProducts(bagPINProducts.map((item) => {
        if (item.idProduct.id === row.id) {
          return {
            ...item,
            commission
          };
        }
        return item;
      })))
    }).catch(error => errorAlert(error))
  }

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <div>
          <BackButton click={() => navigate(-1)} />
        </div>
        <div className="container-title">
          <div className="commerce">Servicios</div>
          {!justProducts && <div className="bag">{currentPINSegment.name} </div>}
        </div>
        <div>
        </div>
      </Grid>
      <TableData
        dataRows={pinProducts.map((item) => {
          const service = bagPINProducts?.find(({ idProduct }) => idProduct.id === item.id);
          
          return {
            ...item,
            service
          };
        })}
        dataTitle={["Logo", "Nombre", "Comisión del Canal", "Asignado"]}
        formatter={({ fd, name, service }) => [
          { type: "img", value: `/v3/file/${fd}/` },
          name,
          { name: "commission", type: "input", value: service?.commission, onBlur: handlerSetCommission },
          { name: "state", type: "boolean", value: service?.state },
        ]}
        onChecked={handlerCheck}
      />
    </>
  );
};

export default BagPINProductScreen;
