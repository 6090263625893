import React, { useEffect, useState } from "react";
import { FilterBar, TableData } from "../../components";
import { Search } from '@mui/icons-material';
import { useDispatch, useSelector } from "react-redux";
import { setDetailedReportFilters, setTotalReport } from "../../redux/slices/report/reportSlice";
import { errorAlert } from "../../helpers/alerts";
import moment from "moment";
import { currencyFormatter } from "../../helpers/util";
import './TotalReportScreen.css'
import { getTotalReportService } from "services/report/reportService";
import { getCommercesService } from "services/client/commerceService";
import { useNavigate } from "react-router-dom";
import { getGroupsService } from "services/client/groupService";
import { setGroups } from "redux/slices/client/groupSlice";

const TotalReportScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { totalReport } = useSelector((store) => store.reports);
  const { groups } = useSelector(store => store.group);
  const [commerces, setCommerces] = useState([])
  const [loading, setLoading] = useState(false)
  const [filters, setFilters] = useState(null)

  useEffect(() => {
    getGroupsService()
      .then((response) => dispatch(setGroups(response)))
      .catch((e) => errorAlert(e))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getCommercesService(undefined, true)
      .then((result) => setCommerces(result.map(({ _id: id, username }) => ({ id, name: username }))))
      .catch((e) => errorAlert(e))
    // setCommerces((totalReport || [])
    //   .map(({ commerceId: id, commerce: name }) => ({ id, name }))
    //   .filter(({ id }, index) => index === totalReport.findIndex(o => id === o.commerceId))
    // );
    // eslint-disable-next-line
  }, [totalReport]);

  const handlerSubmit = async (data) => {
    setLoading(true)
    setFilters(data)
    getTotalReportService(data).then((result) => {
      dispatch(setTotalReport(result))
    }).catch((error) => errorAlert(error))
      .finally(() => setLoading(false));
  }

  const handlerShowDetail = async (data) => {
    dispatch(setDetailedReportFilters({
      type: 1,
      startDate: filters.startDate,
      endDate: filters.endDate,
      operator: data.operatorId,
    }))
    navigate('/reportes/detallado')
  }

  return (
    <>
      <FilterBar
        handlerFilter={handlerSubmit}
        defaultValues={{
          startDate: moment().format('YYYY-MM-DD'),
          endDate: moment().format('YYYY-MM-DD'),
        }}
        allValue={null}
        filters={[
          {
            name: "startDate",
            label: "Fecha Inicio",
            type: "date",
          },
          {
            name: "endDate",
            label: "Fecha Fin",
            type: "date",
          },
          {
            name: 'group',
            label: "Grupo",
            type: "select",
            all: 'Todos',
            data: groups,
          },
          {
            name: 'commerce',
            label: "Comercio",
            type: "select",
            data: commerces,
            multiple: true,
            xs: 6,
            render: (value) => value.map((id) => {
              const commerce = commerces.find(o => o.id === id);
              return commerce.name;
            }).join(', '),
          },
          {
            type: "checkbox",
            name: "regions",
            label: "Regiones",
          },
        ]}
      />
      <TableData
        loading={loading}
        dataRows={totalReport}
        dataTitle={[
          'Operadora',
          'Monto Aprobado',
          'Txn Aprobadas',
          'Monto Denegado',
          'Txn Denegadas',
        ]}
        formatter={(data) => [
          data.operator,
          currencyFormatter.format(data.approvedAmount),
          data.approvedTxn,
          currencyFormatter.format(data.deniedAmount),
          data.deniedTxn,
        ]}
        actions={[
          {
            label: "Detalle",
            icon: <Search fontSize="medium" />,
            onClick: async (data) => {
              handlerShowDetail(data)
            },
          },
        ]}
      />
    </>
  );
};

export default TotalReportScreen;
