import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, Grid } from '@mui/material'
import React from 'react'
import { Controller, useForm } from 'react-hook-form';

const ColumnFilterModal = ({
  open,
  columns,
  selected,
  onSuccess = () => { },
  onClose = () => { }
}) => {
  const { control, handleSubmit } = useForm({
    defaultValues: selected.reduce((a, v) => ({ ...a, [v]: true }), {})
  });
  
  const submit = (result) => {
    onSuccess(Object.entries(result).filter(([, value]) => value).map(([key]) => key))
  };

  const handlerClose = () => {
    onClose();
  }

  return (
    <Dialog
      open={open}
      onClose={handlerClose}
      fullWidth={true}
    >
      <>
        <DialogTitle sx={{ textAlign: 'center' }}>Selecciona las columnas</DialogTitle>
        <DialogContent >
          <FormGroup>
            <Grid container spacing={1}>
              {
                columns.map(({ name, label }, key) => (
                  <Grid item xs={4} key={key} >
                    <Controller
                      name={name}
                      control={control}
                      render={({
                        field: { value, onChange },
                      }) => (
                        <FormControlLabel label={label} control={
                          <Checkbox checked={value} value={value} onChange={onChange} />
                        } />
                      )}
                    />
                  </Grid>
                ))
              }
            </Grid>
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ m: 2 }}
            color='error'
            onClick={handlerClose}
            // disabled={disabled}
            variant="contained"
          >
            Cancelar
          </Button>
          <Button
            sx={{ m: 2, fontWeight: "bold", backgroundColor: "#0E73ED" }}
            onClick={handleSubmit(submit)}
            // disabled={disabled}
            variant="contained"
          >
            Aceptar
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}

export default ColumnFilterModal