import React, { useEffect, useState } from 'react'
import { CustomButton, FilterBar, TableData } from '../../components';
import DownloadIcon from "@mui/icons-material/Download";
import { useDispatch, useSelector } from 'react-redux';
import { setBalanceReport } from '../../redux/slices/report/reportSlice';
import { downloadBalanceReportService, getBalanceReportService } from '../../services/report/reportService';
import { errorAlert } from '../../helpers/alerts';
import moment from 'moment';
import { currencyFormatter, downloadFile } from '../../helpers/util';
import { getGroupsService } from 'services/client/groupService';
import { setGroups } from 'redux/slices/client/groupSlice';

const BalancesScreen = () => {
  const dispatch = useDispatch();
  const { balanceReport } = useSelector(store => store.reports);
  const { groups } = useSelector(store => store.group);
  const [loading, setLoading] = useState(false)
  const [downloadFilters, setDownloadFilters] = useState({})
  const [commerces, setCommerces] = useState([])
  const [bags, setBags] = useState([])
  const [disabledBags, setDisabledBags] = useState(true)
  // const [disabledBags, setDisabledBags] = useState(true)

  useEffect(() => {
    getGroupsService()
      .then((response) => dispatch(setGroups(response)))
      .catch((e) => errorAlert(e))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setCommerces(balanceReport
      .map(({ hosterId: id, hoster: name }) => ({ id, name }))
      .filter(({ id }, index) => index === balanceReport.findIndex(o => id === o.hosterId))
    );
    setBags(balanceReport
      .filter(({ bagId }) => bagId)
      .map(({ bagId: id, bag: name }) => ({ id, name }))
      .filter(({ id }, index) => index === balanceReport.findIndex(o => id === o.bagId))
    );
    // eslint-disable-next-line
  }, [balanceReport]);

  const handlerSubmit = async (data) => {
    setLoading(true)
    setDownloadFilters(data)
    setDisabledBags(data.commerce === undefined)
    getBalanceReportService(data)
      .then((response) => dispatch(setBalanceReport(response || [])))
      .catch((e) => errorAlert(e))
      .finally(() => setLoading(false));
  }

  const handlerDownload = async () => {
    setLoading(true)
    downloadBalanceReportService(downloadFilters)
      .then((data) => downloadFile(data, 'Reporte de Saldos.xlsx'))
      .catch((e) => errorAlert(e))
      .finally(() => setLoading(false));
  }

  return (
    <>
      <FilterBar
        handlerFilter={handlerSubmit}
        defaultValues={{
          startDate: moment().format('YYYY-MM-DD'),
          endDate: moment().format('YYYY-MM-DD'),
        }}
        filters={[
          {
            name: "startDate",
            label: "Fecha Inicio",
            type: "date",
          },
          {
            name: "endDate",
            label: "Fecha Fin",
            type: "date",
          },
          {
            name: 'group',
            label: "Grupo",
            type: "select",
            all: 'Todos',
            data: groups,
          },
          {
            name: 'commerce',
            label: "Comercio",
            type: "select",
            all: 'Todos',
            data: commerces,
          },
          {
            name: 'bag',
            label: "Bolsa",
            type: "select",
            all: 'Todas',
            data: bags,
            disabled: disabledBags
          },
        ]}
      />
      <TableData
        loading={loading}
        dataRows={balanceReport || []}
        headerButtons={[
          <CustomButton
            icon={<DownloadIcon />}
            key="dowloadBalance"
            name={"Descargar"}
            click={handlerDownload}
          />,
        ]}
        dataTitle={[
          "Fecha",
          "Comercio",
          "Bolsa",
          "Saldo inicial",
          "Abonos",
          "Ventas",
          "Saldo final",
        ]}
        formatter={({ date, hoster, bag, balance, payment, sale }) => ([
          moment(date).format('DD/MM/YYYY'),
          hoster,
          bag,
          currencyFormatter.format(balance || 0),
          currencyFormatter.format(payment || 0),
          currencyFormatter.format(sale || 0),
          currencyFormatter.format(balance + payment + sale || 0),
        ])}
        actions={[
        ]}
      />
    </>

  )
}

export default BalancesScreen