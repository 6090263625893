import service from "../service";

export const getPanelRuteo = () => (
  service('get', `v3/ruteo`)
)

export const setPanelPrimaryProvider = ({ idOperator, idProvider, idRegion }) => (
  service('post', `v3/ruteo/operador/${idOperator}/primario/${idProvider}/region/${idRegion}`)
)

export const setPanelSecondaryProvider = ({ idOperator, idProvider, idRegion }) => (
  service('post', `v3/ruteo/operador/${idOperator}/secundario/${idProvider}/region/${idRegion}`)
)

//PDS
export const getPDSRuteoService = () => (
  service('get', `v3/ruteo/pds`)
)

export const setPDSProviderCredentialService = ({ idProduct, idCredential }) => (
  service('post', `v3/ruteo/pds/producto/${idProduct}/credencial/${idCredential}`)
)

//PIN
export const getPINRuteoService = () => (
  service('get', `v3/ruteo/pin`)
)

export const setPINProviderCredentialService = ({ idProduct, idCredential }) => (
  service('post', `v3/ruteo/pin/producto/${idProduct}/credencial/${idCredential}`)
)
