import { Grid } from '@mui/material'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { errorAlert } from '../../helpers/alerts';
import { setExtraDenominations } from '../../redux/slices/admin/extraDenominationSlice';
import { getExtraDenominationByDenominationService } from '../../services/admin/extraDenominationService';
import { getLawfulOperatorByOperatorService } from '../../services/admin/lawfulOperatorService';
import InputTextFieldGrid from './InputTextFieldGrid';
import TableData from './TableData';


const DenominationDataAndExtras = ({ name, data, operatorId }) => {

  const dispatch = useDispatch();
  const { extraDenominations } = useSelector((store) => store.extraDenominations);

  const values = {
    name: data.denomination,
    imp: data.imp,
    validity: data.vig,
    min: data.min,
    sms: data.sms,
    gb: data.gb,
    gift: data.gift,
  }

  const { control } = useForm({ values });
  useEffect(() => {
    if (!extraDenominations)
      (async () => {
        try {
          const extraDenomination = await getExtraDenominationByDenominationService(data.id);
          const lawfulOperator = await getLawfulOperatorByOperatorService(operatorId);
          dispatch(setExtraDenominations([...extraDenomination.filter(({ state }) => state), ...lawfulOperator.filter(({ state }) => state).map(item => ({
            ...item,
            nombre: item.name
          }))]));
        } catch (error) {
          errorAlert(error)
        }
      })()
    // eslint-disable-next-line
  }, [extraDenominations]);

  return (
    <Grid container sx={{ mb: 5 }}>
      <Grid container justifyContent={"space-between"} alignItems={"center"}>
        {/* <Grid> */}
        <h3>{name}</h3>
        {/* </Grid> */}
      </Grid>
      <Grid container justifyContent={"space-between"}>
        <Grid width={"40%"} textAlign={"center"}>
          <h3>Datos</h3>
          <Grid container direction="row" m={"40px"} maxWidth={"80%"} spacing={3} >
            <InputTextFieldGrid
              control={control}
              name="name"
              label="Nombre"
              xs={6}
              disabled={true}
            />
            <InputTextFieldGrid
              control={control}
              name="imp"
              label="Importe"
              xs={6}
              inputProps={{ type: 'number' }}
              disabled={true}
            />
            <InputTextFieldGrid
              control={control}
              name="validity"
              label="Vigencia"
              xs={6}
              disabled={true}
            />
            <InputTextFieldGrid
              control={control}
              name="min"
              label="Minutos"
              xs={6}
              disabled={true}
            />
            <InputTextFieldGrid
              control={control}
              name="sms"
              label="SMS"
              xs={6}
              disabled={true}
            />
            <InputTextFieldGrid
              control={control}
              name="gb"
              label="Datos (GB)"
              xs={6}
              disabled={true}
            />
            <InputTextFieldGrid
              control={control}
              name="gift"
              label="Saldo de regalo"
              xs={6}
              disabled={true}
            />
          </Grid>
        </Grid>
        <Grid width={"60%"} textAlign={"center"}>
          <h3>Extras</h3>
          <TableData
            dataRows={extraDenominations}
            dataTitle={["Logo", "Nombre", "Vigencia", "Descripción", "GB", "Datos"]}
            formatter={({ fd, nombre, vig, description, gb, datos }) => [
              { type: "img", value: `/v3/file/${fd}/` },
              nombre,
              vig,
              description,
              gb,
              datos,
            ]}
            style={{ height: '40vh' }}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default DenominationDataAndExtras