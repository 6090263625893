import { AddCircleOutline } from "@mui/icons-material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import { BackButton, CustomButton, TableData } from "../../../../components";
import {
  removeSegment,
  setCurrentSegment,
  updateSegment,
} from "../../../../redux/slices/admin/segmentSlice";
import { confirmAlert, errorAlert } from "../../../../helpers/alerts";
import { removeSegmentService, updateSegmentService } from "../../../../services/admin/segmentServices";
import { validatePermission } from "../../../../helpers/authentication";

const SegmentScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { segments } = useSelector((store) => store.segments);

  const handlerBack = () => navigate(-1);

  const handlerAdd = () => navigate("crear");

  const handlerEdit = (row) => {
    dispatch(setCurrentSegment(row));
    navigate("editar");
  };

  const handlerCheck = async ({ id, idOperator }, currentValue) => {
    const data = { state: !currentValue }
    updateSegmentService(idOperator, id, data)
      .then(() => dispatch(updateSegment({ id, data })))
      .catch(error => errorAlert(error))
  };

  const handlerDelete = ({ id, idOperator, name }) => {
    confirmAlert({
      title: "Estas seguro que deseas eliminar este segmento?",
      text: `El segmento ${name} será eliminado.`,
      onConfirm: async () => {
        removeSegmentService(idOperator, id)
          .then(() => dispatch(removeSegment(id)))
          .catch(error => errorAlert(error))
      }
    })
  };

  return (
    <>
      <BackButton name={"Segmentos"} click={handlerBack} />
      <TableData
        dataRows={segments}
        headerButtons={[
          validatePermission('productos', 'productos', ['agregar']) &&
          <CustomButton
            icon={<AddCircleOutline fontSize="large" />}
            key="addSegment"
            name={"Segmento"}
            click={handlerAdd}
          />,
        ]}
        dataTitle={["Logo", "Nombre", "Código", "Carrier", "Descripción", "Estado"]}
        formatter={({ name, code, carrierId, description, fd, state }) => [
          { type: "img", value: `/v3/file/${fd}/` },
          name,
          code,
          carrierId,
          description,
          { type: "boolean", value: state, disabled: !validatePermission('productos', 'productos', ['editar']) }
        ]}
        onChecked={handlerCheck}
        actions={[
          validatePermission('productos', 'productos', ['editar']) &&
          {
            label: "Editar",
            icon: <EditIcon fontSize="medium" />,
            onClick: (data) => {
              handlerEdit(data);
            },
          },
          validatePermission('productos', 'productos', ['eliminar']) &&
          {
            label: "Eliminar",
            icon: <DeleteForeverIcon fontSize="medium" />,
            onClick: (data) => {
              handlerDelete(data);
            },
          },
        ]}
      />
    </>
  );
};

export default SegmentScreen;
