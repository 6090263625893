import service from "../service";

export const getPermissionsService = () => (
  service('get', `v3/seguridad/permiso`)
)

export const createPermissionService = (data) => (
  service('post', `v3/seguridad/permiso`, {
    contentType: "multipart/form-data",
    form: { ...data }
  })
)

export const updatePermissionService = (permissionId, data) => (
  service('patch', `v3/seguridad/permiso/${permissionId}`, {
    form: { ...data }
  })
)

export const removePermissionService = (permissionId) => (
  service('delete', `v3/seguridad/permiso/${permissionId}`)
)

export const getPermissionTypeService = () => {
  return [
    { id: 'admin', name: 'Administrador' },
    { id: 'commerce', name: 'Comercio' }
  ]
}
