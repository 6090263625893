import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import OperatorForm from "../../../../components/admin/OperatorForm";
import { errorAlert } from "../../../../helpers/alerts";
import { addOperator } from "../../../../redux/slices/admin/operatorSlice";
import { createOperatorService } from "../../../../services/admin/operatorServices";

const ProductCreateOperatorScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const values = {
    name: "",
    carrierId: 0,
    file: null
  }

  const handlerBack = () => navigate(-1);

  const handlerSubmit = async (result) => {
    createOperatorService(result)
      .then((response) => {
        dispatch(addOperator(response));
        handlerBack()
      })
      .catch(e => errorAlert(e))
  };

  return (
    <OperatorForm
      title="Crear Operadora"
      goBack={handlerBack}
      defaultValues={values}
      submit={handlerSubmit}
    />
  );
};

export default ProductCreateOperatorScreen;
