import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  IconButton,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid,
  ButtonGroup,
  Tooltip,
  TextField,
} from "@mui/material";
import "./TableData.css";
import { secundaryColor } from "../../config/theme";
import { set } from "react-hook-form";

const TableData = ({
  dataTitle = [],
  dataRows = [],
  formatter = (data) => data,
  headerButtons,
  onChecked,
  onClick,
  onChange,
  actions,
  style,
  loading,
  onChangePage = () => { },
  onChangeRowsPerPage = () => { },
  rowsPerPageOpt = 25,
  search = true,
  pagination = true,
  autonumber = true,
  headerStyle = {}
}) => {
  actions = actions?.filter(item => item)
  actions = actions?.length === 0 ? null : actions
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOpt);
  const StyledTableRow = styled(TableRow)(({ theme }) => {
    return {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.grey[300],
      },
      "&:last-child td, &:last-child th": {
        border: 0,
      },
    };
  });

  useEffect(() => {
    setPage(0);
  }, [dataRows]);

  const handlerChangePage = (e, newPage) => {
    setPage(newPage);
    // onChangePage(newPage)
  };

  const handlerChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    // onChangeRowsPerPage(e.target.value)
    setPage(0);
  };

  const visibleRows = React.useMemo(
    () =>
      dataRows.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [dataRows, page, rowsPerPage],
  );

  return (
    <div className="wrapper-table">
      {
        search && (
          <Grid container columnSpacing={2} mb={1} mt={1} justifyContent={"flex-end"}>
            {headerButtons &&
              <ButtonGroup variant="contained" aria-label="outlined primary button group" style={{ boxShadow: 'none' }}>
                {headerButtons.map((component) => component)}
              </ButtonGroup>
            }
          </Grid>
        )
      }
      <TableContainer component={Paper} sx={{ maxHeight: '80vh' }}>
        <div className="wrapper-data" style={style}>
          <Table
            stickyHeader
            // sx={{ minWidth: 650 }}
            size="small"
            aria-label="simple table"
            classes={{ root: 'table-data' }}
          >
            {
              loading ? (
                <tbody><tr style={{ width: '100%', textAlign: 'center' }}><td>Cargando...</td></tr></tbody>
              ) : (
                <>
                  <TableHead >
                    <TableRow>
                      {
                        !dataTitle.includes('Id') && autonumber && (
                          <TableCell
                            sx={{
                              fontWeight: 'bold',
                              backgroundColor: secundaryColor,
                              color: '#ffffff',
                              textAlign: 'center',
                              width: 10
                            }}
                          />
                        )
                      }
                      {
                        dataTitle.map((title, h) => (
                          <TableCell sx={{
                            fontWeight: 'bold',
                            backgroundColor: secundaryColor,
                            color: '#ffffff',
                            textAlign: 'center',
                            ...headerStyle
                          }} key={h + "title"} >{title}</TableCell>
                        ))
                      }
                      {actions && <TableCell sx={{
                        fontWeight: 'bold',
                        backgroundColor: secundaryColor,
                        color: '#ffffff',
                        textAlign: 'center'
                      }} >Acciones</TableCell>}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      visibleRows.map((item, i) => (
                        <StyledTableRow
                          key={i + "row"}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            "& td": item.style
                          }}
                        >
                          {
                            !dataTitle.includes('Id') && autonumber && <TableCellFormatter value={i + 1} />
                          }
                          {
                            formatter(item).map((value, key) => {
                              return <TableCellFormatter
                                key={key + "cell"}
                                data={item}
                                value={value}
                                onChecked={onChecked}
                                onChange={onChange}
                              />
                            })
                          }
                          {
                            actions && (
                              <TableCellActions data={item} actions={actions} />
                            )
                          }
                        </StyledTableRow>
                      ))
                    }
                  </TableBody>
                </>
              )
            }

          </Table>
        </div>
      </TableContainer>
      {
        pagination && (
          <TablePagination
            rowsPerPageOptions={dataRows.length === 0 ? [] : [15, 25, 50, 100, 200, 500]}
            component="div"
            count={dataRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlerChangePage}
            onRowsPerPageChange={handlerChangeRowsPerPage}
            labelDisplayedRows={({ from, to, count }) => `${from}–${to} de ${count}`}
            labelRowsPerPage={dataRows.length === 0 ? '' : "Filas por página:"}
          />
        )
      }
    </div>
  );
};


const TableCellFormatter = ({ value, data, onChecked }) => {
  if (value === null)
    return <TableCell sx={{ textAlign: 'center' }}></TableCell>
  if (typeof value === 'object') {
    if (value.type === 'boolean')
      return (
        <TableCellBoolean
          value={value.value}
          data={data}
          onChecked={onChecked || value.onChange}
          col={value.name}
          disabled={value.disabled}
        />
      );
    else if (value.type === 'img')
      return (
        <TableCellImage
          value={value.value}
        />
      );
    else if (value.type === 'button')
      return (
        <TableCellButton
          data={data}
          value={value.value}
          onClick={value.action}
        />
      );
    else if (value.type === 'select_button')
      return (
        <TableCellSelectButton
          list={value.list}
          value={value.value}
          data={data}
          label={value.label}
          disabled={value.disabled}
          onChange={value.onChange}
        />
      );
    else if (value.type === 'input')
      return (
        <TableCellInput
          value={value.value}
          data={data}
          onChange={value.onChange}
          disabled={value.disabled}
          onBlur={value.onBlur}
          onEnter={value.onEnter}
        />
      );
    else
      return <TableCell sx={{ textAlign: 'center' }}>{value}</TableCell>;
  }
  else
    return <TableCell sx={{ textAlign: 'center' }}>{value}</TableCell>;
};

const TableCellBoolean = ({ value, data, onChecked, col, disabled }) => {

  return (
    <TableCell
      sx={{ height: 'auto', textAlign: 'center' }}
    >
      <Checkbox
        checked={value}
        onChange={() => onChecked(data, value, col)}
        inputProps={{ "aria-label": "controlled" }}
        disabled={disabled}
      />
    </TableCell>
  );
};

const TableCellImage = ({ value }) => {
  return (
    <TableCell sx={{ textAlign: 'center' }}>
      <img alt="" className="img-cell" src={value} />
    </TableCell>
  );
};

const TableCellButton = ({ data, value, onClick }) => {
  return (
    <TableCell sx={{ textAlign: 'center' }}>
      <IconButton
        title="ver"
        aria-label="view"
        onClick={() => onClick(data)}
      >
        {value}
      </IconButton>
    </TableCell>
  );
};
const TableCellInput = ({ data, value, disabled, onChange, onBlur, onEnter }) => {
  const [val, setVal] = useState(value)
  return (
    <TableCell sx={{ textAlign: 'center' }}>
      <TextField
        value={val}
        size="small"
        inputProps={{
          style: {
            textAlign: 'center'
          }
        }}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => {
          setVal(e.target.value)
          onChange && onChange(e.target.value, data)
        }}
        disabled={disabled}
        onBlur={() => onBlur(val, data)}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            onEnter(val, data)
          }
        }}
      />
    </TableCell>
  );
};

const TableCellSelectButton = ({ data, list, value, label, disabled, onChange }) => {
  return (
    <TableCell sx={{ textAlign: 'center' }}>
      <FormControl sx={{ p: 0, m: 0, minWidth: 200 }} size="small">
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value || ''}
          onChange={(e) => {
            onChange(e.target.value, data)
          }}
          disabled={disabled}
          label={label}
        >
          {list.map((item, i) => (
            <MenuItem key={"item_" + item.name + i} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </TableCell>
  );
};

const TableCellActions = ({ data, actions }) => {
  return (
    <TableCell sx={{ textAlign: 'center' }}>
      <ButtonGroup variant="contained" aria-label="outlined primary button group" style={{ boxShadow: 'none' }}>
        {
          actions.filter((action) => action.validate ? action.validate(data) : true).map(({ icon, label, onClick }, i) => (
            <Tooltip
              key={i}
              title={label}
              arrow
            >
              <IconButton onClick={() => onClick(data)} >
                {icon}
              </IconButton>
            </Tooltip>
          ))
        }
      </ButtonGroup>
    </TableCell>
  );
};

export default TableData;
