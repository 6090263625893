import React from 'react';
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import SearchBar from './SearchBar';
import "./PermissionsCheck.css";

const PermissionCheck = ({ titles, permissions, onChecked }) => {
  return (
    <div className="wrapper-table" >
      <div className='container_search'>
        <SearchBar />
      </div>
      <div className='container-table'>
        <TableContainer component={Paper}>
          <Table
            size="small"
            sx={{ minWidth: 650 }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell width={'30%'} ></TableCell>
                {
                  titles.map((name, i) => (
                    <TableCell key={i} align="center" >{name}</TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
        <div style={{ overflow: 'auto', height: '50vh'}}>
          {
            permissions.map(({ name, permissions }, h) => (
              <TableContainer key={h} component={Paper}>
                <Table
                  size="small"
                  sx={{ minWidth: 650 }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={5}>{name}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      permissions.map(({ id, name, actions }, i) => (
                        <TableRow
                          key={i}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell
                            className='table-th'
                            width={'27%'}
                            component="th"
                            scope="row"
                          >{name}
                          </TableCell>
                          {
                            titles.map((name, i) => (
                              <TableCell key={i} className='table-th' align="center">
                                <Checkbox
                                  checked={actions?.includes(name)}
                                  onChange={() => onChecked(id, name, actions?.includes(name))}
                                  inputProps={{ "aria-label": "controlled" }}
                                />
                              </TableCell>
                            ))
                          }
                        </TableRow>
                      ))
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            ))
          }
        </div>
      </div>

    </div>
  )
}

export default PermissionCheck