import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { errorAlert } from '../../helpers/alerts';
import { getConciliacionHistoryLogService } from 'services/report/reportService';

const ConciliationLogModal = ({ id, open, onClose }) => {
  const [log, setLog] = useState([])

  useEffect(() => {
    if (id)
      getConciliacionHistoryLogService(id)
        .then((response) => setLog(response))
        .catch((e) => {
          errorAlert(e)
          onClose()
        })
    // eslint-disable-next-line
  }, [id]);

  return (
    <Dialog
      open={open}
      fullWidth={true}
      onClose={onClose}
    >
      <>
        <DialogTitle sx={{ textAlign: 'center' }}>
          Log de conciliación
        </DialogTitle>
        <DialogContent >
          {log.map((item, index) => (
            <pre key={index} style={{margin: 0, fontSize: 10}}>{item}</pre>
          ))}
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ m: 2, fontWeight: "bold", backgroundColor: "#0E73ED" }}
            onClick={onClose}
            variant="contained"
          >
            Cerrar
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}

export default ConciliationLogModal