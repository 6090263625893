import { Button } from '@mui/material'
import React from 'react'
import './CustomButton.css'

const CustomButton = ({ name, click, additional_class, icon }) => {
  return (
    <Button
      startIcon={icon}
      className={`button ${additional_class}`}
      onClick={click}
    >
      {name}
    </Button>
  )
}

export default CustomButton

