import React, { useEffect, useState } from 'react'
import { CustomButton, FilterBar, TableData } from '../../components'
import DownloadIcon from "@mui/icons-material/Download";
import { useDispatch, useSelector } from 'react-redux';
import { downloadBinnacleReportService, downloadDetailedReportService, getBinnacleReportService } from '../../services/report/reportService';
import { errorAlert } from '../../helpers/alerts';
import { setBinnacleReport, setBinnacleReportFilters } from 'redux/slices/report/reportSlice';
import moment from 'moment';
import { downloadFile } from 'helpers/util';
import { setIcon, setTitle } from 'redux/slices/uiSlice';
import { Plagiarism } from '@mui/icons-material';

const BinnacleScreen = () => {
  const dispatch = useDispatch();
  const { binnacleReport, binnacleReportFilters } = useSelector(store => store.reports);
  const [loading, setLoading] = useState(false)
  const [types, setTypes] = useState([])
  const [users, setUsers] = useState([])

  useEffect(() => {
    dispatch(setTitle('Reporte de Bitacora'));
    dispatch(setIcon(<Plagiarism fontSize='large' htmlColor='#0E73ED' />));
  }, [])


  useEffect(() => {
    dispatch(setTitle('Reporte de bitacora'));
    setTypes(binnacleReport
      .map(({ event: id, event: name }) => ({ id, name }))
      .filter(({ id }, index) => index === binnacleReport.findIndex(o => id === o.event))
    );
    setUsers(binnacleReport
      .map(({ userId: id, userName: name }) => ({ id, name }))
      .filter(({ id }, index) => index === binnacleReport.findIndex(o => id === o.userId))
    );
  }, [binnacleReport])

  const handlerSubmit = async (data) => {
    dispatch(setBinnacleReportFilters(data))
    setLoading(true)
    getBinnacleReportService(data).then((result) => dispatch(setBinnacleReport(result)))
      .catch((error) => errorAlert(error))
      .finally(() => setLoading(false));
  }

  const handlerDownload = () => {
    downloadBinnacleReportService([], binnacleReportFilters)
      .then((data) => downloadFile(data, 'Reporte de Bitacora.xlsx'))
      .catch((e) => errorAlert(e))
  }

  return (
    <>
      <FilterBar
        handlerFilter={handlerSubmit}
        defaultValues={binnacleReportFilters || {
          startDate: moment().format('YYYY-MM-DD'),
          endDate: moment().format('YYYY-MM-DD'),
        }}
        allValue={null}
        filters={[
          {
            name: "startDate",
            label: "Fecha Inicio",
            type: "date",
          },
          {
            name: "endDate",
            label: "Fecha Fin",
            type: "date",
          },
          {
            name: 'type',
            label: "Tipo",
            type: "select",
            all: 'Todos',
            data: types,
          },
          {
            name: 'user',
            label: "Usuario",
            type: "select",
            all: 'Todos',
            data: users,
          },
        ]}
      />
      <TableData
        loading={loading}
        dataRows={binnacleReport}
        headerButtons={[
          <CustomButton
            icon={<DownloadIcon />}
            key="download"
            name={"Descargar"}
            click={handlerDownload}
          />,
        ]}
        dataTitle={[
          "Fecha",
          "Tipo",
          "Descripcion",
          "Usuario",
        ]}
        formatter={({ userName, event, createdAt, description }) => ([
          moment(createdAt).format('DD/MM/YYYY HH:mm:ss'),
          event,
          description,
          userName,
        ])}
      />
    </>

  )
}

export default BinnacleScreen