import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { errorAlert } from '../../../helpers/alerts';
import { hasSameValues } from '../../../helpers/util';
import { updateGroupService } from '../../../services/client/groupService';
import { updateGroup } from '../../../redux/slices/client/groupSlice';
import GroupForm from '../../../components/client/GroupForm';
import { setLoading } from '../../../redux/slices/uiSlice';

const EditGroupScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentGroup: current } = useSelector((store) => store.group);
 
  const values = {
    name: current.name,
  }

  const handlerBack = () => {
    navigate("/cliente/grupos");
  };

  const handlerSubmit = async (data) => {
    dispatch(setLoading(true))
    updateGroupService(current.id, data).then(() => {
      dispatch(updateGroup({ id: current.id, data }));
      navigate("/cliente/grupos");
    }).catch((e) => errorAlert(e))
      .finally(() => dispatch(setLoading(false)))
  }

  const validate = (data) => !hasSameValues(values, data)
  return (
    <GroupForm
      title={"Grupos"}
      goBack={handlerBack}
      submit={handlerSubmit}
      validate={validate}
      defaultValues={values}
    />
  )
}

export default EditGroupScreen