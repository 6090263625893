import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BackButton, TableData } from "../../../../../components";
import { errorAlert } from "../../../../../helpers/alerts";
import { getPINSegmentsService } from "services/admin/productService";
import { createCredPINSegmentsService, deleteCredPINSegmentsService } from "services/admin/providerService";
import { setPINSegments as setProviderPINSegments } from "redux/slices/admin/providerSlice";
import { Grid } from "@mui/material";
import { setPINSegments } from "redux/slices/admin/productSlice";

const CredPINSegmentScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    providerCredSelected,
    pinSegments: providerPINSegments,
  } = useSelector((store) => store.providers);
  const {
    pinSegments
  } = useSelector((state) => state.products);


  useEffect(() => {
    getPINSegmentsService()
      .then((data) => dispatch(setPINSegments(data)))
      .catch(error => errorAlert(error))
    // eslint-disable-next-line
  }, []);

  const handlerCheck = async ({ id }, currentValue) => {
    (currentValue ?
      deleteCredPINSegmentsService(providerCredSelected.id, id) :
      createCredPINSegmentsService(providerCredSelected.id, id)
    ).then((result) => {
      dispatch(setProviderPINSegments(result));
    }).catch(error => errorAlert(error))
  };

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <div>
          <BackButton click={() => navigate(-1)} />
        </div>
        <div className="container-title">
          <div className="commerce">Segmentos</div>
        </div>
        <div>
        </div>
      </Grid>
      <TableData
        dataRows={pinSegments.map((item) => {
          const find = providerPINSegments.find(({ idSegment }) => idSegment.id === item.id);
          return { ...item, state: find ? true : false };
        })}
        dataTitle={["Logo", "Nombre", "Estado"]}
        formatter={({ name, state, fd }) => [
          { type: "img", value: `/v3/file/${fd}/` },
          name,
          { name: "state", type: "boolean", value: state },
        ]}
        onChecked={handlerCheck}
      />
    </>
  );
};

export default CredPINSegmentScreen;
