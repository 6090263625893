import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { batch, useDispatch, useSelector } from 'react-redux';
import { setTitle } from 'redux/slices/uiSlice';
import { AmountProviderForm } from 'components';
import { putProviderCredsAmountsService } from 'services/admin/providerService';


function CreateAmountScreen() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { providerCredSelected } = useSelector(store => store.providers)

    useEffect(() => {
        dispatch(setTitle('Nuevo Amount de Proveedor'));
        // eslint-disable-next-line
    }, [])

    return (
        <AmountProviderForm
            title={"Cancelar"}
            defaultValues={{
                operadora: "",
                segmento: "",
                product: "",
                sku: ""
            }}
            goBack={() => {
                navigate(-1);
            }}
            submit={(data) => {
                data['providerCred'] = providerCredSelected.id;
                putProviderCredsAmountsService(data).then((result) => {
                    console.log(result);
                    navigate(-1);
                }).catch((e) => {
                    console.log(e);
                    navigate(-1);
                });
            }}
        />
    );
}

export default CreateAmountScreen;