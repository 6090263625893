import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showSuccesChangePassword: false,
  title: null,
  icon: null,
  updatePasswordToken: null,
  sidebar: true,
  loading: false
}

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setUpdatePasswordToken: (state, action) => ({
      ...state,
      updatePasswordToken: action.payload
    }),
    setShowSuccesChangePassword: (state, action) => ({
      ...state,
      showSuccesChangePassword: action.payload
    }),
    setTitle: (state, action) => ({
      ...state,
      title: action.payload
    }),
    setIcon: (state, action) => ({
      ...state,
      icon: action.payload
    }),
    showSidebar: (state, action) => ({
      ...state,
      sidebar: action.payload
    }),
    setLoading: (state, action) => ({
      ...state,
      loading: action.payload
    }),
    clean: () => initialState
  }
})

export const { setUpdatePasswordToken, setShowSuccesChangePassword, setTitle, setIcon, showSidebar, setLoading, clean } = uiSlice.actions

export default uiSlice.reducer

