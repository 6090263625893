import React from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ProviderForm } from '../../../components';
import { errorAlert } from '../../../helpers/alerts';
import { setProviders } from '../../../redux/slices/admin/providerSlice';
import { createProviderService } from '../../../services/admin/providerService';

const CreateProviderScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const values ={
    name: "",
    type: "",
    host: "",
  };

  const handlerBack = () => {
    navigate("/admin/proveedores");
  };

  const handlerSubmit = async(result) => {
    result = { ...result, container_name: result.name }
    try{
      const response = await createProviderService(result);
      dispatch(setProviders(response))
      navigate("/admin/proveedores");
    }catch(error){
      errorAlert(error);
    }
  };

  return (
    <ProviderForm
      title={"Crear Proveedor"}
      goBack={handlerBack}
      defaultValues={values}
      submit={handlerSubmit}
    />
  )
}

export default CreateProviderScreen