import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UserForm } from "../../../components";
import { errorAlert } from "../../../helpers/alerts";
import { createUserService } from "../../../services/security/userServices";


const CreateUserScreen = ({ type = 'commerce' }) => {
  const { hoster } = useSelector((store) => store.session);
  const navigate = useNavigate();
  const values = {
    nameUser: "",
    name: "",
    lastname: "",
    email: "",
    rol: "",
  }

  const handlerBack = () => navigate(`/seguridad/${type === 'admin' ? 'administradores' : 'usuarios'}`);

  const handlerSubmit = (result) => {
    createUserService({
      ...result,
      hoster,
      type,
    }).then(() => navigate(`/seguridad/${type === 'admin' ? 'administradores' : 'usuarios'}`))
      .catch((e) => errorAlert(e))
  };

  return (
    <UserForm
      title={type === 'admin' ? 'Administradores' : 'Usuarios'}
      goBack={handlerBack}
      defaultValues={values}
      submit={handlerSubmit}
      data={{ type }}
    />
  );
};

export default CreateUserScreen;
