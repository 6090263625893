import { Grid } from '@mui/material'
import React, { useEffect } from 'react'
import './FavoritesSales.css'
import { getFavorites } from '../../services/sale/servipagosService';
import { setFavorites } from '../../redux/slices/sale/airTimeSlice';
import { useDispatch, useSelector } from 'react-redux';
import { errorAlert } from '../../helpers/alerts';

const FavoritesSales = ({ onSelect }) => {
  const dispatch = useDispatch();
  const { favorites, update } = useSelector(store => store.airTimeSale);

  useEffect(() => {
    getFavorites()
      .then((response) => dispatch(setFavorites(response)))
      .catch((e) => errorAlert(e))
    // eslint-disable-next-line   
  }, [update])

  return (
    favorites.length !== 0 && (
      <Grid >
        <h3 className='title'>FAVORITOS</h3>
        <Grid container spacing={4} >
          {
            favorites.map(({ product }, key) => (
              <Grid key={key} xs={4} item onClick={() => onSelect(product)} style={{ cursor: 'pointer' }}>
                <Grid container direction={'row'} className='item' justifyContent={'center'}>
                  <Grid flex={1} item >
                    <div className='img' style={{ backgroundImage: `url(/v3/file/${product?.segment.fd}/)` }}></div>
                  </Grid>
                  <Grid flex={1} container item direction={'column'} justifyContent={'center'}>
                    <Grid item>{product?.operator.name}</Grid>
                    <Grid item>{product?.segment.name}</Grid>
                    <Grid item>${product?.denomination}.00</Grid>
                  </Grid>
                </Grid>
              </Grid>
            ))
          }
        </Grid>
      </Grid>
    )
  )
}

export default FavoritesSales