import React from 'react'
import { Outlet, useNavigate } from 'react-router-dom';
import { CustomTab } from '../../../components'

const AlertTab = () => {
  const navigate = useNavigate();

  return (
    <CustomTab
      panels={[
        {
          name: "Eventos",
          route: '/alertas/eventos',
          onChange: () => navigate('eventos'),
          content: <Outlet />
        },
        {
          name: "Alertas",
          route: '/alertas/configuracion',
          onChange: () => navigate('configuracion'),
          content: <Outlet />
        },
      ]}
    />
  )
}

export default AlertTab