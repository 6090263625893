import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentRegion: null,
  regions: []
}

export const regionsSlice = createSlice({
  name: 'regions',
  initialState,
  reducers: {
    setRegions: (state, action) => ({
      ...state,
      regions: action.payload
    }),
    setCurrentRegion: (state, action) => ({
      ...state,
      currentRegion: action.payload
    })
  }
})
export const {setRegions,setCurrentRegion} = regionsSlice.actions


export default regionsSlice.reducer