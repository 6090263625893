import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ConnectorForm } from '../../../components';
import { errorAlert } from '../../../helpers/alerts';
import { hasSameValues } from '../../../helpers/util';
import { setConnectors } from '../../../redux/slices/admin/connectorSlice';
import { updateConnectorService } from '../../../services/admin/connectorService';

const EditConnectorScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentConnector: current } = useSelector((store) => store.connectors)
  const values = {
    name: current.container_name,
    slow_response_time: current.slow_response_time,
    optimal_response_time: current.optimal_response_time,
    critical_response_time: current.critical_response_time,
    response_code: current.param_response_code,
    success_code: current.value_success_code,
    collection: current.name_collection,
    type: current.onType,
    description: current.description
  }

  const handlerBack = () => {
    navigate("/admin/conectores");
  };
  const handlerSubmit = async (data) => {
    data = {
      ...data,
      container_name: data.name,
      param_response_code: data.response_code,
      value_success_code: data.success_code,
      name_collection: data.collection,
      onType: data.type
    };
    try {
      const response = await updateConnectorService(current.id, data);
      dispatch(setConnectors(response));
      navigate("/admin/conectores");
    } catch (error) {
      errorAlert(error);
    }
  };
  const validate = (data) => !hasSameValues(values, data)
  return (
    <ConnectorForm
      title="Editar Denominación Extra"
      goBack={handlerBack}
      defaultValues={values}
      submit={handlerSubmit}
      validate={validate}
    />
  );
}

export default EditConnectorScreen