import React, { useEffect, useState } from "react";
import { Button, Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import BackButton from "../BackButton";
import InputTextFieldGrid from "../common/InputTextFieldGrid";
import InputSelectFieldGrid from "components/common/InputSelectFieldGrid";
import InputChipFieldGrid from "components/common/InputChipFieldGrid";
import SteepsBox from "components/common/SteepsBox";
import { getProvidersToRuteoV2Service } from "services/admin/providerService";

const AlertForm = ({ title, goBack, defaultValues, submit }) => {
  const { control, handleSubmit, watch, setValue, clearErrors } = useForm({ defaultValues });
  const { txnState } = watch();
  const [instances, setInstances] = useState([])
  
  useEffect(() => {
    getProvidersToRuteoV2Service().then((response) => setInstances(response))
  }, [])

  return (
    <>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" >
        <div>
          <BackButton click={goBack} />
        </div>
        <div className='container-title'>
          <div className='title'>{title}</div>
        </div>
        <div>
        </div>
      </Grid>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          container
          direction="row"
          m={"40px"}
          maxWidth={"70%"}
          justifyContent="center"
          spacing={3}
        >
          <SteepsBox
            formatter={(content) => content.map(component => component)}
            steps={[
              {
                label: "Identificacion",
                content: [
                  <InputTextFieldGrid
                    xs={12}
                    name={"name"}
                    control={control}
                    required={"Debes agregar el nombre"}
                    label={"Nombre"}
                  />,
                ]
              },
              {
                label: "Condiciones",
                content: [
                  <InputSelectFieldGrid
                    xs={12}
                    name={"txnState"}
                    control={control}
                    required={"Debes seleccionar el estado de las transacciones"}
                    label={"Estado de la transacción"}
                    data={[
                      { id: "approved", name: "APROBADAS" },
                      { id: "declined", name: "DENEGADAS" },
                    ]}
                  />,
                  <InputTextFieldGrid
                    xs={12}
                    name={"txns"}
                    control={control}
                    label={"Cantidad de transacciones consecutivas"}
                    required={"Debes agregar la cantidad"}
                    inputType={"number"}
                  />,
                  txnState === "approved" && <InputTextFieldGrid
                    xs={12}
                    name={"time"}
                    control={control}
                    label={"Tiempo de la transacción mayor a (segundos)"}
                    required={"Debes agregar el tiempo"}
                    inputType={"number"}
                  />,
                  <InputSelectFieldGrid
                    xs={12}
                    name={"instance"}
                    control={control}
                    label={"Instancia"}
                    data={[
                      { id: 0, name: "Todas" },
                      ...instances.map(item => ({ id: item._id, name: item.container_name }))
                    ]}
                  />
                ].filter(Boolean)
              },
              {
                label: "Notificaciones",
                content: [
                  <InputChipFieldGrid
                    xs={12}
                    name={"emails"}
                    control={control}
                    label={"Correos electrónicos"}
                  />,
                ]
              }
            ]}
          />
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            sx={{ m: 2, fontWeight: "bold", backgroundColor: "#0E73ED" }}
            onClick={handleSubmit(submit)}
            // disabled={disabled}
            variant="contained"
          >
            Guardar
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default AlertForm;
