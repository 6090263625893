import React from "react";
import { Button, Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import BackButton from "../BackButton";
import InputTextFieldGrid from "../common/InputTextFieldGrid";
import CheckboxFieldGrid from "components/common/CheckboxFieldGrid";

const CredProductForm = ({ title, subtitle, goBack, defaultValues, submit, type }) => {
  const { control, handleSubmit } = useForm({ defaultValues });
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <div>
          <BackButton click={goBack} />
        </div>
        <div className="container-title">
          <div className="commerce">{title}</div>
          <div className="bag">{subtitle}</div>
        </div>
        <div>
        </div>
      </Grid>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          container
          direction="row"
          m={"40px"}
          maxWidth={"70%"}
          justifyContent="center"
          spacing={3}
        >
          <InputTextFieldGrid
            xs={12}
            name={"sku"}
            control={control}
            required={"Debes agregar el nombre"}
            label={"SKU"}
          />
          {
            type === 'pds' ? (
              <>
                <CheckboxFieldGrid
                  xs={4}
                  name={'inline'}
                  control={control}
                  label={'En linea'}
                />
                <CheckboxFieldGrid
                  xs={4}
                  name={'partialPayments'}
                  control={control}
                  label={'Pagos parciales'}
                />
                <CheckboxFieldGrid
                  xs={4}
                  name={'balanceInquiry'}
                  control={control}
                  label={'Consulta de saldo'}
                />
                <InputTextFieldGrid
                  xs={4}
                  name={"commissionProvider"}
                  control={control}
                  label={"Comisión proveedor"}
                  inputType={"number"}
                />
                {/* <InputTextFieldGrid
                  xs={4}
                  name={"commissionChannel"}
                  control={control}
                  label={"Comisión canal"}
                  inputType={"number"}
                /> */}
                <InputTextFieldGrid
                  xs={4}
                  name={"commissionUserMax"}
                  control={control}
                  label={"Comisión máxima usuario"}
                  inputType={"number"}
                />
              </>
            ) : (
              <>
              </>
            )
          }
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            sx={{ m: 2, fontWeight: "bold", backgroundColor: "#0E73ED" }}
            onClick={handleSubmit(submit)}
            // disabled={disabled}
            variant="contained"
          >
            Guardar
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default CredProductForm;
