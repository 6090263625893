import service from "../service";

export const getRegionsService = () => (
  service('get', `v3/ladas/regiones`)
)

export const getLadasService = () => (
  service('get', `v3/ladas`)
)

export const createLadaService = (data) => (
  service('post', `v3/ladas`, {
    contentType: "multipart/form-data",
    form: { ...data }
  })
)

export const updateLadaService = (idLada, data) => (
  service('patch', `v3/ladas/${idLada}`, {
    contentType: "multipart/form-data",
    form: { ...data }
  })
)

export const removeLadaService = (idLada) => (
  service('delete', `v3/ladas/${idLada}`)
)
