import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { SegmentForm } from '../../../../components'
import { errorAlert } from '../../../../helpers/alerts';
import { addSegment } from '../../../../redux/slices/admin/segmentSlice';
import { createSegmentService } from '../../../../services/admin/segmentServices';

const ProductCreateSegmentScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentOperator: { id: idOperator } } = useSelector((store) => store.operators);

  const values = {
    name: "",
    description: "",
    code: "",
    file: null
  }

  const handlerBack = () => navigate(-1);

  const handlerSubmit = async (result) => {
    createSegmentService(idOperator, result)
      .then(response => {
        dispatch(addSegment(response))
        navigate(-1);
      }).catch(error => errorAlert(error))
  };

  return (
    <SegmentForm
      title="Crear Segmento"
      goBack={handlerBack}
      defaultValues={values}
      submit={handlerSubmit}
    />
  )
}

export default ProductCreateSegmentScreen