import React from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { errorAlert } from '../../../helpers/alerts';
import { addGroup } from '../../../redux/slices/client/groupSlice';
import { createGroupService } from '../../../services/client/groupService';
import GroupForm from '../../../components/client/GroupForm';

const CreateGroupScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handlerBack = () => {
    navigate(-1);
  };

  const handlerSubmit = async (data) => {
    createGroupService(data).then((response) => {
      dispatch(addGroup(response));
      navigate("/cliente/grupos");
    }).catch((e) => errorAlert(e))
  }

  return (
    <GroupForm
      title={"Grupos"}
      goBack={handlerBack}
      submit={handlerSubmit}
    />
  )
}

export default CreateGroupScreen