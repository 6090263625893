import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentGroup: null,
  groups: [],
}

export const groupSlice = createSlice({
  name: 'group',
  initialState,
  reducers: {
    setGroups: (state, action) => ({
      ...state,
      groups: action.payload
    }),
    addGroup: (state, action) => ({
      ...state,
      groups: [
        ...state.groups,
        action.payload
      ]
    }),
    updateGroup: (state, action) => ({
      ...state,
      groups: state.groups.map((group) => {
        if (group.id === action.payload.id)
          return { ...group, ...action.payload.data };
        return group;
      }),
    }),
    removeGroup: (state, action) => ({
      ...state,
      groups: state.groups.filter((group) => {
        return group.id !== action.payload;
      }),
    }),
    setCurrentGroup: (state, action) => ({
      ...state,
      currentGroup: action.payload
    })
  }

})
export const {
  setGroups,
  addGroup,
  updateGroup,
  removeGroup,
  setCurrentGroup,
} = groupSlice.actions


export default groupSlice.reducer