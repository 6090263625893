import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  roles: [],
  types: [],
  permissions: [],
  current: null,
}

export const roleSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    setRoles: (state, action) => ({
      ...state,
      roles: action.payload
    }),
    setRolesType: (state, action) => ({
      ...state,
      types: action.payload
    }),
    setPermissions: (state, action) => ({
      ...state,
      permissions: action.payload
    }),
    setCurrentRole: (state, action) => ({
      ...state,
      current: action.payload,
    }),
    createRole: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    updateRole: (state, action) => ({
      ...state,
      roles: state.roles.map((role) => {
        if (role.id === action.payload.id)
          return { ...role, ...action.payload.data }
        return role
      })
    }),
    removeRole: (state, action) => ({
      ...state,
      roles: state.roles.filter((role) => {
        return role.id !== action.payload
      })
    })
  }
});

export const {
  setRoles,
  createRole,
  updateRole,
  setRolesType,
  setPermissions,
  setCurrentRole,
  removeRole
} = roleSlice.actions;

export default roleSlice.reducer;