import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { errorAlert } from '../../../helpers/alerts';
import { updateLadaService } from '../../../services/admin/ladasService';
import { setLadas } from '../../../redux/slices/admin/ladasSlice';
import LadaForm from '../../../components/admin/LadaForm';
import { hasSameValues } from '../../../helpers/util';

const EditLadaScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {currentLada}=useSelector(store => store.ladas)
  const values = {
    lada:currentLada.lada,
    region: currentLada.region.id
  };

  const handlerBack =()=>{
    navigate("/ruteo/ladas/");
  };
  const handlerSubmit =async(result)=>{
    result.state =  currentLada.state;
    try{
        const response = await updateLadaService(currentLada.id,result);
        dispatch(setLadas(response));
        navigate("/ruteo/ladas/");
    }catch(error){
      errorAlert(error);
    }
  };
  const validate = (data) => !hasSameValues(values, data)
  return (
    <LadaForm
    title="Editar Lada"
    goBack={handlerBack}
    defaultValues={values}
    submit={handlerSubmit}
    validate={validate}
    />
  )
}

export default EditLadaScreen