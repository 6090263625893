import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DenominationForm } from '../../../../components'
import { errorAlert } from '../../../../helpers/alerts';
import { hasSameValues } from '../../../../helpers/util';
import { setDenominations } from '../../../../redux/slices/admin/denominationSlice';
import { updateDenominationService } from '../../../../services/admin/denominationServices';

const ProductEditDenominationScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentDenomination } = useSelector((store) => store.denominations)
  const values = {
    name: currentDenomination.denomination,
    imp: currentDenomination.imp,
    validity: currentDenomination.vig,
    min: currentDenomination.min,
    sms: currentDenomination.sms,
    gb: currentDenomination.gb,
    gift: currentDenomination.gift,
  }

  const handlerBack = () => navigate(-1);

  const handlerSubmit = async (data) => {
    data = {
      ...data,
      denomination: data.name,
      vig: data.validity,
    };
    try {
      const response = await updateDenominationService(currentDenomination.id, data)
      dispatch(setDenominations(response))
      navigate(-1);
    }
    catch (error) {
      errorAlert(error);
    }
  }

  const validate = (data) => !hasSameValues(values, data)

  return (
    <DenominationForm
      title="Editar Denominación"
      goBack={handlerBack}
      defaultValues={values}
      submit={handlerSubmit}
      validate={validate}
    />
  )
}

export default ProductEditDenominationScreen