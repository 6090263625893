import service from "../service";

export const getDetailedReportService = (data) => (
  service('post', `v3/reportes/detallado`, { data })
)

export const downloadDetailedReportService = (columns, filters) => (
  service('post', `v3/reportes/detallado/descargar`, {
    responseType: 'blob',
    data: { columns, filters }
  })
)

export const getTotalReportService = (data) => (
  service('post', `v3/reportes/totales`, { data })
)

export const getDailyTotalReportService = (data) => (
  service('post', `v3/reportes/totalesdiarios`, { data })
)

export const downloadDailyTotalReportService = (data) => (
  service('post', `v3/reportes/totalesdiarios/descargar`, { responseType: 'blob', data })
)


export const getBalanceReportService = (data) => (
  service('post', `v3/reportes/saldo`, { data })
)

export const downloadBalanceReportService = (data) => (
  service('post', `v3/reportes/saldo/descargar`, { responseType: 'blob', data })
)

export const getBinnacleReportService = (data) => (
  service('post', `v3/reportes/bitacora`, { data })
)

export const downloadBinnacleReportService = (columns, filters) => (
  service('post', `v3/reportes/bitacora/descargar`, {
    responseType: 'blob',
    data: { columns, filters }
  })
)

// Conciliaciones


export const getProvidersService = (data) => (
  service('post', `v3/reportes/providers`, { data })
)

export const getConciliacionesDiferenciasReportService = (data) => (
  service('post', `v3/reportes/conciliaciondiff`, { data })
)

export const getConciliacionesReportService = (data) => (
  service('post', `v3/reportes/conciliacion`, { data })
)

export const downloadConciliacionesReportService = (data) => (
  service('post', `v3/reportes/conciliacion/descargar`, { responseType: 'blob', data })
)

// Conciliaciones history

export const getConciliacionHistoryService = (data) => (
  service('post', `v3/reportes/conciliacion/history`, { data })
)
export const getConciliacionHistoryLogService = (id) => (
  service('get', `v3/reportes/conciliacion/history/${id}`)
)