import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { loadState, saveState } from "../helpers/storeState";
import { cleanAuthorizationToken } from "../helpers/authentication";
import uiReducer from "./slices/uiSlice";
import sessionReducer from "./slices/sessionSlice";
import userReducer from "./slices/security/userSlice";
import adminReducer from "./slices/security/adminSlice";
import roleReducer from "./slices/security/roleSlice";
import permissionsReducer from "./slices/security/permissionSlice";
import categoriesReducer from "./slices/security/categorySlice";
import operatorReducer from "./slices/admin/operatorSlice";
import lawfulOperatorReducer from "./slices/admin/lawfulOperatorSlice";
import segmentReducer from "./slices/admin/segmentSlice";
import denominationReducer from "./slices/admin/denominationSlice";
import providerReducer from "./slices/admin/providerSlice";
import extraDenominationReducer from "./slices/admin/extraDenominationSlice";
import connectorsReducer from "./slices/admin/connectorSlice";
import ladasReducer from "./slices/admin/ladasSlice";
import routingReducer from "./slices/admin/routingSlice";
import regionsSlice from "./slices/admin/regionsSlice";
import commercesReducer from "./slices/client/commerceSlice"
import bagsReducer from "./slices/client/bagSlice";
import routeReducer from "./slices/route/routeSlice";
import exceptionReducer from "./slices/route/exceptionSlice";
import reportReducer from "./slices/report/reportSlice";
import airTimeSlice from "./slices/sale/airTimeSlice";
import salePointReducer from "./slices/client/salePointSlice";
import groupReducer from "./slices/client/groupSlice";
import productSlice from "./slices/admin/productSlice";
import alertReducer from "./slices/admin/alertSlice";
import serviceMonitorSlice from "./slices/admin/serviceMonitorSlice";

const appReducer = combineReducers({
  ui: uiReducer,
  session: sessionReducer,
  users: userReducer,
  admins: adminReducer,
  permissions: permissionsReducer,
  categories: categoriesReducer,
  operators: operatorReducer,
  lawfulOperators: lawfulOperatorReducer,
  segments: segmentReducer,
  denominations: denominationReducer,
  roles: roleReducer,
  providers: providerReducer,
  products: productSlice,
  extraDenominations: extraDenominationReducer,
  connectors: connectorsReducer,
  ladas: ladasReducer,
  routing: routingReducer,
  regions: regionsSlice,
  commerces: commercesReducer,
  bags: bagsReducer,
  salePoint: salePointReducer,
  group: groupReducer,
  route: routeReducer,
  exceptions: exceptionReducer,
  airTimeSale: airTimeSlice,
  reports: reportReducer,
  alert: alertReducer,
  serviceMonitor: serviceMonitorSlice
});

const store = configureStore({
  preloadedState: loadState(),
  reducer: (state, action) => {
    if (action.type === 'user/cleanSession') {
      state = undefined;
      cleanAuthorizationToken();
    }
    return appReducer(state, action);
  }
})

store.subscribe(() => {
  saveState(store.getState())
})

export default store