import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentCommerce: null,
  commerces: [],
  commerceTypes:[]
}

export const commerceSlice = createSlice({
  name: 'commerces',
  initialState,
  reducers: {
    setCommerces: (state, action) => ({
      ...state,
      commerces: action.payload
    }),
    setCommerceTypes: (state, action) => ({
      ...state,
      commerceTypes: action.payload
    }),
    addCommerce: (state, action) => ({
      ...state,
      commerces: [
        ...state.commerces,
        action.payload
      ]
    }),
    updateCommerce: (state, action) => ({
      ...state,
      commerces: state.commerces.map((commerce) => {
        if (commerce._id === action.payload.id)
          return { ...commerce, ...action.payload.data };
        return commerce;
      }),
    }),
    removeCommerce: (state, action) => ({
      ...state,
      commerces: state.commerces.filter((commerce) => {
        return commerce._id !== action.payload;
      }),
    }),
    setCurrentCommerce: (state, action) => ({
      ...state,
      currentCommerce: action.payload
    })
  }

})
export const {
  setCommerces,
  setCommerceTypes,
  addCommerce,
  updateCommerce,
  removeCommerce,
  setCurrentCommerce,
} = commerceSlice.actions


export default commerceSlice.reducer