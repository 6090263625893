import { AddCircleOutline, Password } from "@mui/icons-material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CustomButton, TableData } from "../../../components";
import {
  removeAdmin,
  setAdmins,
  updateAdmin,
} from "../../../redux/slices/security/adminSlice";
import {
  setCurrentUser,
} from "../../../redux/slices/security/userSlice";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import { confirmAlert, errorAlert } from "../../../helpers/alerts";
import { validatePermission } from "../../../helpers/authentication";
import { getUsersService, recoverPassword, removeUserService, updateUserService } from "../../../services/security/userServices";

const AdminScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { admins } = useSelector((store) => store.admins);

  useEffect(() => {
    getUsersService()
      .then((result) => dispatch(setAdmins(result)))
      .catch((e) => errorAlert(e))
    // eslint-disable-next-line
  }, []);

  const handlerClick = () => navigate("crear")

  const handlerCheck = (row, currentValue) => {
    const value = !currentValue;
    updateUserService(row.id, { state: value })
      .then(() => dispatch(updateAdmin({ id: row.id, data: { state: value } })))
      .catch((e) => errorAlert(e))
  };

  const handlerEdit = (row) => {
    dispatch(setCurrentUser(row));
    navigate("editar");
  };

  const handlerDelete = (row) => {
    confirmAlert({
      title: "Estas seguro que deseas eliminar este administrador?",
      text: `El administrador ${row.nameUser} será eliminado.`,
      onConfirm: () => {
        removeUserService(row.id)
          .then(() => dispatch(removeAdmin(row.id)))
          .catch((e) => errorAlert(e))
      },
    });
  };

  const handlerResetPassword = ({ email }) => {
    confirmAlert({
      title: `¿Estas seguro que deseas restablecer la contraseña del usuario ${email}?`,
      text: `Se enviará al usuario un correo electrónico con un enlace para restablecer`,
      onConfirm: () => {
        recoverPassword(email).then(() => {
          confirmAlert({
            icon: 'success',
            title: `¡Correo enviado exitosamente!`,
            showCancelButton: false,
          });
        }).catch((e) => errorAlert(e))
      },
    });
  }

  return (
    <TableData
      dataRows={admins}
      headerButtons={[
        validatePermission('administradores', 'administradores', ['agregar']) &&
        <CustomButton
          icon={<AddCircleOutline fontSize="large" />}
          key="addAdmin"
          name={"Crear Administrador"}
          click={handlerClick}
        />,
      ]}
      dataTitle={[
        "Usuario",
        "Correo Electrónico",
        "Nombre",
        "Rol",
        "Estado",
      ]}
      formatter={({ nameUser, email, name, lastname, type, rol, state }) => [
        nameUser,
        email,
        name + " " + lastname,
        rol.name,
        { type: "boolean", value: state, disabled: !validatePermission('administradores', 'administradores', ['editar']) },
      ]}
      onChecked={handlerCheck}
      actions={
        [
          validatePermission('administradores', 'administradores', ['editar']) &&
          {
            label: "Editar",
            icon: <EditIcon fontSize="medium" />,
            onClick: (data) => {
              handlerEdit(data);
            },
          },
          validatePermission('administradores', 'administradores', ['editar']) &&
          {
            label: "Restablecer Contraseña",
            icon: <Password fontSize="medium" />,
            onClick: (data) => {
              handlerResetPassword(data);
            },
          },
          validatePermission('administradores', 'administradores', ['eliminar']) &&
          {
            label: "Eliminar",
            icon: <DeleteForeverIcon fontSize="medium" />,
            onClick: (data) => {
              handlerDelete(data);
            },
          },

        ]

      }
    />
  );
};

export default AdminScreen;
