import service from "../service";

export const getLawfulOperatorByOperatorService = (operatorId) => (
  service('get', `v3/producto/operadora/${operatorId}/legal`)
)

export const createLawfulOperatorService = (operatorId,data) => (
  service('post', `v3/producto/operadora/${operatorId}/legal`, {
    contentType: "multipart/form-data",
    form: data
  })
)
export const updateLawfulOperatorService = (operatorId,operatorLegalId, data) => (
  service('patch', `v3/producto/operadora/${operatorId}/legal/${operatorLegalId}`, {
    contentType: "multipart/form-data",
    form: data
  })
)
export const removeLawfulOperatorService = (operatorId,operatorLegalId) => (
  service('delete', `v3/producto/operadora/${operatorId}/legal/${operatorLegalId}`)
)
