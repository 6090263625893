import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BagForm } from '../../../components';
import { errorAlert } from '../../../helpers/alerts';
import { hasSameValues } from '../../../helpers/util';
import { updateBag } from '../../../redux/slices/client/bagSlice';
import { updateBagService } from '../../../services/client/bagService';
import { setLoading } from '../../../redux/slices/uiSlice';

const EditBagScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentBag } = useSelector(store => store.bags);
  
  const values = {
    commerce: currentBag.hoster?.id,
    hashtag: currentBag.hashtag,
    earnings: currentBag.earnings,
    earnings_scheme: currentBag.earnings_scheme,
    min_amount: currentBag.min_amount,
  };

  const handlerBack = () => {
    navigate(-1);
  };

  const handlerSubmit = async (result) => {
    dispatch(setLoading(true))
    updateBagService(currentBag._id, result).then((response) => {
      dispatch(updateBag(response));
      navigate(-1);
    }).catch((e) => errorAlert(e))
    .finally(() => dispatch(setLoading(false)))
  };

  const validate = (data) => !hasSameValues(values, data)

  return (
    <BagForm
      title={"Editar Bolsa"}
      goBack={handlerBack}
      defaultValues={values}
      submit={handlerSubmit}
      validate={validate}
    />
  )
}

export default EditBagScreen