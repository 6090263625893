import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { batch, useDispatch, useSelector } from 'react-redux';
import { setTitle } from 'redux/slices/uiSlice';
import { AmountProviderForm } from 'components';
import { updateProviderCredsAmountsService } from 'services/admin/providerService';


function EditAmountScreen() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { providerCredSelected, amountSelected } = useSelector(store => store.providers)

    useEffect(() => {
        dispatch(setTitle('Nuevo Amount de Proveedor'));
        // eslint-disable-next-line
    }, [])

    return (
        <AmountProviderForm
            title={"Cancelar"}
            defaultValues={{
                id: amountSelected.id,
                operadora: amountSelected.operadora,
                segmento: amountSelected.segmento,
                product: amountSelected.product,
                sku: amountSelected.sku
            }}
            goBack={() => {
                navigate(-1);
            }}
            submit={(data) => {
                data['providerCred'] = providerCredSelected.id;
                try { delete data['id'] } catch(e) {}
                updateProviderCredsAmountsService(amountSelected.id, data).then((result) => {
                    console.log(result);
                    navigate(-1);
                }).catch((e) => {
                    console.log(e);
                    navigate(-1);
                });
            }}
        />
    );
}

export default EditAmountScreen;