import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentException: null,
  exceptions: [],
  operators: [],
  regions: [],
  segments: [],
  denominations: [],
  currentOperator: null,
  currentRegion: null,
  currentSegment: null

}

export const ExceptionSlice = createSlice({
  name: 'Exceptions',
  initialState,
  reducers: {
    setExceptions: (state, action) => ({
      ...state,
      exceptions: action.payload
    }),
    setOperators: (state, action) => ({
      ...state,
      operators: action.payload
    }),
    setRegions: (state, action) => ({
      ...state,
      regions: action.payload
    }),
    setSegments: (state, action) => ({
      ...state,
      segments: action.payload
    }),
    setDenominations: (state, action) => ({
      ...state,
      denominations: action.payload
    }),
    addException: (state, action) => ({
      ...state,
      exceptions: [
        ...state.exceptions,
        action.payload
      ],
    }),
    updateException: (state, action) => ({
      ...state,
      exceptions: state.exceptions.map((exception) => {
        if (exception.id === action.payload.id)
          return { ...exception, ...action.payload.data };
        return exception;
      }),
    }),
    removeException: (state, action) => ({
      ...state,
      exceptions: state.exceptions.filter((exception) => {
        return exception.id !== action.payload;
      }),
    }),
    setCurrentException: (state, action) => ({
      ...state,
      currentException: action.payload
    }),
    setCurrentOperator: (state, action) => ({
      ...state,
      currentOperator: action.payload,
    }),
    setCurrentRegion: (state, action) => ({
      ...state,
      currentRegion: action.payload,
    }),
    setCurrentSegment: (state, action) => ({
      ...state,
      currentSegment: action.payload,
    }),
  }

})
export const { setExceptions, 
  setOperators,
  setRegions,
  setSegments,
  setDenominations, addException, setCurrentException, updateException, removeException,
  setCurrentOperator,
  setCurrentRegion,
  setCurrentSegment } = ExceptionSlice.actions


export default ExceptionSlice.reducer