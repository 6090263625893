import service from "../service";

export const getSegmentsByOperatorService = (idOperator) => (
  service('get', `v3/producto/operadora/${idOperator}/segmento`)
)

export const createSegmentService = (idOperator, data) => (
  service('post', `v3/producto/operadora/${idOperator}/segmento`, {
    contentType: "multipart/form-data",
    form: data
  })
)
export const updateSegmentService = (idOperator, idSegment, data) => (
  service('patch', `v3/producto/operadora/${idOperator}/segmento/${idSegment}`, {
    contentType: "multipart/form-data",
    form: data
  })
)
export const removeSegmentService = (idOperator, idSegment) => (
  service('delete', `v3/producto/operadora/${idOperator}/segmento/${idSegment}`)

)