import { Button, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { validateEmptyFields } from '../../helpers/util'
import BackButton from '../BackButton'
import InputTextFieldGrid from '../common/InputTextFieldGrid'

const ProviderForm = ({ title, goBack, defaultValues, submit, validate }) => {
  const { control, handleSubmit } = useForm({ defaultValues });
  const watch = useWatch({ control });
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    setDisabled(validateEmptyFields(watch, { validate }));
    // eslint-disable-next-line
  }, [watch]);

  return (
    <>
      <BackButton name={title} click={goBack} />
      <Grid container direction="column" justifyContent="center" alignItems="center" >
        <Grid container direction="column" m={"40px"} maxWidth={"40%"} justifyContent="center" alignItems="center" spacing={3}>
          <InputTextFieldGrid
            name="name"
            label="Nombre"
            required="Debes agregar el nombre del proveedor"
            control={control}
          />
          <InputTextFieldGrid
            name="type"
            label="Tipo"
            required="Debes agregar el tipo de proveedor"
            control={control}
          />
          <InputTextFieldGrid
            name="host"
            label="Host"
            required="Debes agregar el host de proveedor"
            control={control}
          />
        </Grid>
        <Grid item justifyContent="center" alignItems="center" >
          <Button
            sx={{ m: 2, fontWeight: 'bold', backgroundColor: '#0E73ED' }}
            onClick={handleSubmit(submit)}
            disabled={disabled}
            variant="contained"
          >Guardar</Button>
        </Grid>
      </Grid>
    </>
  )
}

export default ProviderForm