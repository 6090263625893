import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentConnector: null,
  connectors: [],
  connectortypes: []
}

export const connectorSlice = createSlice({
  name: 'connectors',
  initialState,
  reducers: {
    setConnectors: (state, action) => ({
      ...state,
      connectors: action.payload
    }),
    setConnectorTypes: (state, action) => ({
      ...state,
      connectortypes: action.payload
    }),
    addConnector: (state, action) => ({
      ...state,
      connectors: [
        ...state.connectors,
        action.payload
      ]
    }),
    updateConnector: (state, action) => ({
      ...state,
      connectors: state.connectors.map((connector) => {
        if (connector.id === action.payload.id)
          return { ...connector, ...action.payload.data };          
        return connector;
      }),
    }),
    removeConnector: (state, action) => ({
      ...state,
      connectors: state.connectors.filter((connector) => {
        return connector.id !== action.payload;
      }),
    }),
    setcurrentConnector: (state, action) => ({
      ...state,
      currentConnector: action.payload
    })
  }

})
export const {
  setConnectors,
  addConnector,
  updateConnector,
  removeConnector,
  setcurrentConnector,
  setConnectorTypes
} = connectorSlice.actions


export default connectorSlice.reducer
