import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { batch, useDispatch, useSelector } from 'react-redux';
import { setTitle } from 'redux/slices/uiSlice';
import { ErrorCodesProviderForm } from 'components';
import { updateProviderCredsErrorCodesService } from 'services/admin/providerService';

function EditErrorCodesScreen() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { providerCredSelected, providerErrorCodeToCreate, errorCodeSelected } = useSelector(store => store.providers)

    useEffect(() => {
        dispatch(setTitle('Nuevo Error Code de Proveedor'));
        // eslint-disable-next-line
        console.log("errorCodeSelected", errorCodeSelected);
    }, [])

    return (
        <ErrorCodesProviderForm
            title={"Cancelar"}
            defaultValues={{
                hebeExceptionCode: errorCodeSelected.hebeExceptionCode,
                externalExceptionCode: errorCodeSelected.externalExceptionCode
            }}
            goBack={() => {
                navigate(-1);
            }}
            submit={(data) => {
                data['connectorName'] = providerCredSelected.conciliacion_service;
                try { delete data['id'] } catch(e) {}
                console.log(data);
                updateProviderCredsErrorCodesService(errorCodeSelected.id, data).then((result) => {
                    console.log(result);
                    navigate(-1);
                }).catch((e) => {
                    console.log(e);
                    navigate(-1);
                });
            }}
        />
    );
}

export default EditErrorCodesScreen;