import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { errorAlert } from '../../../../helpers/alerts';
import { hasSameValues } from '../../../../helpers/util';
import { updatePINProductService } from 'services/admin/productService';
import { updatePINProduct } from 'redux/slices/admin/productSlice';
import ProductForm from 'components/admin/ProductForm';
import { setLoading } from 'redux/slices/uiSlice';

const EditPINScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentPINSegment, currentPINProduct } = useSelector((store) => store.products);
  const values = {
    name: currentPINProduct.name,
    code: currentPINProduct.code,
    logo: currentPINProduct.fd,
    fixedAmount: currentPINProduct.fixedAmount,
    amounts: currentPINProduct.amounts ? currentPINProduct.amounts.split(',') : [],
  }
  const handlerSubmit = async (data) => {
    dispatch(setLoading(true))
    updatePINProductService(currentPINSegment.id, currentPINProduct.id, data)
      .then(response => {
        dispatch(updatePINProduct({ id: currentPINProduct.id, data: response }))
        navigate(-1);
      })
      .catch(error => errorAlert(error))
      .finally(() => dispatch(setLoading(false)))
  }

  const validate = (data) => !hasSameValues(values, data)

  return (
    <ProductForm
      title="Editar Pin Electrónico"
      type={"pin"}
      goBack={() => navigate(-1)}
      defaultValues={values}
      submit={handlerSubmit}
      validate={validate}
    />
  );
}

export default EditPINScreen