import axios from "axios"
import { URL_API } from "config/connection";
import { createAuthenticationCookie, getAuthentication, getOldAuthentication } from "helpers/authentication";

const service = (method, url, {
  data = {},
  form,
  timeout = 0,
  contentType = 'application/json',
  responseType = 'json'
} = {}) => {
  return new Promise(async (resolve, reject) => {
    const token = getAuthentication();
    const old_token = getOldAuthentication();
    createAuthenticationCookie({ token_user: old_token })
    const config = {
      method,
      withCredentials: true,
      url: `${URL_API}${url}`,
      responseType,
      headers: {
        'Content-Type': contentType,
        'Authorization': `Bearer ${token}`
      },
      timeout,
      data: form ? createFromData(form) : JSON.stringify(data)
    }
    axios(config).then((response) => {
      resolve(response?.data)
    }).catch((error) => {
      if (error?.response?.data?.message === 'Token expirado') {
        window.location.href = '/?session_expired';
      } else {
        reject(error?.response?.data)
      }
    })
  })
}

const createFromData = (data) => {
  let form = new FormData();
  Object.entries(data)
    .filter(([name, value]) => !(value instanceof File))
    .forEach(([name, value]) => {
      form.append(name, value);
    })
  Object.entries(data)
    .filter(([name, value]) => value instanceof File)
    .forEach(([name, value]) => {
      form.append(name, value);
    })
  return form;
}
export default service;