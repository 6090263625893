import { Button, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useForm, useWatch } from 'react-hook-form'
import { validateEmptyFields } from '../../helpers/util'
import BackButton from 'components/BackButton'
import InputTextFieldGrid from '../common/InputTextFieldGrid'
import InputSelectFieldGrid from 'components/common/InputSelectFieldGrid'
import { errorAlert } from 'helpers/alerts';
import { setCarrierList, setSegmentList } from 'redux/slices/admin/providerSlice';
import { getOperatorsService } from 'services/admin/operatorServices';
import { getSegmentsByOperatorService } from 'services/admin/segmentServices';
import { getDenominationBySegmentService } from 'services/admin/denominationServices';
import { setDenominations } from 'redux/slices/admin/denominationSlice';


const getById = function (array, id) {
	for (let i = 0; i < array.length; i++) {
		if (array[i].id === id) {
			return array[i];
		}
	}
	return null;
}

const AmountProviderForm = ({ title, goBack, defaultValues, submit, validate }) => {
	const { control, handleSubmit, setValue } = useForm({ defaultValues });
	const watch = useWatch({ control });
	const [selectedCarrier, setSelectedCarrier] = useState(defaultValues.operadora ? defaultValues.operadora : '');
	const [selectedSegment, setSelectedSegment] = useState(defaultValues.segmento ? defaultValues.segmento : '');

	const [disabled, setDisabled] = useState(true);
	const { carrierList, segmentList } = useSelector(store => store.providers)
	const { denominations } = useSelector(store => store.denominations)
	const dispatch = useDispatch();

	console.log("carrierList, segmentList", carrierList, segmentList);

	useEffect(() => {
		console.log("defaultValues", defaultValues);
		(async () => {
			try {
				console.log("getOperatorsService");
				const response = await getOperatorsService();
				console.log("getOperatorsService", response);
				dispatch(setCarrierList(response));
				if (!"id" in defaultValues) {
					dispatch(setDenominations([]));
				}
			} catch (error) {
				errorAlert(error);
			}
		})();
	}, []);

	useEffect(() => {
		if (selectedSegment != watch.operadora) {
			setSelectedSegment(watch.operadora);
			console.log("control", control, watch);
			(async () => {
				try {
					dispatch(setSegmentList([]));
					const response = await getSegmentsByOperatorService(watch.operadora);
					console.log("getSegmentsByOperatorService", response);
					dispatch(setSegmentList(response));
				} catch (error) {
					errorAlert(error);
				}
			})();
		}
	}, [watch.operadora]);


	useEffect(() => {
		if (selectedCarrier != watch.segmento) {
			setSelectedCarrier(watch.segmento);
			console.log("control", control, watch);
			(async () => {
				try {
					dispatch(setDenominations([]));
					const response = await getDenominationBySegmentService(watch.segmento);
					console.log("getDenominationBySegmentService", response);
					dispatch(setDenominations(response))
				} catch (error) {
					errorAlert(error);
				}
			})();
		}
	}, [watch.segmento]);

	useEffect(() => {
		setDisabled(validateEmptyFields(watch, { validate }));
		// eslint-disable-next-line
	}, [watch]);

	return (
		<>
			<BackButton name={title} click={goBack} />
			<Grid container direction="column" justifyContent="center" alignItems="center" >
				<Grid container direction="column" m={"40px"} maxWidth={"40%"} justifyContent="center" alignItems="center" spacing={3}>
					<InputSelectFieldGrid
						name="operadora"
						label="Carrier"
						required="Debes seleccionar el carrier"
						control={control}
						data={carrierList}
						formatter={(rows) => {
							return rows.map((row) => {
								return { id: row.id, name: row.name };
							});
						}}
					/>
					<InputSelectFieldGrid
						name="segmento"
						label="Segmento"
						required="Debes seleccionar el segmento"
						control={control}
						data={segmentList || []}
						formatter={(rows) => {
							return rows.map((row) => {
								return { id: row.id, name: row.name + " - " + row.carrierId };
							});
						}}
					/>
					<InputSelectFieldGrid
						name="product"
						label="Expose Id"
						required="Debes agregar el expose id"
						control={control}
						data={denominations}
						formatter={(rows) => {
							return rows.map((row) => {
								return { id: row.id, name: row.denomination };
							});
						}}
					/>
					<InputTextFieldGrid
						name="sku"
						label="SKU"
						required="Debes agregar el SKU"
						control={control}
					/>
				</Grid>
				<Grid item justifyContent="center" alignItems="center" >
					<Button
						sx={{ m: 2, fontWeight: 'bold', backgroundColor: '#0E73ED' }}
						onClick={handleSubmit((data) => {
							console.log("handleSubmit", data);
							data['name'] = getById(carrierList, data.operadora).name;
							data['carrier_id'] = getById(segmentList, data.segmento).carrierId;
							data['expose_id'] = getById(segmentList, data.segmento).code + getById(denominations, data.product).denomination;
							data['amount'] = getById(denominations, data.product).denomination;
							//console.log('handleSubmit', data);
							return submit(data);
						})}
						disabled={disabled}
						variant="contained"
					>Guardar</Button>
				</Grid>
			</Grid>
		</>
	)
}

export default AmountProviderForm