import React from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LadaForm from '../../../components/admin/LadaForm'
import { errorAlert } from '../../../helpers/alerts';
import { setLadas } from '../../../redux/slices/admin/ladasSlice';
import { createLadaService } from '../../../services/admin/ladasService';

const CreateLadaScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const values = {
    lada:"",
    region:""
  };

  const handlerBack =()=>{
    navigate("/ruteo/ladas/");
  };
  const handlerSubmit =async(result)=>{
    result.state =  true;
    try{
        const response = await createLadaService(result);
        dispatch(setLadas(response));
        navigate("/ruteo/ladas/");
    }catch(error){
      errorAlert(error);
    }
  };
  return (
    <LadaForm
    title="Crear Lada"
    goBack={handlerBack}
    defaultValues={values}
    submit={handlerSubmit}
    />
  )
}

export default CreateLadaScreen