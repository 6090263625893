import React from 'react'
import { Outlet, useNavigate } from 'react-router-dom';
import { CustomTab } from '../../../../components'

const TAETabScreen = () => {
  const navigate = useNavigate();

  return (
    <CustomTab
      panels={[
        {
          name: "General",
          route: '/ruteo/panel/tae',
          onChange: () => navigate('../tae'),
          content: <Outlet />
        },
        {
          name: "Excepciones",
          route: '/ruteo/panel/tae/excepciones',
          onChange: () => navigate('excepciones'),
          content: <Outlet />
        },
      ]}
    />
  )
}

export default TAETabScreen