import React, { useEffect } from 'react'
import { AddCircleOutline } from "@mui/icons-material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { CustomButton, TableData } from '../../../components';
import { confirmAlert, errorAlert } from '../../../helpers/alerts';
import { useDispatch, useSelector } from 'react-redux';
import { setLadas, updateLada, setCurrentLada, removeLada } from '../../../redux/slices/admin/ladasSlice';
import { getLadasService, removeLadaService, updateLadaService } from '../../../services/admin/ladasService';
import { formatDateForTable } from '../../../helpers/util';
import { useNavigate } from 'react-router-dom';
import EditIcon from "@mui/icons-material/Edit";
import { validatePermission } from '../../../helpers/authentication';

const LadasScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { ladas } = useSelector((store) => (store.ladas))

  useEffect(() => {
    (async () => {
      try {
        const result = await getLadasService();
        dispatch(setLadas(result))
      }
      catch (error) {
        errorAlert(error);
      }
    })();
    // eslint-disable-next-line
  }, []);


  const handlerCheck = async (row, currentValue) => {
    try {
      const value = !currentValue;
      await updateLadaService(row.id, { state: value });
      dispatch(updateLada({ id: row.id, data: { state: value } }));
    } catch (error) {
      errorAlert(error)
    }
  };

  const handlerDelete = ({ id, lada }) => {
    confirmAlert({
      title: "Estás seguro que deseas eliminar esta lada?",
      text: `Lada: ${lada} será eliminada.`,
      onConfirm: async () => {
        try {
          await removeLadaService(id);
          dispatch(removeLada(id));
        }
        catch (error) {
          errorAlert(error);
        }
      }
    })
  };

  const handlerClick = () => {
    navigate("crear");
  };

  const handlerEdit = (row) => {
    dispatch(setCurrentLada(row));
    navigate("editar");
  };

  return (
    <TableData
      dataRows={ladas}
      headerButtons={[
        //   <CustomButton
        //   icon={<SaveAltIcon />}
        //   key="dowloadLada"
        //   name={"Descargar"}
        //   //click={handlerClick}
        //  />,
        validatePermission('ruteo', 'ladas', ['agregar']) &&
        <CustomButton
          icon={<AddCircleOutline fontSize="large" />}
          key="addUser"
          name={"Crear Lada"}
          click={handlerClick}
        />
      ]}
      dataTitle={[
        "Lada",
        "Región",
        "Estado",
        "Fecha de Creación"
      ]}
      formatter={({ createdAt, lada, region, state }) => [
        lada,
        region.nombre,
        { name: "state", type: "boolean", value: state, disabled: !validatePermission('ruteo', 'ladas', ['editar']) },
        formatDateForTable(createdAt)
      ]}
      onChecked={handlerCheck}
      actions={[
        validatePermission('ruteo', 'ladas', ['editar']) &&
        {
          label: "Editar",
          icon: <EditIcon fontSize="medium" />,
          onClick: (data) => {
            handlerEdit(data);
          },
        },
        validatePermission('ruteo', 'ladas', ['eliminar']) &&
        {
          label: "Eliminar",
          icon: <DeleteForeverIcon fontSize="medium" />,
          onClick: (data) => {
            handlerDelete(data);
          },
        },
      ]}
    />
  )
}

export default LadasScreen