import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  currentAlert: null,
  currentMonitor: null,
  alerts: [],
  monitor: []
}

export const alertSlice = createSlice({
  name: 'group',
  initialState,
  reducers: {
    setAlerts: (state, action) => ({
      ...state,
      alerts: action.payload
    }),
    setMonitor: (state, action) => ({
      ...state,
      monitor: action.payload
    }),
    setCurrentMonitor: (state, action) => ({
      ...state,
      currentMonitor: action.payload
    }),
    addAlert: (state, action) => ({
      ...state,
      alerts: [
        ...state.alerts,
        action.payload
      ]
    }),
    updateAlert: (state, action) => ({
      ...state,
      alerts: state.alerts.map((group) => {
        if (group.id === action.payload.id)
          return { ...group, ...action.payload.data };
        return group;
      }),
    }),
    removeAlert: (state, action) => ({
      ...state,
      alerts: state.alerts.filter((group) => {
        return group.id !== action.payload;
      }),
    }),
    setCurrentAlert: (state, action) => ({
      ...state,
      currentAlert: action.payload
    })
  }

})
export const {
  setAlerts,
  setMonitor,
  setCurrentMonitor,
  addAlert,
  updateAlert,
  removeAlert,
  setCurrentAlert,
} = alertSlice.actions


export default alertSlice.reducer