import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { batch, useDispatch, useSelector } from 'react-redux';
import { setTitle } from 'redux/slices/uiSlice';
import HebeCodeProviderForm from 'components/admin/HebeCodeProviderForm';
import { putHebeCodesService } from 'services/admin/providerService';


function HebeCodesCreateScreen() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setTitle('Nuevo Código de Error'));
        // eslint-disable-next-line
    }, [])

    return (
        <HebeCodeProviderForm
            title={"Cancelar"}
            defaultValues={{
                name: "",
                description: "",
                code: ""
            }}
            goBack={() => {
                navigate(-1);
            }}
            submit={(data) => {
                console.log(data);
                putHebeCodesService(data).then((result) => {
                    console.log(result);
                    navigate(-1);
                }).catch((e) => {
                    console.log(e);
                    navigate(-1);
                });
            }}
        />
    );
}

export default HebeCodesCreateScreen;