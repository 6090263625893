import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from "moment";

import { AddCircleOutline, ErrorOutline, ChevronLeft, CompareArrows, Close, Edit } from '@mui/icons-material';
import { batch, useDispatch, useSelector } from 'react-redux';
import { setTitle } from 'redux/slices/uiSlice';
import { validatePermission } from 'helpers/authentication';
import BackButton from 'components/BackButton';
import { errorAlert, confirmAlert } from 'helpers/alerts';
import { CustomButton, FilterBar, TableData } from "components";

import { setErrorCodesProvider, setErrorCodesHistoryProvider, setHebeErrorCodesList, setProviderErrorCodeToCreate, setErrorCodesHistoryIdsProvider, setErrorCodeSelected } from 'redux/slices/admin/providerSlice';
import { getProviderCredsErrorCodesService, getProviderCredsErrorCodesHistoryService, getHebeCodesListService, deleteProviderCredsErrorCodesService } from 'services/admin/providerService';

function ErrorCodesScreen() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [update, setUpdate] = useState(0);
    const { errorCodesProvider, providerCredSelected, hebeErrorCodesHistoryList } = useSelector(store => store.providers)

    const errorCodesList = errorCodesProvider.map((e) => e.externalExceptionCode);

    useEffect(() => {
        dispatch(setTitle('Homologación Errores Proveedor'));
        // eslint-disable-next-line
    }, [])

    const historicalCodes = async (data = {}) => {
        const response_history = await getProviderCredsErrorCodesHistoryService(providerCredSelected.conciliacion_service, data);
        console.log("response_history", response_history, providerCredSelected.conciliacion_service);
        dispatch(setErrorCodesHistoryProvider(response_history));
    }

    useEffect(() => {
        (async () => {
          try {
            console.log("providerCredSelected", providerCredSelected.conciliacion_service);
            const response = await getProviderCredsErrorCodesService(providerCredSelected.conciliacion_service);
            dispatch(setErrorCodesProvider(response));

            await historicalCodes();

            const response_hebe_codes = await getHebeCodesListService();
            dispatch(setHebeErrorCodesList(response_hebe_codes));
          } catch (error) {
            errorAlert(error);
          }
        })();
    
        // eslint-disable-next-line
      }, [update]);

    
    const handlerDelete = ({ sku, id }) => {
        confirmAlert({
          title: "Estás seguro que deseas eliminar este error code?",
          text: `Error Code: ${sku} será eliminada.`,
          onConfirm: async () => {
            try {
              await deleteProviderCredsErrorCodesService(id);
              setUpdate(update+1);
            }
            catch (error) {
              errorAlert(error);
            }
          }
        })
    };

    const handlerEdit = (row) => {
        dispatch(setErrorCodeSelected(row));
        navigate("edit");
    };

    return (
        <>
            <h2 style={{marginBottom: -45}}>Códigos homologados</h2>
            <TableData
                dataRows={errorCodesProvider}
                headerButtons={[
                    <CustomButton
                        icon={<ChevronLeft fontSize="large"/>}
                        key="addProviderCred"
                        name={"Volver"}
                        click={() => {
                            navigate(-1);
                        }}
                    />,
                    validatePermission('proveedores', 'proveedores', ['agregar']) &&
                    <CustomButton
                        icon={<AddCircleOutline fontSize="large"/>}
                        key="addProviderCred"
                        name={"Nueva Homologaciónz"}
                        click={() => {
                            navigate("create");
                        }}
                    />,
                ]}
                dataTitle={[
                    "Proveedor",
                    "HEBE Code",
                    "Proveedor Code"
                ]}
                formatter={({ hebeExceptionCode, externalExceptionCode, connectorName }) => ([
                    connectorName,
                    hebeExceptionCode,
                    externalExceptionCode
                ])}
                actions={[
                    validatePermission('proveedores', 'productos', ['editar']) &&
                    {
                        label: "Editar",
                        icon: <Edit fontSize="medium" />,
                        onClick: (data) => {
                            handlerEdit(data);
                        },
                    },
                    validatePermission('proveedores', 'productos', ['editar']) &&
                    {
                        label: "Eliminar",
                        icon: <Close fontSize="medium" />,
                        onClick: (row) => {
                            handlerDelete(row);
                        },
                    },
                ]}
            />
            <h2 style={{marginBottom: 20}}>Códigos recibidos</h2>
            <FilterBar
                handlerFilter={async (data) => {
                    console.log(data);
                    await historicalCodes(data);
                }}
                defaultValues={{
                    startDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
                    endDate: moment().format('YYYY-MM-DD'),
                }}
                allValue={null}
                filters={[
                    {
                        name: "startDate",
                        label: "Fecha Inicio",
                        type: "date",
                    },
                    {
                        name: "endDate",
                        label: "Fecha Fin",
                        type: "date",
                    }
                ]}
            />
            <TableData
                dataRows={hebeErrorCodesHistoryList}
                headerButtons={[]}
                dataTitle={[
                    "Homologado",
                    "Error Code",
                    "Message",
                    "Count"
                ]}
                formatter={({ code, message, count }) => ([
                    (errorCodesList.indexOf(code) > -1 ? "SI" : "NO"),
                    code,
                    message,
                    count
                ])}
                actions={[
                    validatePermission('proveedores', 'productos', ['editar']) &&
                    {
                        label: "Homologar",
                        icon: <ErrorOutline fontSize="medium" />,
                        validate: (data) => {
                            return errorCodesList.indexOf(data.code) == -1
                        },
                        onClick: (data) => {
                            dispatch(setProviderErrorCodeToCreate(data.code));
                            navigate("create");
                        },
                    },
                    validatePermission('proveedores', 'productos', ['editar']) &&
                    {
                        label: "Transacciones",
                        icon: <CompareArrows fontSize="medium" />,
                        validate: (data) => {
                            return data.ids.length > 0
                        },
                        onClick: (data) => {
                            dispatch(setErrorCodesHistoryIdsProvider(data.ids));
                            navigate("/panel/transacciones");
                        },
                    },
                    
                ]}
            />
        </>
    );
}


export default ErrorCodesScreen;