import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { BackButton, InputSelectFieldGrid, InputTextFieldGrid } from "..";
import {
  Button,
  Grid
} from "@mui/material";
import { useForm, useWatch } from "react-hook-form";
import { getRolesService } from "../../services/security/roleService";
import { setRoles } from "../../redux/slices/security/roleSlice";
import { errorAlert } from '../../helpers/alerts';
import { validateEmptyFields } from '../../helpers/util';

const UserForm = ({ title, goBack, defaultValues, submit, data, validate }) => {
  const dispatch = useDispatch();
  const { roles } = useSelector((store) => store.roles);
  const [disabled, setDisabled] = useState(true);
  const { control, handleSubmit } = useForm({ defaultValues });
  const watch = useWatch({ control });

  useEffect(() => {
    (async () => {
      try {
        const roles = await getRolesService();
        console.log('roles', roles);
        dispatch(setRoles(roles));
      } catch (error) {
        errorAlert(error)
      }
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setDisabled(validateEmptyFields(watch, { validate }));
    // eslint-disable-next-line
  }, [watch]);

  return (
    <>
      <BackButton
        name={title}
        click={goBack}
      />
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          container
          direction="row"
          m={"40px"}
          maxWidth={"70%"}
          justifyContent="center"
          spacing={3}
        >
          <InputTextFieldGrid
            xs={8}
            name={"nameUser"}
            control={control}
            required={"Debes agregar el nombre de usuario"}
            label={"Nombre de usuario"}
          />
          <InputTextFieldGrid
            xs={8}
            name={"name"}
            control={control}
            required={"Debes agregar el nombre"}
            label={"Nombre(s)"}
          />
          <InputTextFieldGrid
            xs={8}
            name={"lastname"}
            control={control}
            required={"Debes agregar el apellidos"}
            label={"Apellidos"}
          />
          <InputTextFieldGrid
            xs={8}
            name={"email"}
            control={control}
            required={"Debes agregar un correo electrónico válido"}
            pattern={{
              value:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: "El correo electrónico no es valido",
            }}
            label={"Correo electrónico"}
          />
          <InputSelectFieldGrid
            xs={8}
            name={"rol"}
            control={control}
            required={"Debes seleccionar un rol"}
            label={"Asigna un Rol"}
            data={roles.filter(({ type }) => data.type === type)}
            style={{ margin: '0 5px' }}
          />
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            sx={{ m: 2, fontWeight: "bold", backgroundColor: "#0E73ED" }}
            onClick={handleSubmit(submit)}
            disabled={disabled}
            variant="contained"
          // fullWidth
          >
            Guardar
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default UserForm