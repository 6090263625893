import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentLawfulOperator: null,
  lawfulOperators: []
}

export const lawfulOperatorSlice = createSlice({
  name: 'lawfulOperators',
  initialState,
  reducers: {
    setLawfulOperators: (state, action) => ({
      ...state,
      lawfulOperators: action.payload
    }),
    addLawfulOperator: (state, action) => ({
      ...state,
      lawfulOperators: [
        ...state.lawfulOperators,
        action.payload
      ]
    }),
    updateLawfulOperator: (state, action) => ({
      ...state,
      lawfulOperators: state.lawfulOperators.map((lawfulOperator) => {
        if (lawfulOperator.id === action.payload.id)
          return { ...lawfulOperator, ...action.payload.data };
        return lawfulOperator;
      }),
    }),
    removeLawfulOperator: (state, action) => ({
      ...state,
      lawfulOperators: state.lawfulOperators.filter((lawfulOperator) => {
        return lawfulOperator.id !== action.payload;
      }),
    }),
    setCurrentLawfulOperator: (state, action) => ({
      ...state,
      currentLawfulOperator: action.payload
    })
  }

})
export const { setLawfulOperators, setCurrentLawfulOperator, updateLawfulOperator, removeLawfulOperator, addLawfulOperator } = lawfulOperatorSlice.actions


export default lawfulOperatorSlice.reducer