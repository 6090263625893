import { Alert, Grid, IconButton, InputAdornment, useMediaQuery } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { InputSelectFieldGrid, InputTextFieldGrid } from "../../components";
import FavoritesSales from "../../components/sale/FavoritesSales";
import LastSales from "../../components/sale/LastSales";
import { confirmAlert, errorAlert } from "../../helpers/alerts";
import { setDenominations, setSegments, setSelectedProduct, setUpdate } from "../../redux/slices/sale/airTimeSlice";
import xml from "../../helpers/xml";
import { currencyFormatter, hideEmail, randomNumber, validateEmptyFields } from "../../helpers/util";
import moment from "moment";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { getBagPDSProductsService, getBagPDSSegmentsService, getBagService } from "../../services/client/bagService";
import Swal from "sweetalert2";
import { setCurrentBag, setPDSProducts, setPDSSegments } from "redux/slices/client/bagSlice";
import { setSession } from "redux/slices/sessionSlice";
import { doStoreSale, sendTiketByEmail } from "services/sale/pdsService";
import { Help } from "@mui/icons-material";

const ServicePaymentSaleScreen = () => {
  const dispatch = useDispatch();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('xl'));
  const { balance_bag, accountBalance, sale: salepointId } = useSelector(store => store.session);
  const { showDetail, selectedProduct } = useSelector(store => store.airTimeSale);
  const { control, handleSubmit, setValue, watch, reset, setFocus } = useForm();
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const { segment, product, amount } = watch();
  const {
    currentBag,
    pdsSegments,
    pdsProducts,
  } = useSelector((store) => store.bags);

  useEffect(() => {
    getBagService(balance_bag).then(response => {
      dispatch(setCurrentBag(response))
    }).catch((e) => console.error(e))
    getBagPDSSegmentsService(balance_bag).then(list => {
      dispatch(setPDSSegments(list));
    }).catch(error => errorAlert(error))
    dispatch(setSelectedProduct(null));
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    let disable = validateEmptyFields(watch(), {});
    setDisabled(disable);
    // eslint-disable-next-line
  }, [watch()]);

  useEffect(() => {
    if (segment) {
      setValue('product', null)
      const selected = pdsSegments.find(({ idSegment }) => segment === idSegment.id);
      dispatch(setSelectedProduct({ image: selected.fd }));
      getBagPDSProductsService(balance_bag, segment)
        .then((response) => {
          dispatch(setPDSProducts(response))
          if (response.length === 1)
            setValue('product', response[0].idProduct.id)
        })
        .catch((e) => errorAlert(e))
    }
    // eslint-disable-next-line
  }, [segment]);

  useEffect(() => {
    if (product) {
      const selected = pdsProducts.find(({ idProduct }) => product === idProduct.id);
      dispatch(setSelectedProduct({
        image: selected.fd,
        instructions: selected.idProduct.instructions,
        imageReceipt: selected.idProduct.imageReceipt,
      }));
      setValue('commission', selected.commission || 0)
      if (amount)
        setValue('total', (amount * 1) + (selected.commission || 0))
    } else {
      setValue('commission', '')
      setValue('total', '')
    }
    // eslint-disable-next-line
  }, [product, amount]);


  const submit = async ({ segment, product, total, reference }) => {
    setLoading(true)
    try {
      const selected_segment = pdsSegments.find(({ idSegment }) => idSegment.id === segment);
      const selected_product = pdsProducts.find(({ idProduct }) => idProduct.id === product);
      const externalTrace = randomNumber(100000, 999999);
      console.log('selected_segment:', selected_segment);
      console.log('selected_product:', selected_product);

      const sale = {
        username: currentBag.username,
        password: currentBag.password,
        storeId: currentBag.storeId,
        provider: selected_segment.idSegment.carrierId,
        amount: total,
        reference,
        productDetail: selected_product.idProduct.code,
        externalTrace,
        salepointId
      }
      let xmlResponse = await doStoreSale(sale);
      xmlResponse = new xml(xmlResponse)
      const error = xmlResponse.getElementValue("phon:ProviderResponseCode");
      if (error !== '00') {
        console.log('result:', xmlResponse);
        const message = xmlResponse.getElementValue("phon:ProviderResponseMessage");
        errorAlert({ message: message || "Error" })
      } else {
        reset({ operator: '', segment: '', denomination: '' });
        dispatch(setSelectedProduct(null));
        dispatch(setSession({ accountBalance: accountBalance - amount }))
        confirmAlert({
          icon: "success",
          title: "Recarga Éxitosa",
          html: `<div id="ticket">
          <h3>Número de autorización ${xmlResponse.getElementValue("phon:AuthCode")}</h3>
          <div>Segmento: ${selected_segment.idSegment.name}</div>
          <div>Servicio: ${selected_product.idProduct.name}</div>
          <div>Monto: ${currencyFormatter.format(sale.amount)}</div>
          <div>Fecha: ${moment().format('DD/MM/YYYY HH:mm:ss')}</div>
          </div>`,
          showCancelButton: true,
          showDenyButton: true,
          cancelButtonText: "Cerrar",
          confirmButtonText: "Descargar comprobante",
          denyButtonText: "Enviar por correo electrónico",
          preConfirm: () => {
            return html2canvas(document.querySelector('.swal2-show')).then(
              canvas => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF();
                pdf.addImage(imgData, 'PNG', 25, 0);
                pdf.save('comprobante.pdf');
              }
            );
          },
          onDenied: () => {
            Swal.fire({
              title: 'Ingresa el correo electrónico',
              input: 'text',
              inputAttributes: {
                autocapitalize: 'off'
              },
              showCancelButton: true,
              confirmButtonText: 'Enviar',
              showLoaderOnConfirm: true,
              cancelButtonColor: "#ED3726",
              preConfirm: (email) => {
                return sendTiketByEmail(email, {
                  auth: xmlResponse.getElementValue("phon:AuthCode"),
                  segment: selected_segment.idSegment.name,
                  product: selected_product.idProduct.name,
                  amount: sale.amount,
                  date: moment().format('DD/MM/YYYY HH:mm:ss'),
                }).then(() => {
                  confirmAlert({
                    icon: "success",
                    title: "Enviado",
                    text: `Se ha enviado el ticket de la transacción al correo electrónico ${hideEmail(email)}`,
                    showCancelButton: false,
                  })
                }).catch((error) => {
                  Swal.showValidationMessage(error)
                })
              },
              allowOutsideClick: () => !Swal.isLoading()
            })
          }
        })
      }
    } catch (e) {
      console.error(e);
      if (e.code === 501)
        errorAlert({ message: e.message })
      else
        errorAlert({ message: 'Ha ocurrido un error al realizar la recarga, Vuelve a intentarlo y si el error persiste contacta a tu administrador.' })
    } finally {
      setLoading(false)
      dispatch(setUpdate(moment().unix()))
    }
  }

  const handlerSelectFavorite = (product) => {
    setValue('operator', null)
    setTimeout(() => {
      setTimeout(() => {
        setFocus('phone')
      }, 500);
      if (product._id === product)
        return
      dispatch(setSegments([]))
      dispatch(setDenominations([]))
      setValue('segment', product.segment._id)
      setValue('product', product._id)
    }, 100);
  }

  const handlerShowImageReceipt = () => {
    const { imageReceipt } = selectedProduct;
    Swal.fire({
      title: 'Recibo',
      imageUrl: `/v3/file/${imageReceipt}/`,
      imageHeight: 400,
      confirmButtonText: 'Cerrar',
    })
  }

  return (
    <>
      <Grid container justifyContent={"flex-end"}>
        {/* <Button
          size={isSmallScreen ? 'small' : 'large'}
          sx={{
            fontWeight: "bold",
            backgroundColor: showDetail ? '#2a2a2a' : '#319643'
          }}
          onClick={() => dispatch(setShowDetail(!showDetail))}
          variant="contained"
        >
          {showDetail ? 'Ocultar detalle' : 'Ver Detalle'}
        </Button> */}
      </Grid>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          container
          mt={1}
          width={showDetail ? "70%" : isSmallScreen ? "60%" : "40%"}
          spacing={3}
        >
          <InputSelectFieldGrid
            name={"segment"}
            xs={showDetail ? 6 : 12}
            control={control}
            required={"Debes seleccionar un segmento"}
            label={"Segmento"}
            data={pdsSegments}
            size={isSmallScreen ? 'small' : 'large'}
            formatter={(data) =>
              data?.map(({ idSegment }) => ({ id: idSegment.id, name: idSegment.name }))
            }
          />
          <InputSelectFieldGrid
            xs={showDetail ? 6 : 12}
            control={control}
            name={"product"}
            required={"Debes seleccionar un segmento"}
            label={"Servicio"}
            data={pdsProducts}
            size={isSmallScreen ? 'small' : 'large'}
            disabled={segment == null}
            formatter={(data) =>
              data?.map(({ idProduct }) => ({ id: idProduct.id, name: idProduct.name }))
            }
          />
          <InputTextFieldGrid
            xs={12}
            name={"reference"}
            control={control}
            label={"Referencia"}
            rules={{
              required: { value: true, message: "Debes agregar la referencia" },
            }}
            size={isSmallScreen ? 'small' : 'large'}
            InputProps={selectedProduct?.imageReceipt && {
              endAdornment:
                <InputAdornment position="end">
                  <IconButton
                    onClick={handlerShowImageReceipt}
                    size="large"
                  >
                    <Help />
                  </IconButton>
                </InputAdornment>
            }}
          />
          {
            selectedProduct?.instructions && (
              <Grid item xs={12}>
                <Alert severity="info">{selectedProduct.instructions}</Alert>
              </Grid>
            )
          }
          <InputTextFieldGrid
            xs={4}
            control={control}
            name={"amount"}
            label={"Monto"}
            rules={{
              required: { value: true, message: "Debes agregar el monto" },
            }}
            size={isSmallScreen ? 'small' : 'large'}
            inputProps={{ type: 'number' }}
            inputType={"number"}
            step={"any"}
          />
          <InputTextFieldGrid
            xs={4}
            name={"commission"}
            control={control}
            label={"Comisión"}
            disabled={true}
            size={isSmallScreen ? 'small' : 'large'}
          />
          <InputTextFieldGrid
            xs={4}
            name={"total"}
            control={control}
            label={"Total a pagar"}
            disabled={true}
            size={isSmallScreen ? 'small' : 'large'}
          />
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <LoadingButton
            loading={loading}
            size={isSmallScreen ? 'small' : 'large'}
            sx={{
              m: 2,
              paddingX: showDetail ? 5 : 10,
              fontWeight: "bold",
              backgroundColor: "#0E73ED",
              fontSize: showDetail ? 14 : 50
            }}
            onClick={handleSubmit(submit)}
            disabled={disabled}
            variant="contained"
          // fullWidth
          >
            Pagar
          </LoadingButton>
        </Grid>
      </Grid>
      {
        showDetail && (
          <>
            <FavoritesSales onSelect={handlerSelectFavorite} />
            <LastSales />
          </>
        )
      }
    </>
  );
};

export default ServicePaymentSaleScreen;
