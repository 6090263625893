import service from "../service";

export const getSimulatorParams = (provider) => (
  service('get', `v3/simulador/proveedor/${provider}`)
)

export const setSimulatorParams = (provider, data) => (
  service('post', `v3/simulador/proveedor/${provider}`, { data })
)

