import { Grid, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import icon from "../../assets/images";
import "./ImageInput.css";

const ImageInput = ({ nameInput, iconImage, imageSrc, onChange }) => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handlerChange = (e) => {
    const image = e.target.files[0];
    onChange(image);
    setSelectedImage(URL.createObjectURL(image));
  };

  useEffect(() => {
    imageSrc && setSelectedImage(imageSrc && `/v3/file/${imageSrc}/`);
    // eslint-disable-next-line
  }, []);

  return (
      <Grid
        container
        item
        xs={8}
        direction="column"
        justifyContent={"center"}
        style={{ width: "100%" }}
        className="container-image-icon"
      >
        <Grid  justifyContent={"space-around"} style={{display: "flex"}}>
            {selectedImage ? (
              <img
                alt=""
                className="image-icon"
                // src={URL.createObjectURL(selectedImage)}
                src={selectedImage}
              />
            ) : (
              <img alt="" className="image-icon" src={iconImage} />
            )}
        </Grid>
      <Grid justifyContent={"space-around"} className="container-complement" style={{display: "flex"}}>
        {nameInput}
      </Grid>
      <Grid justifyContent={"space-around"} style={{display: "flex"}}>
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="label"
          className="container-image-edit-icon"
        >
          <input type="file" name="myImage" onChange={handlerChange} />
          <img alt="" className="image-edit-icon" src={icon.icon.edit} />
        </IconButton>
      </Grid>
      </Grid>
  );
};

export default ImageInput;
