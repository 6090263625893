import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { AddCircleOutline, Category, Edit } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { setTitle } from 'redux/slices/uiSlice';
import { BackButton, CustomButton, TableData } from 'components';
import { validatePermission } from 'helpers/authentication';
import { setCurrentOperator, setCurrentSegment, setProviderCredSelected } from 'redux/slices/admin/providerSlice';
import { Grid } from '@mui/material';

function CredsScreen() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { providerSelected, providerCreds } = useSelector(store => store.providers)

    useEffect(() => {
        dispatch(setTitle('Credenciales Proveedores'));
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <div>
                    <BackButton click={() => navigate('..')} />
                </div>
                <div className="container-title">
                    <div className="commerce">Credenciales</div>
                    <div className="bag">{providerSelected?.container_name} </div>
                </div>
                <div>
                </div>
            </Grid>
            <TableData
                dataRows={providerCreds}
                headerButtons={[
                    validatePermission('proveedores', 'proveedores', ['agregar']) &&
                    <CustomButton
                        icon={<AddCircleOutline fontSize="large" />}
                        key="addProviderCred"
                        name={"Nueva credencial"}
                        click={() => {
                            navigate("create");
                        }}
                    />,
                ]}
                dataTitle={[
                    "Proveedor",
                    "Nombre",
                    "Client Id",
                    "Username",
                    "Conectada",
                    "Conciliación",
                ]}
                formatter={({ conciliacion_service, name_connector, client_id, user_name, active, conciliacion }) => ([
                    conciliacion_service,
                    name_connector,
                    client_id,
                    user_name,
                    active ? "SI" : "NO",
                    conciliacion ? "ACTIVA" : "INACTIVA"
                ])}
                actions={[
                    validatePermission('proveedores', 'productos', ['ver']) &&
                    {
                        label: "Productos",
                        icon: <Category fontSize="medium" />,
                        onClick: (data) => {
                            dispatch(setProviderCredSelected(data));
                            dispatch(setCurrentOperator(null));
                            dispatch(setCurrentSegment(null));
                            dispatch(setProviderCredSelected(data));
                            navigate(providerSelected.type === 'RECHARGE' ? "productos/tae" : "productos/pds");
                        },
                    },
                    // validatePermission('proveedores', 'productos', ['editar']) &&
                    // {
                    //     label: "Amounts",
                    //     icon: <Category fontSize="medium" />,
                    //     onClick: (data) => {
                    //         dispatch(setProviderCredSelected(data));
                    //         navigate("amounts");
                    //     },
                    // },
                    validatePermission('proveedores', 'productos', ['editar']) &&
                    {
                        label: "Editar",
                        icon: <Edit fontSize="medium" />,
                        onClick: (data) => {
                            dispatch(setProviderCredSelected(data));
                            navigate("edit");
                        },
                    },
                ]}
            />
        </>

    );
}

export default CredsScreen;