import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ProviderForm } from '../../../components';
import { errorAlert } from '../../../helpers/alerts';
import { hasSameValues } from '../../../helpers/util';
import { setProviders } from '../../../redux/slices/admin/providerSlice';
import { updateProviderService } from '../../../services/admin/providerService';

const EditProviderScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { current } = useSelector((store) => store.providers);
  const values = {
    name: current.container_name,
    type: current.type,
    host: current.host
  }

  const handlerBack = () => {
    navigate("/admin/proveedores");
  };

  const handlerSubmit = async (result) => {
    result = { ...result, container_name: result.name }
    try {
      const response = await updateProviderService(current.id, result);
      dispatch(setProviders(response))
      navigate("/admin/proveedores");
    } catch (error) {
      errorAlert(error)
    }
  };

  const validate = (data) => !hasSameValues(values, data)

  return (
    <ProviderForm
      title={"Editar Proveedor"}
      goBack={handlerBack}
      defaultValues={values}
      submit={handlerSubmit}
      validate={validate}
    />
  )
}

export default EditProviderScreen