import { Button, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { validateEmptyFields } from '../../helpers/util'
import BackButton from 'components/BackButton'
import InputTextFieldGrid from '../common/InputTextFieldGrid'



const HebeCodeProviderForm = ({ title, goBack, defaultValues, submit, validate }) => {
	const { control, handleSubmit, setValue } = useForm({ defaultValues });
	const watch = useWatch({ control });

	const [disabled, setDisabled] = useState(true);

	useEffect(() => {
		setDisabled(validateEmptyFields(watch, { validate }));
		// eslint-disable-next-line
	}, [watch]);

	return (
		<>
			<BackButton name={title} click={goBack} />
			<Grid container direction="column" justifyContent="center" alignItems="center" >
				<Grid container direction="column" m={"40px"} maxWidth={"40%"} justifyContent="center" alignItems="center" spacing={3}>
                    <InputTextFieldGrid
						name="name"
						label="Nombre"
						required="Escribe el nombre del código de error"
						control={control}
					/>
                    <InputTextFieldGrid
						name="description"
						label="Descripción"
						required="Debes agregar una descripción"
						control={control}
					/>
					<InputTextFieldGrid
						name="code"
						label="Código"
						required="Debes agregar un código para su homologación"
						control={control}
					/>
				</Grid>
				<Grid item justifyContent="center" alignItems="center" >
					<Button
						sx={{ m: 2, fontWeight: 'bold', backgroundColor: '#0E73ED' }}
						onClick={handleSubmit((data) => {
							return submit(data);
						})}
						disabled={disabled}
						variant="contained"
					>Guardar</Button>
				</Grid>
			</Grid>
		</>
	)
}

export default HebeCodeProviderForm