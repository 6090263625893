import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentSegment: null,
  segments: []
}

export const segmentSlice = createSlice({
  name: 'segments',
  initialState,
  reducers: {
    setSegments: (state, action) => ({
      ...state,
      segments: action.payload
    }),
    addSegment: (state, action) => ({
      ...state,
      segments: [
        ...state.segments,
        action.payload
      ],
    }),
    updateSegment: (state, action) => ({
      ...state,
      segments: state.segments.map((segment) => {
        if (segment.id === action.payload.id)
          return { ...segment, ...action.payload.data };
        return segment;
      }),
    }),
    removeSegment: (state, action) => ({
      ...state,
      segments: state.segments.filter((segment) => {
        return segment.id !== action.payload;
      }),
    }),
    setCurrentSegment: (state, action) => ({
      ...state,
      currentSegment: action.payload
    })
  }

})
export const { setSegments, addSegment, setCurrentSegment, updateSegment, removeSegment } = segmentSlice.actions


export default segmentSlice.reducer