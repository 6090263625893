import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { errorAlert } from '../../../../helpers/alerts';
import { hasSameValues } from '../../../../helpers/util';
import { updatePDSProductService, uploadPDSImageReceiptProductService } from 'services/admin/productService';
import { updatePDSProduct } from 'redux/slices/admin/productSlice';
import ProductForm from 'components/admin/ProductForm';
import { setLoading } from 'redux/slices/uiSlice';

const EditPaymentServiceScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentPDSSegment, currentPDSProduct } = useSelector((store) => store.products);
  const values = {
    name: currentPDSProduct.name,
    code: currentPDSProduct.code,
    logo: currentPDSProduct.fd,
    imageReceipt: currentPDSProduct.imageReceipt,
    instructions: currentPDSProduct.instructions,
    referenceLength: currentPDSProduct.referenceLength,
    finalClientPayComission: currentPDSProduct.finalClientPayComission,
    fixedComission: currentPDSProduct.fixedComission,
    discountAmount: currentPDSProduct.discountAmount,
  }
  const handlerSubmit = async (data) => {
    dispatch(setLoading(true))
    if (data.imageReceipt instanceof File) {
      const file = await uploadPDSImageReceiptProductService({ file: data.imageReceipt })
      data.imageReceipt = file.fd
    }
    updatePDSProductService(currentPDSSegment.id, currentPDSProduct.id, data)
      .then(response => {
        dispatch(updatePDSProduct({ id: currentPDSProduct.id, data: response }))
        navigate(-1);
      })
      .catch(error => errorAlert(error))
      .finally(() => dispatch(setLoading(false)))
  }

  const validate = (data) => !hasSameValues(values, data)

  return (
    <ProductForm
      title="Editar Pago de Servicio"
      type={"pds"}
      goBack={() => navigate(-1)}
      defaultValues={values}
      submit={handlerSubmit}
      validate={validate}
    />
  );
}

export default EditPaymentServiceScreen