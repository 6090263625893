import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { batch, useDispatch, useSelector } from 'react-redux';
import { setTitle } from 'redux/slices/uiSlice';
import HebeCodeProviderForm from 'components/admin/HebeCodeProviderForm';
import { updateHebeCodesService } from 'services/admin/providerService';


function HebeCodesEditScreen() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { hebeCodeSelected } = useSelector(store => store.providers)

    useEffect(() => {
        dispatch(setTitle('Editar Código de Error'));
        // eslint-disable-next-line
    }, [])

    return (
        <HebeCodeProviderForm
            title={"Cancelar"}
            defaultValues={{
                name: hebeCodeSelected.name,
                description: hebeCodeSelected.description,
                code: hebeCodeSelected.code
            }}
            goBack={() => {
                navigate(-1);
            }}
            submit={(data) => {
                updateHebeCodesService(hebeCodeSelected.id, data).then((result) => {
                    console.log(result);
                    navigate(-1);
                }).catch((e) => {
                    console.log(e);
                    navigate(-1);
                });
            }}
        />
    );
}

export default HebeCodesEditScreen;