import { Button, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import {  useForm, useWatch } from 'react-hook-form'
import BackButton from '../BackButton'
import { validateEmptyFields } from '../../helpers/util'
import InputTextFieldGrid from '../common/InputTextFieldGrid'
import InputImageFieldGrid from '../common/InputImageFieldGrid'

const SegmentForm = ({ title, goBack, defaultValues, submit, validate }) => {
  const { control, handleSubmit } = useForm({ defaultValues });
  const [disabled, setDisabled] = useState(false);
  const watch = useWatch({ control });

  useEffect(() => {
    setDisabled(validateEmptyFields(watch, { validate, skip_fields: ["code"] }));
    // eslint-disable-next-line
  }, [watch]);

  return (
    <>
      <BackButton name={title} click={goBack} />
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          container
          direction="row"
          m={"40px"}
          maxWidth={"70%"}
          justifyContent="center"
          spacing={3}
        >
          <InputTextFieldGrid 
              xs={6}
              name = {"name"}
              control = {control}
              required = {"Debes agregar el nombre de segmento"}
              label = {"Nombre"}
          />
          <InputTextFieldGrid
            xs={6}
            name={"carrierId"}
            control={control}
            required={"Debes agregar el identificador de la operadora"}
            label={"Identificador"}
            inputType={"number"}
            step={false}
          />
          <InputTextFieldGrid 
              xs={6}
              name = {"code"}
              control = {control}
              label = {"Código"}
          />
          <InputTextFieldGrid 
              xs={12}
              name = {"description"}
              control = {control}
              required = {"Debes agregar una descripción de segmento"}
              label = {"Descripción"}
          />
          <InputImageFieldGrid
              xs={12}
              name = {"file"}
              control = {control}
              required = {"Debes agregar el logo de segmento"}
              label = {"Logo"}
              style = {{display: "flex"}}
          /> 
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            sx={{ m: 2, fontWeight: "bold", backgroundColor: "#0E73ED" }}
            onClick={handleSubmit(submit)}
            disabled={disabled}
            variant="contained"
          >
            Guardar
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default SegmentForm