import { createSlice } from "@reduxjs/toolkit";

const initialState = {
 panel: [],
}

export const RouteSlice = createSlice({
  name: 'Routes',
  initialState,
  reducers: {
    setPanel: (state,action) =>({
        ...state,
        panel:action.payload
    })
  }

})
export const { setPanel } = RouteSlice.actions


export default RouteSlice.reducer