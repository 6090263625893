import service from "../service";

export const getSalePointsService = (data) => (
  service('post', `v3/puntoVenta/`, { data })
)

export const createSalePointService = (data) => (
  service('put', `v3/puntoVenta`, { data })
)

export const updateSalePointService = (salePointId, data) => (
  service('patch', `v3/puntoVenta/${salePointId}`, { data })
)

export const updateSalePointStateService = (salePointId, data) => (
  service('patch', `v3/puntoVenta/${salePointId}/estado`, { data })
)

export const removeSalePointService = (salePointId) => (
  service('delete', `v3/puntoVenta/${salePointId}`)
)

export const getSalePointTypesService = () => new Promise((resolve) => resolve([
  { id: 'Portal Web', name: 'Portal Web' },
  { id: 'Aplicación Móvil', name: 'Aplicación Móvil' },
  { id: 'Web Service', name: 'Web Service' },
]))