import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filters: {},
  currentSalePoint: null,
  salePoints: [],
  salePointTypes: [],
}

export const salePointSlice = createSlice({
  name: 'salePoint',
  initialState,
  reducers: {
    setSalePointFilters: (state, action) => ({
      ...state,
      filters: action.payload
    }),
    setSalePoints: (state, action) => ({
      ...state,
      salePoints: action.payload
    }),
    setSalePointTypes: (state, action) => ({
      ...state,
      salePointTypes: action.payload
    }),
    addSalePoint: (state, action) => ({
      ...state,
      salePoints: [
        ...state.salePoints,
        action.payload
      ]
    }),
    updateSalePoint: (state, action) => ({
      ...state,
      salePoints: state.salePoints.map((salePoint) => {
        if (salePoint.id === action.payload.id)
          return { ...salePoint, ...action.payload.data };
        return salePoint;
      }),
    }),
    removeSalePoint: (state, action) => ({
      ...state,
      salePoints: state.salePoints.filter((salePoint) => {
        return salePoint.id !== action.payload;
      }),
    }),
    setCurrentSalePoint: (state, action) => ({
      ...state,
      currentSalePoint: action.payload
    })
  }

})
export const {
  setSalePointFilters,
  setSalePoints,
  setSalePointTypes,
  addSalePoint,
  updateSalePoint,
  removeSalePoint,
  setCurrentSalePoint,
} = salePointSlice.actions


export default salePointSlice.reducer