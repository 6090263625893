import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { errorAlert } from "../../../../helpers/alerts";
import { createPDSProductService, uploadPDSImageReceiptProductService } from "services/admin/productService";
import { addPDSProduct } from "redux/slices/admin/productSlice";
import ProductForm from "components/admin/ProductForm";
import { setLoading } from "redux/slices/uiSlice";

const CreatePaymentServiceScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentPDSSegment } = useSelector((store) => store.products);

  const handlerSubmit = async (data) => {
    dispatch(setLoading(true))
    if (data.imageReceipt instanceof File) {
      const file = await uploadPDSImageReceiptProductService({ file: data.imageReceipt })
      data.imageReceipt = file.fd
    }
    createPDSProductService(currentPDSSegment.id, data)
      .then((response) => {
        dispatch(addPDSProduct(response));
        navigate(-1)
      })
      .catch(e => errorAlert(e))
      .finally(() => dispatch(setLoading(false)))
  };

  return (
    <ProductForm
      title="Crear Pago de Servicio"
      type={"pds"}
      goBack={() => navigate(-1)}
      submit={handlerSubmit}
    />
  );
};

export default CreatePaymentServiceScreen;
