import service from "../service";

export const getDenominationBySegmentService = (idSegment) => (
  service('get', `v3/producto/denominacion/${idSegment}`)
)

export const createDenominationService = (data) => (
  service('post', `v3/producto/denominacion`, {
    contentType: "multipart/form-data",
    form: data
  })
)
export const updateDenominationService = (idDenomination, data) => (
  service('patch', `v3/producto/denominacion/${idDenomination}`, {
    contentType: "multipart/form-data",
    form: data
  })
)
export const removeDenominationService = (idDenomination) => (
  service('delete', `v3/producto/denominacion/${idDenomination}`)
)
