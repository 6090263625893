import { Button, Grid } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import BackButton from "../BackButton";
import InputTextFieldGrid from "../common/InputTextFieldGrid";

const GroupForm = ({ title, goBack, defaultValues, submit }) => {
  const { control, handleSubmit } = useForm({ defaultValues });
  
  const handlerSubmit = (data) => {
    submit(data);
  }

  return (
    <>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" >
        <div>
          <BackButton name={title} click={goBack} />
        </div>
      </Grid>
      <Grid container justifyContent="center" alignItems="center" mt={"40px"}>
        <Grid width={"70%"} container spacing={3}>
          <InputTextFieldGrid
            label={"Nombre"}
            name={"name"}
            required={"Debes agregar el nombre"}
            // xs={6}
            control={control}
          />
          <Grid container justifyContent="center" mt={5}>
            <Button
              sx={{ m: 2, fontWeight: "bold", backgroundColor: "#0E73ED" }}
              onClick={handleSubmit(handlerSubmit)}
              variant="contained"
            >
              Guardar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default GroupForm;
