import { Grid } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux';
import './ProductDetailSale.css'

const ProductDetailSale = () => {
  const { selectedProduct } = useSelector(store => store.airTimeSale);
  
  return (
    <div className={`wrapper ${selectedProduct ? '' : 'notProduct'}`}>
      <Grid container direction={'column'} justifyContent={'center'} rowSpacing={2}>
        <Grid item >
          <div className='imgDetail' style={{ backgroundImage: `url(/v3/file/${selectedProduct?.image}/)` }}></div>
        </Grid>
        <Grid item className='priceDetail'>
          {
            selectedProduct?.denomination && <>${selectedProduct?.denomination}.00</>
          }
        </Grid>
        <Grid item className='titleDetail'>
          Información del Producto
        </Grid>
        <Grid item container direction={'row'} justifyContent={'space-evenly'}>
          <Grid item >
            <Grid item className='titleData'>
              DATOS
            </Grid>
            <Grid item className='data'>
              {selectedProduct?.gb} MB
            </Grid>
          </Grid>
          <Grid item>
            <Grid item className='titleData'>
              VIGENCIA
            </Grid>
            <Grid item className='data'>
              {selectedProduct?.vig} {selectedProduct?.vig === '1' ? 'día' : 'días'}
            </Grid>
          </Grid>
        </Grid>
        <Grid item container direction={'row'} justifyContent={'space-evenly'}>
          <Grid item >
            <Grid item className='titleData'>
              MINUTOS
            </Grid>
            <Grid item className='data'>
              {selectedProduct?.min}
            </Grid>
          </Grid>
          <Grid item >
            <Grid item className='titleData'>
              SMS
            </Grid>
            <Grid item className='data'>
              {selectedProduct?.sms}
            </Grid>
          </Grid>
        </Grid>
        {
          selectedProduct?.extraDenomination?.length !== 0 && (
            <Grid item container direction={'column'}>
              <Grid item className='titleData'>
                REDES SOCIALES
              </Grid>
              <Grid item container justifyContent={'center'} spacing={2}>
                {
                  selectedProduct?.extraDenomination?.map(({ fd, description }, key) => (
                    <Grid item key={key}>
                      <div
                        className='extraDenominationImage'
                        title={description}
                        style={{ backgroundImage: `url(/v3/file/${fd}/)` }}
                      />
                    </Grid>
                  ))
                }
              </Grid>
            </Grid>
          )
        }
      </Grid>
    </div>
  )
}

export default ProductDetailSale