import React from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ConnectorForm } from '../../../components';
import { errorAlert } from '../../../helpers/alerts';
import { setConnectors } from '../../../redux/slices/admin/connectorSlice';
import { createConnectorService } from '../../../services/admin/connectorService';

const CreateConnectorScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const values = {
    name: "",
    slow_response_time: "",
    optimal_response_time: "",
    critical_response_time: "",
    response_code: "",
    success_code: "",
    collection: "",
    type: "",
    description: undefined,
  }

  const handlerBack = () => {
    navigate("/admin/conectores");
  };

  const handlerSubmit = async (result) => {
    result.active_system = true;
    const new_result = {
      ...result,
      container_name: result.name,
      param_response_code: result.response_code,
      value_success_code: result.success_code,
      name_collection: result.collection,
      onType: result.type
    };
    try {
      const response = await createConnectorService(new_result);
      dispatch(setConnectors(response));
      navigate("/admin/conectores");
    } catch (error) {
      errorAlert(error);
    }
  }

  return (
    <ConnectorForm
      title={"Crear Conector"}
      goBack={handlerBack}
      defaultValues={values}
      submit={handlerSubmit}
    />
  )
}

export default CreateConnectorScreen
