import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  detailedReport: [],
  detailedReportFilters: null,
  balanceReport: [],
  binnacleReport: [],
  binnacleReportFilters: null,
  totalReport: [],
  // conciliaciones
  conciliacionesDiferenciasReport: [],
  conciliacionesReport: [],
  // conciliaciones history
  conciliacionesHistory: [],
  // logs
  logs: []
}

export const reportSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setDetailedReport: (state, action) => ({
      ...state,
      detailedReport: action.payload
    }),
    setDetailedReportFilters: (state, action) => ({
      ...state,
      detailedReportFilters: action.payload
    }),
    setBalanceReport: (state, action) => ({
      ...state,
      balanceReport: action.payload
    }),
    setBinnacleReport: (state, action) => ({
      ...state,
      binnacleReport: action.payload
    }),
    setBinnacleReportFilters: (state, action) => ({
      ...state,
      binnacleReportFilters: action.payload
    }),
    setTotalReport: (state, action) => ({
      ...state,
      totalReport: action.payload
    }),
    // Conciliaciones
    setConciliacionesDiferenciasReport: (state, action) => ({
      ...state,
      conciliacionesDiferenciasReport: action.payload
    }),
    setConciliacionesReport: (state, action) => ({
      ...state,
      conciliacionesReport: action.payload
    }),
    // conciliaciones history
    setConciliacionesHistory: (state, action) => ({
      ...state,
      conciliacionesHistory: action.payload
    }),
    // logs
    setLogsTxn: (state, action) => ({
      ...state,
      logs: action.payload
    }),
  }
  
})
export const {
  setDetailedReport,
  setDetailedReportFilters,
  setBalanceReport,
  setBinnacleReport,
  setBinnacleReportFilters,
  setTotalReport,
  setConciliacionesDiferenciasReport,
  setConciliacionesReport,
  setConciliacionesHistory,
  setLogsTxn
} = reportSlice.actions


export default reportSlice.reducer