import React, { useEffect, useState } from 'react';
import {
  Button,
  Grid
} from '@mui/material'
import { useForm, useWatch } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { InputSelectFieldGrid, InputTextFieldGrid, PermissionsCheck } from '..'
import { setPermissions, setRolesType } from '../../redux/slices/security/roleSlice'
import { getPermissionsService } from '../../services/security/permissionService'
import { getRolesTypeService } from '../../services/security/roleService'
import BackButton from '../BackButton'
import { validateEmptyFields } from '../../helpers/util';
const titles = [
  'Ver',
  'Agregar',
  'Editar',
  'Eliminar'
]

const RoleForm = ({ title, goBack, defaultValues, permissions: savedPermissions, onCheck, submit, validate }) => {
  const { hoster } = useSelector((store) => store.session);
  const { control, handleSubmit } = useForm({ defaultValues });
  const watch = useWatch({ control });
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(true);
  const { types, permissions } = useSelector((store) => store.roles);
  const [list, setList] = useState([])
  const { type: type_selected } = watch;

  useEffect(() => {
    (async () => {
      const types = await getRolesTypeService();
      const permissions = await getPermissionsService();
      dispatch(setRolesType(types))
      dispatch(setPermissions(permissions))
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let disabled = validateEmptyFields(watch, { validate });
    setDisabled(disabled);
    // eslint-disable-next-line
  }, [watch, savedPermissions]);

  useEffect(() => {
    const list = [];
    permissions.filter(({ state, type }) => state && (type_selected === 'admin' ? true : type_selected === type)).forEach((permission) => {
      const selected = savedPermissions.filter(({ idPermiso }) => permission.id === idPermiso)
      const item = {
        ...permission,
        actions: selected.map(({ action }) => action)
      }
      const category = list.find(({ name }) => name === permission.category);
      if (category) {
        category.permissions.push(item)
      } else {
        list.push({
          name: permission.category,
          permissions: [item]
        })
      }
    })
    setList(list)
    // eslint-disable-next-line
  }, [savedPermissions, permissions, type_selected]);

  const handlerCheck = (id, action, state) => {
    onCheck({ id, action, state })
  }

  return (
    <>
      <BackButton
        name={title}
        click={goBack}
      />
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          container
          m={'30px'}
          width={"60%"}
          spacing={3}
        >
          <InputTextFieldGrid
            xs={6}
            name={"name"}
            control={control}
            required={"Debes agregar el nombre del rol"}
            label={"Nombre del rol"}
          />
          <InputSelectFieldGrid
            xs={6}
            name={"type"}
            control={control}
            required={"Debes seleccionar un tipo de rol"}
            label={"Selecciona un tipo"}
            data={types.filter(({ id }) => hoster ? id === 'commerce' : true)}
          // style={{ margin: "0 5px" }}
          />
        </Grid>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid>
            <h4>Permisos</h4>
          </Grid>
          <PermissionsCheck
            titles={titles}
            permissions={list}
            onChecked={handlerCheck}
          />
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            sx={{ m: 2, fontWeight: 'bold', backgroundColor: '#0E73ED' }}
            disabled={disabled}
            onClick={handleSubmit(submit)}
            variant="contained"
          >Guardar</Button>
        </Grid>
      </Grid>
    </>
  )
}

export default RoleForm