import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './MenuBar.css'
import { ArrowDropDown, ArrowRight } from '@mui/icons-material';
import { Icon } from '@mui/material';
import { menuIcon } from 'config/theme';
import { useDispatch } from 'react-redux';
import { setTitle } from 'redux/slices/uiSlice';

const MenuBar = ({ name, icon, page, list, children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false)

  const handlerNavigate = (e) => {
    if (list)
      setOpen(!open)
    if (page){
      navigate(page)
      dispatch(setTitle(name));
    }
  }

  return (
    <>
      <div className='container-wrap-menu-bar'
        onClick={handlerNavigate}
      >
        <div className={`container-menu-bar ${open ? 'open' : ''}`}>
          {
            icon && (
              <Icon sx={{ color: menuIcon }}>{icon}</Icon>
            )
          }
          <div className='container-menu-bar-name'>
            <span className='menu-bar-name'>{name}</span>
          </div>
          <div className='container-menu-bar-icon-arrow'>
            {
              list && (open ? <ArrowDropDown className='menu-bar-icon-arrow' /> : <ArrowRight className='menu-bar-icon-arrow' />)
            }
          </div>
        </div>
      </div>
      {
        open && children
      }
    </>

  )
}

export default MenuBar;