import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentRouting: null,
  routes: []
}

export const routingSlice = createSlice({
  name: 'routes',
  initialState,
  reducers: {
    setRoutes: (state, action) => ({
      ...state,
      routes: action.payload
    }),
    addRouting: (state, action) => ({
      ...state,
      routes: [
        ...state.routes,
        action.payload
      ],
    }),
    updateRouting: (state, action) => ({
      ...state,
      routes: state.routes.map((routing) => {
        if (routing.id === action.payload.id)
          return { ...routing, ...action.payload.data };
        return routing;
      }),
    }),
    removeRouting: (state, action) => ({
      ...state,
      routes: state.routes.filter((routing) => {
        return routing.id !== action.payload;
      }),
    }),
    setCurrentRouting: (state, action) => ({
      ...state,
      currentRouting: action.payload
    })
  }

})
export const {
  setRoutes,
  addRouting,
  updateRouting,
  removeRouting,
  setCurrentRouting
} = routingSlice.actions


export default routingSlice.reducer