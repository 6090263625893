import { AddCircleOutline, Key, ErrorOutline } from '@mui/icons-material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CustomButton, TableData } from 'components';
import { getProveedoresService, getProviderCredsService } from 'services/admin/providerService';
import EditIcon from '@mui/icons-material/Edit';
import { setCurrentProvider, setProviders, setProviderCreds, setProviderSelected } from 'redux/slices/admin/providerSlice';
import { errorAlert } from 'helpers/alerts';
import { validatePermission } from 'helpers/authentication';
import { setProviderCredSelected } from 'redux/slices/admin/providerSlice';

const ProviderScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { providers } = useSelector(store => store.providers)

  useEffect(() => {
    (async () => {
      try {
        const response = await getProveedoresService();
        dispatch(setProviders(response));
      } catch (error) {
        errorAlert(error);
      }
    })();

    // eslint-disable-next-line
  }, []);

  const handlerCreate = () => {
    navigate("crear");
  };

  const handlerProviderCreds = (row) => {
    (async () => {
      try {
        const response = await getProviderCredsService(row.host);
        dispatch(setProviderCreds(response));
        dispatch(setProviderSelected(row));
      } catch (error) {
        errorAlert(error);
      }
    })();
    navigate("creds");
  }

  const handlerEdit = (row) => {
    dispatch(setCurrentProvider(row));
    navigate("editar")
  };

  return (
    <TableData
      dataRows={providers}
      headerButtons={[
        validatePermission('proveedores', 'proveedores', ['agregar']) &&
        <CustomButton
          icon={<AddCircleOutline fontSize="large" />}
          key="addProvider"
          name={"Proveedor"}
          click={handlerCreate}
        />,
      ]}
      dataTitle={[
        "Nombre",
        "Tipo",
        "Host",
      ]}
      formatter={({ container_name, type, host, state }) => ([
        container_name,
        type,
        host,
      ])}
      actions={[
        validatePermission('proveedores', 'proveedores', ['editar']) &&
        {
          label: "Editar",
          icon: <EditIcon fontSize="medium" />,
          onClick: (data) => {
            handlerEdit(data);
          },
        },
        validatePermission('proveedores', 'productos', ['editar']) &&
        {
          label: "Credenciales",
          icon: <Key fontSize="medium" />,
          onClick: (data) => {
            handlerProviderCreds(data);
          },
        },
        validatePermission('proveedores', 'productos', ['editar']) &&
        {
          label: "Error Codes",
          icon: <ErrorOutline fontSize="medium" />,
          onClick: (data) => {
            dispatch(setProviderCredSelected({ conciliacion_service: data.host }));
            navigate("creds/errorcodes");
          },
        },
        // validatePermission('proveedores', 'proveedores', ['eliminar']) &&
        // {
        //   label: "Eliminar",
        //   icon: <DeleteForeverIcon fontSize="medium" />,
        //   onClick: (data) => {
        //     handlerRemove(data);
        //   },
        // }
      ]}
    />
  )
}

export default ProviderScreen