import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Grid,
  TextField,
  IconButton,
  FilledInput,
  InputLabel,
  InputAdornment,
  FormControl,
  Button,
  FormHelperText,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import Images from '../assets/images';
import { loginService, recoverPassword } from '../services/security/userServices';
import { setSession } from '../redux/slices/sessionSlice';
import './LoginScreen.css'
import { confirmAlert, errorAlert } from '../helpers/alerts';
import NewPasswordModal from '../components/modal/NewPasswordModal';
import { setShowSuccesChangePassword, setUpdatePasswordToken } from '../redux/slices/uiSlice';
import theme from '../config/theme';

const LoginScreen = () => {
  const { showSuccesChangePassword, updatePasswordToken } = useSelector(store => store.ui);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const { control, handleSubmit } = useForm();
  const token = location.pathname.includes('/newpass') ? location.pathname.split('/')[2] : null;

  useEffect(() => {
    if (location.search === '?session_expired')
      errorAlert({ message: "Tu sesión ha expirado. Vuelve a iniciar.", onClose: () => navigate('/') })
    else if (token) {
      dispatch(setUpdatePasswordToken(token))
      navigate('/')
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (showSuccesChangePassword)
      confirmAlert({
        title: 'Contraseña actualizada exitosamente ',
        icon: "success",
        showCancelButton: false,
        onConfirm: () => {
          dispatch(setShowSuccesChangePassword(false))
        },
      })
    // eslint-disable-next-line
  }, [showSuccesChangePassword])

  const handlerClickShowPassword = () => setShowPassword((show) => !show);

  const handlerSubmit = async ({ username, password }) => {
    try {
      const response = await loginService({ username, password })
      if (response?.token) {
        dispatch(setSession(response))
        navigate('/')
      } else {
        // eslint-disable-next-line
        throw 'Error'
      }
    } catch (error) {
      errorAlert({ message: 'Credenciales de acceso incorrectas' })
    }
  }

  const handlerRecoverPassword = () => {
    confirmAlert({
      title: 'Recuperar contraseña',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off',
        placeholder: 'Usuario'
      },
      showCancelButton: true,
      confirmButtonText: 'Buscar',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: true,
      preConfirm: (email) => {
        return recoverPassword(email).then(respose => {
          console.log(respose);
        }).catch(({ message }) => {
          errorAlert({ message })
          return false
        })
      },
      onConfirm: () => confirmAlert({
        title: '¡Usuario encontrado!',
        icon: "success",
        text: "Se ha enviado un correo electrónico para restablecer la contraseña",
        showCancelButton: false
      })
    })
  }

  return (
    <>
      <div className='login_screen'>
        <Grid container direction="column" justifyContent="center" alignItems="center" height={'100%'} >
          <img alt='logo' className='logo' src={Images[theme].logo} />
          <div className='form' style={{ display: 'inline-grid' }} >
            <Controller
              name="username"
              control={control}
              defaultValue=""
              rules={{
                required: "Debes agregar el nombre de usuario",
                pattern: {
                  value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: 'El correo electrónico no es valido'
                }
              }}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <TextField
                  label="Usuario"
                  variant="filled"
                  sx={{ m: 1, backgroundColor: '#FFFFFF' }}
                  value={value}
                  onChange={(e) => onChange(e.target.value.trim().toLowerCase())}
                  error={!!error}
                  helperText={error && <span> {error.message}</span>}
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              defaultValue=""
              rules={{
                required: "Debes agregar tu contraseña",
              }}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <FormControl
                  sx={{ m: 1 }} variant="filled">
                  <InputLabel
                    htmlFor="filled-adornment-password">Password</InputLabel>
                  <FilledInput
                    type={showPassword ? 'text' : 'password'}
                    value={value}
                    onChange={onChange}
                    sx={{ backgroundColor: '#FFFFFF' }}
                    // error={!!error}
                    // helperText={error && <span> {error.message}</span>}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handlerClickShowPassword}
                          edge="end"
                        // sx={{ color: "#ffffff" }}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {
                    error && <FormHelperText sx={{ color: '#d32f2f' }}>Debes agregar tu contraseña</FormHelperText>
                  }
                </FormControl>
              )}
            />


          </div>
          <Button
            variant="contained"
            sx={{ m: 2, fontWeight: 'bold' }}
            onClick={handleSubmit(handlerSubmit)}
          >
            INGRESAR
          </Button>
          <p className="recover-password"
            onClick={handlerRecoverPassword}
          >Restablecer contraseña</p >
        </Grid>

      </div>
      <NewPasswordModal
        token={updatePasswordToken}
        open={updatePasswordToken != null}
      />
    </>

  )
}

export default LoginScreen;