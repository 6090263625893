import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { setLoading } from 'redux/slices/uiSlice';
import { errorAlert } from 'helpers/alerts';
import { hasSameValues } from 'helpers/util';
import CredProductForm from 'components/admin/CredProductForm';
import { setDenominations as setCredTaeProducts } from "redux/slices/admin/providerSlice";
import { updateCredTAEProductService } from 'services/admin/providerService';

const EditCredTAEProductScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    providerCredSelected: cred,
    currentOperator: operator,
    currentSegment: segment,
    currentTAEProduct: product,
  } = useSelector((store) => store.providers);
  const current = product.service;
  const values = {
    sku: current?.sku,
  }

  const handlerSubmit = async (data) => {
    dispatch(setLoading(true))
    updateCredTAEProductService(cred.id, operator.id, segment.id, product.id, data)
      .then(response => {
        dispatch(setCredTaeProducts(response));
        navigate(-1);
      }).catch(error => errorAlert(error))
      .finally(() => dispatch(setLoading(false)))
  }

  const validate = (data) => !hasSameValues(values, data)

  return (
    <CredProductForm
      title="Editar Denominación"
      subtitle={`${segment.name} - ${product?.imp}`}
      type={"pin"}
      goBack={() => navigate(-1)}
      defaultValues={values}
      submit={handlerSubmit}
      validate={validate}
    />
  );
}

export default EditCredTAEProductScreen