import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentProduct: null,
  products: [],
  currentSegment: null,
  segments: [],

  //PDS
  pdsSegments: [],
  pdsProducts: [],
  currentPDSSegment: null,
  currentPDSProduct: null,

  //PIN
  pinSegments: [],
  pinProducts: [],
  currentPINSegment: null,
  currentPINProduct: null,
}

export const productSlice = createSlice({
  name: 'productSlice',
  initialState,
  reducers: {

    //PDS
    setPDSSegments: (state, action) => ({
      ...state,
      pdsSegments: action.payload
    }),
    addPDSSegment: (state, action) => ({
      ...state,
      pdsSegments: [
        ...state.pdsSegments,
        action.payload
      ]
    }),
    updatePDSSegment: (state, action) => ({
      ...state,
      pdsSegments: state.pdsSegments.map((segment) => {
        if (segment.id === action.payload.id)
          return { ...segment, ...action.payload.data };
        return segment;
      }),
    }),
    removePDSSegment: (state, action) => ({
      ...state,
      pdsSegments: state.pdsSegments.filter((segment) => {
        return segment.id !== action.payload;
      }),
    }),
    setPDSProducts: (state, action) => ({
      ...state,
      pdsProducts: action.payload
    }),
    addPDSProduct: (state, action) => ({
      ...state,
      pdsProducts: [
        ...state.pdsProducts,
        action.payload
      ]
    }),
    updatePDSProduct: (state, action) => ({
      ...state,
      pdsProducts: state.pdsProducts.map((product) => {
        if (product.id === action.payload.id)
          return { ...product, ...action.payload.data };
        return product;
      }),
    }),
    removePDSProduct: (state, action) => ({
      ...state,
      pdsProducts: state.pdsProducts.filter((product) => {
        return product.id !== action.payload;
      }),
    }),
    setCurrentPDSSegment: (state, action) => ({
      ...state,
      currentPDSSegment: action.payload
    }),
    setCurrentPDSProduct: (state, action) => ({
      ...state,
      currentPDSProduct: action.payload
    }),
    //PIN
    setPINSegments: (state, action) => ({
      ...state,
      pinSegments: action.payload
    }),
    addPINSegment: (state, action) => ({
      ...state,
      pinSegments: [
        ...state.pinSegments,
        action.payload
      ]
    }),
    updatePINSegment: (state, action) => ({
      ...state,
      pinSegments: state.pinSegments.map((segment) => {
        if (segment.id === action.payload.id)
          return { ...segment, ...action.payload.data };
        return segment;
      }),
    }),
    removePINSegment: (state, action) => ({
      ...state,
      pinSegments: state.pinSegments.filter((segment) => {
        return segment.id !== action.payload;
      }),
    }),
    setPINProducts: (state, action) => ({
      ...state,
      pinProducts: action.payload
    }),
    addPINProduct: (state, action) => ({
      ...state,
      pinProducts: [
        ...state.pinProducts,
        action.payload
      ]
    }),
    updatePINProduct: (state, action) => ({
      ...state,
      pinProducts: state.pinProducts.map((product) => {
        if (product.id === action.payload.id)
          return { ...product, ...action.payload.data };
        return product;
      }),
    }),
    removePINProduct: (state, action) => ({
      ...state,
      pinProducts: state.pinProducts.filter((product) => {
        return product.id !== action.payload;
      }),
    }),
    setCurrentPINSegment: (state, action) => ({
      ...state,
      currentPINSegment: action.payload
    }),
    setCurrentPINProduct: (state, action) => ({
      ...state,
      currentPINProduct: action.payload
    }),

    //TAE
    setProducts: (state, action) => ({
      ...state,
      products: action.payload
    }),
    setCurrentProduct: (state, action) => ({
      ...state,
      currentProduct: action.payload
    }),
    addProduct: (state, action) => ({
      ...state,
      products: [
        ...state.products,
        action.payload
      ]
    }),
    updateProduct: (state, action) => ({
      ...state,
      products: state.products.map((product) => {
        if (product.id === action.payload.id)
          return { ...product, ...action.payload.data };
        return product;
      }),
    }),
    removeProduct: (state, action) => ({
      ...state,
      products: state.products.filter((product) => {
        return product.id !== action.payload;
      }),
    }),
    setSegments: (state, action) => ({
      ...state,
      segments: action.payload
    }),
    addSegment: (state, action) => ({
      ...state,
      segments: [
        ...state.segments,
        action.payload
      ]
    }),
    updateSegment: (state, action) => ({
      ...state,
      segments: state.segments.map((segment) => {
        if (segment.id === action.payload.id)
          return { ...segment, ...action.payload.data };
        return segment;
      }),
    }),
    removeSegment: (state, action) => ({
      ...state,
      segments: state.segments.filter((segment) => {
        return segment.id !== action.payload;
      }),
    }),
    setCurrentSegment: (state, action) => ({
      ...state,
      currentSegment: action.payload
    })
  }

})
export const {
  //PDS
  setPDSSegments,
  addPDSSegment,
  updatePDSSegment,
  removePDSSegment,
  setPDSProducts,
  addPDSProduct,
  updatePDSProduct,
  removePDSProduct,
  setCurrentPDSSegment,
  setCurrentPDSProduct,
  //PIN
  setPINSegments,
  addPINSegment,
  updatePINSegment,
  removePINSegment,
  setPINProducts,
  addPINProduct,
  updatePINProduct,
  removePINProduct,
  setCurrentPINSegment,
  setCurrentPINProduct,
  //TAE
  setCurrentSegment,
  setCurrentProduct,
  setSegments,
  setProducts,
  addSegment,
  addProduct,
  updateSegment,
  updateProduct,
  removeSegment,
  removeProduct
} = productSlice.actions


export default productSlice.reducer