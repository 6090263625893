import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentMonitor: null,
  monitor: []
}

export const alertSlice = createSlice({
  name: 'group',
  initialState,
  reducers: {
    setMonitor: (state, action) => ({
      ...state,
      monitor: action.payload
    }),
    setCurrentMonitor: (state, action) => ({
      ...state,
      currentMonitor: action.payload
    }),
  }

})
export const {
  setMonitor,
  setCurrentMonitor,
} = alertSlice.actions


export default alertSlice.reducer