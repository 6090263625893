import { Grid } from '@mui/material'
import moment from 'moment'
import React, { useEffect } from 'react'
import TableData from '../common/TableData'
import { getLastMovements } from '../../services/sale/servipagosService'
import { useDispatch, useSelector } from 'react-redux'
import { setLastMovements } from '../../redux/slices/sale/airTimeSlice'
import { errorAlert } from '../../helpers/alerts'

const LastSales = ({ list }) => {
  const dispatch = useDispatch();
  const { lastMovements, update } = useSelector(store => store.airTimeSale);

  useEffect(() => {
    getLastMovements()
      .then((response) => dispatch(setLastMovements(response)))
      .catch((e) => errorAlert(e))
    // eslint-disable-next-line
  }, [update])

  return (
    <Grid flex={1}>
      <h3>ÚLTIMOS MOVIMIENTOS</h3>
      <TableData
        search={false}
        // style={{ height: '20vh' }}
        dataRows={lastMovements.map((item) => ({
          ...item,
          style: !item.Result && { color: 'red' }
        }))}
        dataTitle={[
          "Fecha Hora",
          "Operadora",
          "Producto",
          "Monto",
          "Número",
          "Código",
          "Estado",
          "Tiempo",
        ]}
        formatter={({
          createdAt,
          operator,
          Amount,
          phoneNumber,
          productId,
          ProviderResponseCode,
          ProviderResponseMessage,
          time
        }) => ([
          moment(createdAt).format('DD/MM/YYYY H:mm:ss '),
          operator?.name,
          productId,
          `$${Amount || 0}.00`,
          phoneNumber,
          ProviderResponseCode,
          ProviderResponseMessage,
          `${time} ms`,
        ])}
      />
    </Grid>
  )
}

export default LastSales