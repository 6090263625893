import { Button, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { errorAlert } from '../../helpers/alerts'
import { validateEmptyFields } from '../../helpers/util'
import BackButton from '../BackButton'
import InputRadioFieldGrid from '../common/InputRadioFieldGrid'
import InputTextFieldGrid from '../common/InputTextFieldGrid'
import SteepsBox from '../common/SteepsBox'
import { getEarningsPaymentService, getEarningsSchemeService } from '../../services/client/bagService'
import { setEarningsPayment, setEarningsScheme } from '../../redux/slices/client/bagSlice'
import InputSelectFieldGrid from '../common/InputSelectFieldGrid'

const BagForm = ({ title, goBack, defaultValues, submit, validate }) => {
  const { control, handleSubmit } = useForm({ defaultValues });
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(true);
  const watch = useWatch({ control });
  const { earningsPayment, earningsScheme } = useSelector(store => store.bags);

  useEffect(() => {
    getEarningsPaymentService()
      .then((response) => dispatch(setEarningsPayment(response)))
      .catch((e) => errorAlert(e))
    getEarningsSchemeService()
      .then((response) => dispatch(setEarningsScheme(response)))
      .catch((e) => errorAlert(e))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let disable = validateEmptyFields(watch, { validate, skip_fields: ['min_amount'] });
    setDisabled(disable);
    // eslint-disable-next-line
  }, [watch]);
  const steps = [
    {
      label: "Ajuste de bolsa de saldo",
      content: [
        {
          type: "input",
          xs: 4,
          name: "hashtag",
          required: "Debes agregar la etiqueta del comercio",
          label: "Etiqueta de la Bolsa",
        },
        {
          type: "input",
          xs: 4,
          name: "min_amount",
          label: "Monto minimo",
        },
        validate === undefined && {
          type: "input",
          xs: 4,
          name: "email",
          required: "Debes agregar usuario de la bolsa",
          label: "Usuario",
        },
      ],
    },
    {
      label: "Ajustes comerciales",
      content: [
        { type: "grid", xs: 6, name: "Esquema ganancia / campo comisión: " },
        { type: "grid", xs: 6, name: "Abono Ganancia: " },
        {
          type: "radio",
          xs: 6,
          name: "earnings_scheme",
          required: "Debes seleccionar uun esquema de ganancia",
          data: earningsScheme,
        },

        {
          type: "radio",
          xs: 6,
          name: "earnings",
          required: "Debes seleccionar un esquema de ganancia",
          data: earningsPayment,
        },
      ],
    },
  ];

  return (
    <>
      <BackButton name={title} click={goBack} />
      <Grid container direction="row" justifyContent="center">
        <SteepsBox
          steps={steps}
          formatter={(content) =>
            content.map((item, i) =>
              item.type === "input" ? (
                <InputTextFieldGrid
                  key={i}
                  xs={item.xs}
                  name={item.name}
                  control={control}
                  required={item.required && !item.disabled}
                  label={item.label}
                  disabled={item.disabled}
                  inputType={item.inputType}
                />
              ) : item.type === "radio" ? (
                <InputRadioFieldGrid
                  key={i}
                  xs={item.xs}
                  name={item.name}
                  control={control}
                  required={item.required}
                  label={item.label}
                  data={item.data}
                />
              ) : item.type === "select" ? (
                <InputSelectFieldGrid
                  key={i}
                  xs={item.xs}
                  name={item.name}
                  control={control}
                  required={item.required}
                  label={item.label}
                  data={item.data}
                  formatter={(data) => data.map(({ id, name }) => ({ id, name }))}
                  disabled={item.disabled}
                />
              ) : (
                <Grid item xs={item.xs} key={i} textAlign={'center'}>
                  {item.name}
                </Grid>
              )
            )
          }
        />
        <Grid container justifyContent="center" mt={"40px"}>
          <Button
            sx={{ m: 2, fontWeight: "bold", backgroundColor: "#0E73ED" }}
            onClick={handleSubmit(submit)}
            disabled={disabled}
            variant="contained"
          >
            Guardar
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default BagForm