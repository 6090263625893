import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { CustomTab } from '../../components';

const PanelScreen = () => {
  const navigate = useNavigate();

  return (
    <CustomTab
      panels={[
        {
          name: "Transacciones",
          route: '/panel/transacciones',
          onChange: () => navigate('transacciones'),
          content: <Outlet />
        },
        {
          name: "Abonos",
          route: '/panel/abonos',
          onChange: () => navigate('abonos'),
          content: <Outlet />
        },
        {
          name: "Bitácora",
          route: '/panel/bitacora',
          onChange: () => navigate('bitacora'),
          content: <Outlet />
        }
      ].filter(i => i)}
    />
  )
}

export default PanelScreen;