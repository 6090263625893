import service from "../service";

export const getConnectorsService = () => (
  service('get', `v2/admin/conectores/nuevo`)
)

export const getConnectorTypesService = () => {
  return [
    {id: "TAE", name: "TAE", type: "TAE"},
    {id: "PDS", name: "PDS", type: "PDS"},
    {id: "PIN", name: "PIN", type: "PIN"},
    {id: "ALL", name: "Todos", type: "ALL"},

  ]
}

export const createConnectorService = (data) => (
  service('post', `v2/admin/conectores/nuevo`, {
    contentType: "multipart/form-data",
    form: { ...data }
  })
)

export const updateConnectorService = (idConnector, data) => (
  service('post', `v2/admin/conectores/editar/${idConnector}`, {
    contentType: "multipart/form-data",
    form: { ...data }
  })
)

export const removeConnectorService = (idConnector) => (
  service('post', `v2/admin/conectores/eliminar/${idConnector}`)
)
