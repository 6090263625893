import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { errorAlert } from "../../../../helpers/alerts";
import { createPINSegmentService } from "services/admin/productService";
import { addPINSegment } from "redux/slices/admin/productSlice";
import SegmentProductForm from "components/admin/SegmentProductForm";
import { setLoading } from "redux/slices/uiSlice";

const CreateSegmentPINScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handlerSubmit = async (result) => {
    dispatch(setLoading(true))
    createPINSegmentService(result).then((response) => {
      dispatch(addPINSegment(response));
      navigate(-1)
    }).catch(e => errorAlert(e))
      .finally(() => dispatch(setLoading(false)))
  };

  return (
    <SegmentProductForm
      title="Crear Segmento"
      goBack={() => navigate(-1)}
      submit={handlerSubmit}
    />
  );
};

export default CreateSegmentPINScreen;
