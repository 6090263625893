import XMLParser from 'react-xml-parser';

class xml {
  constructor(data) {
    this.body = new XMLParser().parseFromString(data)
    console.log(this.body);
  }

  getElementValue(element) {
    const result = this.body.getElementsByTagName(element);
    if (result.length === 0)
      return undefined
    if (result.length === 1)
      return result[0].value
    else
      return result.map(({ value }) => value)
  }
}

export default xml;