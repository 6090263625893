import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { setTitle } from 'redux/slices/uiSlice';
import { CredsProviderForm } from 'components';
import { updateProviderCredsService } from 'services/admin/providerService';

function CredsEditScreen() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { providerSelected, providerCredSelected } = useSelector(store => store.providers);

    useEffect(() => {
        dispatch(setTitle('Editar Credencial de Proveedor'));
        // eslint-di
    }, [])

    return (
        <CredsProviderForm
            title={"Cancelar"}
            defaultValues={{
                name_connector: providerCredSelected['name_connector'],
                client_id: providerCredSelected['client_id'],
                user_name: providerCredSelected['user_name'],
                password_digest: providerCredSelected['password_digest'],
                active: Number(providerCredSelected['active']),
                conciliacion: Number(providerCredSelected['conciliacion']),
                operadoras: providerCredSelected['operadoras'] || [],
                regiones: providerCredSelected['regiones'] || [],
            }}
            goBack={() => {
                navigate(-1);
            }}
            submit={(data) => {
                data['active'] = data['active'] == 1
                data['conciliacion'] = data['conciliacion'] == 1
                data['conciliacion_service'] = providerSelected.host;
                updateProviderCredsService(providerCredSelected['id'], data).then((result) => {
                    console.log(result);
                    navigate(-2);
                }).catch((e) => {
                    console.log(e);
                    navigate(-1);
                });
            }}
        />
    );
}

export default CredsEditScreen;