import { FormControl, Input, InputAdornment } from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import Image from "../../assets/images";
import './SearchBar.css';

const SearchBar = () => {
  const { control } = useForm();
  return (
    <div className='wrapper-search'>
      <Controller
        name="searchdata"
        control={control}
        defaultValue=""
        rules={{
          required: "Debes agregar un texto para buscar",
        }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <FormControl fullWidth sx={{ m: 0 }} variant="standard">
            <Input
              id="standard-adornment-amount"
              startAdornment={
                <InputAdornment sx={{ m: 1}} position="start">
                  <img src={Image.icon.search} position="start" alt="" />
                </InputAdornment>
              }
              placeholder=" Buscar "
            />
          </FormControl>
        )}
      />
    </div>
  );
};

export default SearchBar;
