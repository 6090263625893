import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RoleForm } from '../../../components';
import { getRolePermissionsService, updateRoleService } from '../../../services/security/roleService';
import { errorAlert } from '../../../helpers/alerts';

const EditRoleScreen = () => {
  const navigate = useNavigate();
  const { current } = useSelector((store) => store.roles);
  const [permissions, setPermissions] = useState([])
  const values = {
    name: current.name,
    type: current.type,
  };

  useEffect(() => {
    getRolePermissions();
    // eslint-disable-next-line
  }, [])

  const getRolePermissions = async () => {
    try {
      const response = await getRolePermissionsService(current.id)
      setPermissions(response)
    } catch (error) {
      errorAlert(error)
    }
  }

  const handlerBack = () => {
    navigate("/seguridad/roles");
  };

  const handlerSubmit = async (result) => {
    
    try {
      await updateRoleService(current.id, {
        ...result,
        permissions: permissions.map(({ idPermiso, action }) => ({ id: idPermiso, action }))
      })
      navigate('/seguridad/roles')
    } catch (error) {
      errorAlert(error)
    }
  };

  const handlerCheck = ({ id, action, state }) => {
    setPermissions(state ?
      permissions.filter((permission) => (`${permission.idPermiso}_${permission.action}` !== `${id}_${action}`))
      : [
        ...permissions,
        { idPermiso: id, action }
      ])
  }
  return (
    <RoleForm
      title={"Editar Rol"}
      goBack={handlerBack}
      defaultValues={values}
      submit={handlerSubmit}
      permissions={permissions}
      onCheck={handlerCheck}
    />
  )
}

export default EditRoleScreen