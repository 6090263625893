import { Checkbox, FormControlLabel, FormHelperText, Grid } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

const CheckboxFieldGrid = ({ xs, name, control, required, label, style, disabled = false }) => {
  return (
    <Grid
      item
      xs={xs}
      alignContent="center"
      justifyContent={"center"}
      style={{ ...style, width: "100%" }}
    >
      <Controller
        name={name}
        control={control}
        defaultValue=""
        rules={!disabled && { required }}
        render={({
          field: { value, onChange },
          fieldState: { error },
        }) => (
          <Grid marginLeft={2}>
            <FormControlLabel
              label={label}
              control={
                <Checkbox
                  checked={value}
                  onChange={(e) => {
                    onChange(e.target.checked)
                  }}
                />
              }
            />
            {
              error && <FormHelperText sx={{ color: '#d32f2f' }}>{error.message}</FormHelperText>
            }
          </Grid>
        )}
      />
    </Grid>
  );
};

export default CheckboxFieldGrid;
