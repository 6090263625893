import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LawfulOperatorForm } from "../../../../components";
import { errorAlert } from "../../../../helpers/alerts";
import { hasSameValues } from "../../../../helpers/util";
import { updateLawfulOperator } from "../../../../redux/slices/admin/lawfulOperatorSlice";
import { updateLawfulOperatorService } from "../../../../services/admin/lawfulOperatorService";

const EditLawfulOperatorScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentOperator } = useSelector((store) => store.operators);
  const { currentLawfulOperator: current } = useSelector((store) => store.lawfulOperators);
  const values = { name: current.name, description: current.description };
  const handlerBack = () => navigate(-1);

  const handlerSubmit = async (data) => {
    data.state = true;
    try {
      const response = await updateLawfulOperatorService(currentOperator.id, current.id, data);
      dispatch(updateLawfulOperator(response));
      navigate(-1);
    } catch (error) {
      errorAlert(error);
    }
  };
  const validate = (data) => !hasSameValues(values, data);
  return (
    <>
      <div className="container-title">
        <div className="title">Operadora: {currentOperator.name} </div>
      </div>
      <div style={{ width: "170px" }}></div>
      <LawfulOperatorForm
        title=" Editar Operadora Legal"
        goBack={handlerBack}
        defaultValues={values}
        submit={handlerSubmit}
        validate={validate}
      />
    </>
  );
};

export default EditLawfulOperatorScreen;
