import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BackButton, TableData } from "../../../../../components";
import { errorAlert } from "../../../../../helpers/alerts";
import { getPDSProductsService } from "services/admin/productService";
import { setPDSProducts } from "redux/slices/admin/productSlice";
import { setCredPaymentServiceProductService } from "services/admin/providerService";
import { setCurrentPaymentServiceProduct, setPaymentServiceProducts } from "redux/slices/admin/providerSlice";
import { Grid } from "@mui/material";
import { Edit } from "@mui/icons-material";

const CredPDSProductScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    providerCredSelected,
    currentPaymentServiceSegment,
    paymentServiceProducts,
  } = useSelector((store) => store.providers);
  const {
    pdsProducts
  } = useSelector((state) => state.products);


  useEffect(() => {
    getPDSProductsService(currentPaymentServiceSegment.id)
      .then((data) => dispatch(setPDSProducts(data)))
      .catch(error => errorAlert(error))
    // eslint-disable-next-line
  }, []);

  const handlerCheck = async (row, state) => {
    setCredPaymentServiceProductService(providerCredSelected.id, currentPaymentServiceSegment.id, row.id, !state).then((result) => {
      dispatch(setPaymentServiceProducts(result));
    }).catch(error => errorAlert(error))
  };

  const handlerEdit = (row) => {
    dispatch(setCurrentPaymentServiceProduct(row));
    navigate('editar');
  };

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <div>
          <BackButton click={() => navigate(-1)} />
        </div>
        <div className="container-title">
          <div className="commerce">Servicios</div>
          <div className="bag">{currentPaymentServiceSegment.name} </div>
        </div>
        <div>
        </div>
      </Grid>
      <TableData
        dataRows={pdsProducts.map((item) => {
          const service = paymentServiceProducts?.find(({ idProduct }) => idProduct.id === item.id);
          return {
            ...item,
            service
          };
        })}
        dataTitle={["Logo", "Nombre", "SKU", "Estado"]}
        formatter={({ fd, name, service }) => [
          { type: "img", value: `/v3/file/${fd}/` },
          name,
          service?.sku,
          { name: "state", type: "boolean", value: service?.state, disabled: !service?.sku },
        ]}
        onChecked={handlerCheck}
        actions={[
          {
            label: "Editar",
            icon: <Edit fontSize="medium" />,
            onClick: (data) => {
              handlerEdit(data);
            },
          },
        ]}
      />
    </>
  );
};

export default CredPDSProductScreen;
