import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form';
import { errorAlert } from '../../helpers/alerts';
import { validateEmptyFields } from '../../helpers/util';
import { resetPasswordByToken } from '../../services/security/userServices';
import InputPasswordFieldGrid from '../common/InputPasswordFieldGrid';
import { setShowSuccesChangePassword, setUpdatePasswordToken } from '../../redux/slices/uiSlice';
import { useDispatch } from 'react-redux';

const NewPasswordModal = ({ token, open }) => {
  const dispatch = useDispatch();
  const { control, handleSubmit, reset, setError } = useForm();
  const watch = useWatch({ control });
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    setDisabled(validateEmptyFields(watch, {}));
    // eslint-disable-next-line
  }, [watch]);

  const submit = (result) => {
    if (result.password !== result.passwordReplay) {
      setError('passwordReplay', { message: 'Las contraseñas no coinciden' })
      return;
    }
    resetPasswordByToken(token, result).then(() => {
      dispatch(setShowSuccesChangePassword(true))
    }).catch((e) => {
      errorAlert(e);
    }).finally(onClose)
  };

  const onClose = () => {
    reset();
    dispatch(setUpdatePasswordToken(null))
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
    >
      <>
        <DialogTitle sx={{ textAlign: 'center' }}>Ingresa tu nueva contraseña</DialogTitle>
        <DialogContent >
          <Grid container sx={{ paddingTop: '5px' }} >
            <InputPasswordFieldGrid
              name={"password"}
              control={control}
              required={"Debes ingresar una contraseña"}
              label={"Contraseña"}
            />
            <InputPasswordFieldGrid
              name={"passwordReplay"}
              control={control}
              required={"Debes ingresar la misma contraseña"}
              label={"Vuelve a ingresar tu contraseña"}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ m: 2, fontWeight: "bold", backgroundColor: "#0E73ED" }}
            onClick={handleSubmit(submit)}
            disabled={disabled}
            variant="contained"
          >
            Aceptar
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}

export default NewPasswordModal