import service from "services/service";

//PDS
export const getPDSSegmentsService = () => (
  service('get', `v3/producto/pds/segmento`)
)
export const createPDSSegmentService = (data) => (
  service('post', `v3/producto/pds/segmento`, { 
    contentType: 'multipart/form-data',
    form: data
   })
)
export const updatePDSSegmentService = (id, data) => (
  service('patch', `v3/producto/pds/segmento/${id}`, { 
    contentType: 'multipart/form-data',
    form: data
   })
)
export const removePDSSegmentService = (id) => (
  service('delete', `v3/producto/pds/segmento/${id}`)
)
export const getAllPDSProductsService = () => (
  service('get', `v3/producto/pds/productos`)
)
export const getPDSProductsService = (idSegment) => (
  service('get', `v3/producto/pds/segmento/${idSegment}/producto`)
)
export const createPDSProductService = (idSegment, data) => (
  service('post', `v3/producto/pds/segmento/${idSegment}/producto`, { 
    contentType: 'multipart/form-data',
    form: data
   })
)
export const updatePDSProductService = (idSegment, idProduct, data) => (
  service('patch', `v3/producto/pds/segmento/${idSegment}/producto/${idProduct}`, { 
    contentType: 'multipart/form-data',
    form: data
   })
)
export const removePDSProductService = (idSegment, idProduct) => (
  service('delete', `v3/producto/pds/segmento/${idSegment}/producto/${idProduct}`)
)
export const uploadPDSImageReceiptProductService = (data) => (
  service('post', `v3/file`, { 
    contentType: 'multipart/form-data',
    form: data
   })
)

//PIN
export const getPINSegmentsService = () => (
  service('get', `v3/producto/pin/segmento`)
)
export const createPINSegmentService = (data) => (
  service('post', `v3/producto/pin/segmento`, { 
    contentType: 'multipart/form-data',
    form: data
   })
)
export const updatePINSegmentService = (id, data) => (
  service('patch', `v3/producto/pin/segmento/${id}`, { 
    contentType: 'multipart/form-data',
    form: data
   })
)
export const removePINSegmentService = (id) => (
  service('delete', `v3/producto/pin/segmento/${id}`)
)
export const getAllPINProductsService = () => (
  service('get', `v3/producto/pin/productos`)
)
export const getPINProductsService = (idSegment) => (
  service('get', `v3/producto/pin/segmento/${idSegment}/producto`)
)
export const createPINProductService = (idSegment, data) => (
  service('post', `v3/producto/pin/segmento/${idSegment}/producto`, { 
    contentType: 'multipart/form-data',
    form: data
   })
)
export const updatePINProductService = (idSegment, idProduct, data) => (
  service('patch', `v3/producto/pin/segmento/${idSegment}/producto/${idProduct}`, { 
    contentType: 'multipart/form-data',
    form: data
   })
)
export const removePINProductService = (idSegment, idProduct) => (
  service('delete', `v3/producto/pin/segmento/${idSegment}/producto/${idProduct}`)
)


