import service from "services/service";

export const getProvidersService = () => (
  service('get', `v3/proveedor`)
)

export const getProveedoresService = () => (
  service('get', `v3/proveedor`)
)

export const getProvidersToRuteoService = () => (
  service('get', `v3/proveedor/ruteo`)
)

export const getProvidersToRuteoV2Service = () => (
  service('get', `v3/proveedor/ruteo2v`)
)

// Obtiene los Carriers del conector
export const getCarriersListService = () => (
  service('get', `v3/carriers`)
)

// Obtiene los hebe codes
export const getHebeCodesListService = () => (
  service('get', `v3/hebecodes`)
)

// Provider Cred Service
export const getProviderCredsService = (name) => (
  service('get', `v3/proveedor/${name}/providercreds`)
)

export const putProviderCredsService = (data) => (
  service('put', `v3/proveedor/providercreds`, {
    data: data
  })
)

export const updateProviderCredsService = (id, data) => (
  service('post', `v3/proveedor/providercreds/${id}`, {
    data: data
  })
)

export const deleteProviderCredsService = (id) => (
  service('delete', `v3/proveedor/providercreds/${id}`, {})
)

// Provides Amount Service

export const getProviderCredsAmountsService = (id) => (
  service('get', `v3/proveedor/providercreds/${id}/amounts`)
)

export const putProviderCredsAmountsService = (data) => (
  service('put', `v3/proveedor/providercreds/amounts`, {
    data: data
  })
)

export const deleteProviderCredsAmountsService = (id) => (
  service('delete', `v3/proveedor/providercreds/amounts/${id}`, {})
)

export const updateProviderCredsAmountsService = (id, data) => (
  service('patch', `v3/proveedor/providercreds/amounts/${id}`, { data })
)

// Provides Amount Service

export const getProviderCredsCarriersService = (id) => (
  service('get', `v3/proveedor/providercreds/${id}/carriers`)
)

// Provides Error Codes History Service

export const getProviderCredsErrorCodesHistoryService = (name, data) => (
  service('post', `v3/proveedor/errorcodes/${name}`, { data })
)

// Provides Error Codes Service

export const getProviderCredsErrorCodesService = (name) => (
  service('get', `v3/proveedor/providercreds/${name}/errorcodes`)
)

export const putProviderCredsErrorCodesService = (data) => (
  service('put', `v3/proveedor/providercreds/errorcodes`, {
    data: data
  })
)

export const updateProviderCredsErrorCodesService = (id, data) => (
  service('post', `v3/proveedor/providercreds/errorcodes/` + id, {
    data: data
  })
)

export const deleteProviderCredsErrorCodesService = (id) => (
  service('delete', `v3/proveedor/providercreds/errorcodes/${id}`, {})
)

// End Error Codes

export const createProviderService = (data) => (
  service('post', `v3/proveedor`, {
    contentType: "multipart/form-data",
    form: data
  })
)

export const updateProviderService = (idProvider, data) => (
  service('patch', `v3/proveedor/${idProvider}`, {
    contentType: "multipart/form-data",
    form: data
  })
)

//ProviderCredential
export const getProviderCredentialsService = (idProvider) => (
  service('get', `v3/proveedor/${idProvider}/credenciales`)
)

export const createProviderCredentialService = (idProvider, data) => (
  service('post', `v3/proveedor/${idProvider}/credencial`, { data })
)

export const updateProviderCredentialService = (idProvider, id, data) => (
  service('patch', `v3/proveedor/${idProvider}/credencial/${id}`, { data })
)

export const removeProviderCredentialService = (idProvider, id) => (
  service('delete', `v3/proveedor/${idProvider}/credencial/${id}`)
)

// Hebe Codes


export const getHebeCodesService = (name) => (
  service('get', `v3/proveedor/hebecodes`)
)

export const putHebeCodesService = (data) => (
  service('post', `v3/proveedor/hebecodes`, { data })
)

export const updateHebeCodesService = (id, data) => (
  service('patch', `v3/proveedor/hebecodes/${id}`, { data })
)

export const deleteHebeCodesService = (id) => (
  service('delete', `v3/proveedor/hebecodes/${id}`, {})
)

//Monitor
export const getTodayProviderTransactions = () => (
  service('get', `v3/proveedor/monitor`)
)

//TAE
export const getAllCredTAEProductsService = () => (
  service('get', `v3/proveedor/credencial/tae/productos`)
)
export const getCredTAEOperatorsService = (id) => (
  service('get', `v3/proveedor/credencial/${id}/tae/operadoras`)
)
export const createCredTAEOperatorService = (id, idOperator) => (
  service('post', `v3/proveedor/credencial/${id}/tae/operadora/${idOperator}`)
)
export const deleteCredTAEOperatorService = (id, idOperator) => (
  service('delete', `v3/proveedor/credencial/${id}/tae/operadora/${idOperator}`)
)

export const getCredTAESegmentsService = (id, idOperator) => (
  service('get', `v3/proveedor/credencial/${id}/tae/operadora/${idOperator}/segmentos`)
)
export const createCredTAESegmentService = (id, idOperator, idSegment) => (
  service('post', `v3/proveedor/credencial/${id}/tae/operadora/${idOperator}/segmento/${idSegment}`)
)
export const deleteCredTAESegmentService = (id, idOperator, idSegment) => (
  service('delete', `v3/proveedor/credencial/${id}/tae/operadora/${idOperator}/segmento/${idSegment}`)
)

export const getCredTAEProductsService = (id, idOperator, idSegment) => (
  service('get', `v3/proveedor/credencial/${id}/tae/operadora/${idOperator}/segmento/${idSegment}/productos`)
)
export const setCredTAEProductService = (id, idOperator, idSegment, idProduct, state) => (
  service('post', `v3/proveedor/credencial/${id}/tae/operadora/${idOperator}/segmento/${idSegment}/producto/${idProduct}`, { data: { state } })
)
export const updateCredTAEProductService = (id, idOperator, idSegment, idProduct, data) => (
  service('patch', `v3/proveedor/credencial/${id}/tae/operadora/${idOperator}/segmento/${idSegment}/producto/${idProduct}`, { data })
)

//PDS
export const getAllCredPDSProductsService = () => (
  service('get', `v3/proveedor/credencial/pds/productos`)
)
export const getCredPaymentServiceSegmentsService = (id) => (
  service('get', `v3/proveedor/credencial/${id}/pds/segmentos`)
)
export const createCredPaymentServiceSegmentsService = (id, idSegment) => (
  service('post', `v3/proveedor/credencial/${id}/pds/segmento/${idSegment}`)
)
export const deleteCredPaymentServiceSegmentsService = (id, idSegment) => (
  service('delete', `v3/proveedor/credencial/${id}/pds/segmento/${idSegment}`)
)

export const getCredPaymentServiceProductsService = (id, idSegment) => (
  service('get', `v3/proveedor/credencial/${id}/pds/segmento/${idSegment}/productos`)
)
export const setCredPaymentServiceProductService = (id, idSegment, idProduct, state) => (
  service('post', `v3/proveedor/credencial/${id}/pds/segmento/${idSegment}/producto/${idProduct}`, { data: { state } })
)
export const updateCredPaymentServiceProductService = (id, idSegment, idProduct, data) => (
  service('patch', `v3/proveedor/credencial/${id}/pds/segmento/${idSegment}/producto/${idProduct}`, { data })
)

//PIN
export const getAllCredPINProductsService = () => (
  service('get', `v3/proveedor/credencial/pin/productos`)
)
export const getCredPINSegmentsService = (id) => (
  service('get', `v3/proveedor/credencial/${id}/pin/segmentos`)
)
export const createCredPINSegmentsService = (id, idSegment) => (
  service('post', `v3/proveedor/credencial/${id}/pin/segmento/${idSegment}`)
)
export const deleteCredPINSegmentsService = (id, idSegment) => (
  service('delete', `v3/proveedor/credencial/${id}/pin/segmento/${idSegment}`)
)

export const getCredPINProductsService = (id, idSegment) => (
  service('get', `v3/proveedor/credencial/${id}/pin/segmento/${idSegment}/productos`)
)
export const setCredPINProductService = (id, idSegment, idProduct, state) => (
  service('post', `v3/proveedor/credencial/${id}/pin/segmento/${idSegment}/producto/${idProduct}`, { data: { state } })
)
export const updateCredPINProductService = (id, idSegment, idProduct, data) => (
  service('patch', `v3/proveedor/credencial/${id}/pin/segmento/${idSegment}/producto/${idProduct}`, { data })
)