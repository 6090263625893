import service from "../service";

export const getOperatorsService = () => (
  service('get', `v3/producto/operadora`)
)

export const createOperatorService = (data) => (
  service('post', `v3/producto/operadora`, {
    contentType: "multipart/form-data",
    form: data
  })
)
export const updateOperatorService = (idOperator, data) => (
  service('patch', `v3/producto/operadora/${idOperator}`, {
    contentType: "multipart/form-data",
    form: data
  })
)
export const removeOperatorService = (idOperator) => (
  service('delete', `v3/producto/operadora/${idOperator}`)
)