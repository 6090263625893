import { ListAlt } from '@mui/icons-material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TableData } from 'components';
import { errorAlert } from 'helpers/alerts';
import { setCurrentMonitor, setMonitor } from 'redux/slices/admin/serviceMonitorSlice';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { getServiceMonitorService } from 'services/admin/serviceMonitorService';

const ServiceMonitorScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { monitor } = useSelector(store => store.serviceMonitor);

  useEffect(() => {
    getServiceMonitorService().then((response) => {
      dispatch(setMonitor(response));
    }).catch(error => errorAlert(error))
    // eslint-disable-next-line
  }, []);

  const handlerHistorial = (item) => {
    dispatch(setCurrentMonitor(item));
    navigate('../historial');
  }

  return (
    <TableData
      dataRows={monitor}
      headerButtons={[]}
      dataTitle={[
        "Nombre",
        "Estatus",
        // "Mensaje",
        "Fecha y hora",
      ]}
      formatter={({ name, state, message, createdAt }) => [
        name,
        <div style={{
          height: 20,
          width: 20,
          borderRadius: 25,
          backgroundColor: state ? 'green' : 'red',
          margin: 'auto'
        }} />,
        // message,
        moment(createdAt).format("DD/MM/YYYY HH:mm:ss"),
      ]}
      actions={[
        {
          label: "Historial",
          icon: <ListAlt fontSize="medium" />,
          onClick: (data) => handlerHistorial(data)
        },
      ]}
    />
  )
}

export default ServiceMonitorScreen