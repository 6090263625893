import { FormHelperText, Grid } from '@mui/material'
import React from 'react'
import { Controller } from 'react-hook-form'
import ImageInput from './ImageInput'
import icon from "../../assets/images"

const InputImageFieldGrid = ({ xs, name, control, required, label, style }) => {
  return (
    <Grid
      container
      item
      xs={xs}
      direction="column"
      alignContent="center"
      justifyContent={"center"}
      style={{ ...style, width: '100%' }}
    >
      <Controller
        name={name}
        control={control}
        defaultValue=""
        rules={{ required }}
        render={({
          field: { value, onChange },
          fieldState: { error },
        }) => (
          <>
            <ImageInput
              nameInput={label}
              iconImage={icon.icon.add_image}
              imageSrc={value}
              onChange={(image) => {
                onChange(image)
              }}
            />
            {
              error && <FormHelperText sx={{ color: '#d32f2f' }}>{error.message}</FormHelperText>
            }
          </>
        )}
      />
    </Grid>
  )
}

export default InputImageFieldGrid