import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentDenomination: null,
  denominations: []
}

export const denominationSlice = createSlice({
  name: 'denominations',
  initialState,
  reducers: {
    setDenominations: (state, action) => ({
      ...state,
      denominations: action.payload
    }),
    addDenomination: (state, action) => ({
      ...state,
      denominations: [
        ...state.denominations,
        action.payload
      ],
    }),
    updateDenomination: (state, action) => ({
      ...state,
      denominations: state.denominations.map((denomination) => {
        if (denomination.id === action.payload.id)
          return { ...denomination, ...action.payload.data };
        return denomination;
      }),
    }),
    removeDenomination: (state, action) => ({
      ...state,
      denominations: state.denominations.filter((denomination) => {
        return denomination.id !== action.payload;
      }),
    }),
    setCurrentDenomination: (state, action) => ({
      ...state,
      currentDenomination: action.payload
    })
  }

})
export const { setDenominations, addDenomination, updateDenomination, removeDenomination, setCurrentDenomination } = denominationSlice.actions


export default denominationSlice.reducer