import React from 'react'
import { Outlet, useNavigate } from 'react-router-dom';
import { CustomTab } from '../../../components';

const ProductTabScreen = () => {
  const navigate = useNavigate();

  return (
    <div>
      <CustomTab
        panels={[
          {
            name: "Recargas Celular",
            route: '/admin/productos/tae',
            onChange: () => navigate('tae'),
            content: <Outlet />
          },
          {
            name: "Pago de Servicios",
            route: '/admin/productos/pds',
            onChange: () => navigate('pds'),
            content: <Outlet />
          },
          {
            name: "Pines Electrónicos",
            route: '/admin/productos/pin',
            onChange: () => navigate('pin'),
            content: <Outlet />
          }
        ].filter(Boolean)}
      />
    </div>
  );
}

export default ProductTabScreen