import { Grid, TextField } from '@mui/material'
import React from 'react'
import { Controller } from 'react-hook-form'

const InputTextFieldGrid = ({
  xs,
  sx,
  name,
  control,
  required,
  label,
  pattern,
  inputType,
  step,
  disabled = false,
  multiline,
  placeholder,
  size = "small",
  rules = {},
  inputProps,
  InputProps,
  onTextChange = () => { },
  defaultValue = ""
}) => {
  return (
    <Grid
      item
      xs={xs}
      alignContent="center"
      justifyContent={"center"}
      style={{ width: '100%' }}
    >
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={!disabled && {
          ...rules,
          required
        }}
        render={({
          field: { value, onChange },
          fieldState: { error },
        }) => (
          <TextField

            label={label}
            fullWidth
            required={!!required}
            pattern={pattern}
            size={size}
            value={value}
            onChange={(e) => {
              onChange(e);
              onTextChange(e.target.value.trim());
            }}
            onBlur={(e) => {
              if (value !== e.target.value.trim())
                onChange(e.target.value.trim());
            }}
            error={!!error}
            helperText={error && <span> {error.message}</span>}
            type={inputType}
            step={step}
            disabled={disabled}
            multiline={multiline}
            placeholder={placeholder}
            sx={sx}
            inputProps={inputProps}
            InputProps={InputProps}
            InputLabelProps={['date', 'time'].includes(inputType) ? {
              shrink: true,
            } : {}}
          />
        )}
      />
    </Grid>
  )
}

export default InputTextFieldGrid