import { loadState } from "./storeState";

export function getAuthentication() {
	const state = loadState()
	let token = '';
	if (state) {
		token = state?.session?.token
	}
	return token
}

export function getOldAuthentication() {
	const state = loadState()
	let token = '';
	if (state) {
		token = state?.session?.old_token
	}
	return token
}

export function createAuthenticationCookie(resp) {
	let day = new Date();
	day.setTime(day.getTime() + (1 * 12 * 60 * 30 * 1000));
	let expires = "expires=" + day.toUTCString();
	document.cookie = "token-admin=; expires=Thu, 01 Jan 1970 00:00:00 UTC";
	document.cookie = "token-user=; expires=Thu, 01 Jan 1970 00:00:00 UTC";
	if (resp?.token_admin) {
		document.cookie = `token-admin=${resp.token_admin};${expires};path=/`;
	} else if (resp?.token_user) {
		document.cookie = `token-user=${resp?.token_user};${expires};path=/`;
	}
}

export function isAuthorized() {
	return document.cookie.includes('token-user') || document.cookie.includes('token-admin')
}

export function isAdmin() {
	return document.cookie.includes('token-admin');
}

export function cleanAuthorizationToken() {
	document.cookie = "token-admin=; expires=Thu, 01 Jan 1970 00:00:00 UTC";
	document.cookie = "token-user=; expires=Thu, 01 Jan 1970 00:00:00 UTC";
}

export function validatePermission(vCategory, vPermission, vAction) {
	let validate = false;
	const state = loadState()

	state?.session?.permissions?.forEach(({ category, name, action }) => {
		if (vCategory === category.toLowerCase() && vPermission === name.toLowerCase()) {
			let include = vAction.includes(action.toLowerCase())
			if (include)
				validate = true;
		}
	})
	return validate;
}