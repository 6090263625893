import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { errorAlert } from "../../../../helpers/alerts";
import { createPINProductService } from "services/admin/productService";
import { addPINProduct } from "redux/slices/admin/productSlice";
import ProductForm from "components/admin/ProductForm";
import { setLoading } from "redux/slices/uiSlice";

const CreatePINScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentPINSegment } = useSelector((store) => store.products);

  const handlerSubmit = async (result) => {
    dispatch(setLoading(true))
    createPINProductService(currentPINSegment.id, result)
      .then((response) => {
        dispatch(addPINProduct(response));
        navigate(-1)
      })
      .catch(e => errorAlert(e))
      .finally(() => dispatch(setLoading(false)))
  };

  return (
    <ProductForm
      title="Crear Pin Electrónico"
      type={"pin"}
      goBack={() => navigate(-1)}
      submit={handlerSubmit}
    />
  );
};

export default CreatePINScreen;
