import { Box } from '@mui/system'
import React from 'react'


const PanelTab = ({ value, index, children }) => {
  return (
    <div
    className='tabpanel'
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
  >
    {value === index && (
      <Box sx={{ p: 3 }}>
        {children}
      </Box>
    )}
  </div>
  )
}

export default PanelTab