import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BagForm } from '../../../components'
import { confirmAlert, errorAlert } from '../../../helpers/alerts';
import { addBag } from '../../../redux/slices/client/bagSlice';
import { createBagService } from '../../../services/client/bagService';

const CreateBagScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentCommerce } = useSelector((store) => store.commerces);
  const values = {
    earnings: "",
    earnings_scheme: "",
    hashtag: "",
  };

  const handlerBack = () => {
    navigate("/cliente/comercios");
  };
  const handlerSubmit = async (data) => {
    createBagService({ ...data, commerce: currentCommerce._id }).then(({ bag, password }) => {
      dispatch(addBag(bag));
      confirmAlert({
        title: "¡La bolsa se ha creado exitosamente! ",
        icon: 'success',
        html: `
        <div>
          <h4>Credenciales de acceso</h4>
          <div><b>Usuario:</b> ${data.email}</div>
          <div><b>Contraseña:</b>  ${password}</div>
        </div>
        `,
        showCancelButton: false,
        onConfirm: () => {
          navigate(-1);
        }
      })
    }).catch((e) => errorAlert(e))
  };

  return (
    <BagForm
      title={"Comercios"}
      goBack={handlerBack}
      defaultValues={values}
      submit={handlerSubmit}
    />
  )
}

export default CreateBagScreen