import service from "../service";

export const getGroupsService = () => (
  service('post', `v3/grupo/`)
)

export const createGroupService = (data) => (
  service('put', `v3/grupo`, { data })
)

export const updateGroupService = (groupId, data) => (
  service('patch', `v3/grupo/${groupId}`, { data })
)

export const updateGroupStateService = (groupId, data) => (
  service('patch', `v3/grupo/${groupId}/estado`, { data })
)

export const removeGroupService = (groupId) => (
  service('delete', `v3/grupo/${groupId}`)
)

