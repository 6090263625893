import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showDetail: false,
  operators: [],
  segments: [],
  denominations: [],
  favorites: [],
  lastMovements: [],
  update: null,
  selectedProduct: null
}

export const AirTimeSlice = createSlice({
  name: 'Exceptions',
  initialState,
  reducers: {
    setShowDetail: (state, action) => ({
      ...state,
      showDetail: action.payload
    }),
    setOperators: (state, action) => ({
      ...state,
      operators: action.payload
    }),
    setSegments: (state, action) => ({
      ...state,
      segments: action.payload
    }),
    setDenominations: (state, action) => ({
      ...state,
      denominations: action.payload
    }),
    setFavorites: (state, action) => ({
      ...state,
      favorites: action.payload
    }),
    setLastMovements: (state, action) => ({
      ...state,
      lastMovements: action.payload
    }),
    setSelectedProduct: (state, action) => ({
      ...state,
      selectedProduct: action.payload
    }),
    setUpdate: (state, action) => ({
      ...state,
      update: action.payload
    })
  }

})
export const { 
  setShowDetail,
  setExceptions,
  setOperators,
  setSegments,
  setDenominations,
  setFavorites,
  setLastMovements,
  setSelectedProduct,
  setUpdate
} = AirTimeSlice.actions


export default AirTimeSlice.reducer