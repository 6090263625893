import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentExtraDenomination: null,
  extraDenominations: []
}

export const extraDenominationSlice = createSlice({
  name: 'extraDenominations',
  initialState,
  reducers: {
    setExtraDenominations: (state, action) => (
      {
      ...state,
      extraDenominations: action.payload
    }),
    addExtraDenomination: (state, action) => ({
      ...state,
      extraDenominations: [
        ...state.extraDenominations,
        action.payload
      ],
    }),
    updateExtraDenomination: (state, action) => ({
      ...state,
      extraDenominations: state.extraDenominations.map((extraDenomination) => {
        if (extraDenomination.id === action.payload.id)
          return { ...extraDenomination, ...action.payload.data };
        return extraDenomination;
      }),
    }),
    removeExtraDenomination: (state, action) => ({
      ...state,
      extraDenominations: state.extraDenominations.filter((extraDenomination) => {
        return extraDenomination.id !== action.payload;
      }),
    }),
    setCurrentExtraDenomination: (state, action) => ({
      ...state,
      currentExtraDenomination: action.payload
    })
  }

})
export const {
  setExtraDenominations,
  addExtraDenomination,
  updateExtraDenomination,
  removeExtraDenomination,
  setCurrentExtraDenomination
} = extraDenominationSlice.actions


export default extraDenominationSlice.reducer