import { Grid, Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './MenuHeader.css';
import { cleanSession, setSession } from '../../redux/slices/sessionSlice';
import { currencyFormatter } from '../../helpers/util';
import { getBagService } from '../../services/client/bagService';
import { ChevronLeft } from '@mui/icons-material';
import { showSidebar } from '../../redux/slices/uiSlice';
import Image from '../../assets/images';
import { useLocation, useNavigate } from 'react-router-dom';
import IconUser from './IconUser';
import theme from '../../config/theme';

const MenuHeader = ({ loading }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { hoster, nameUser, balance_bag, accountBalance, rol, email } = useSelector(store => store.session);
  const [hosterName, setHosterName] = useState('')
  useEffect(() => {
    if (location.search === '?session_expired')
      return;
    if (balance_bag)
      getBagService(balance_bag).then(({ saldo, hoster }) => {
        dispatch(setSession({ accountBalance: saldo }))
        setHosterName(hoster.username)
      }).catch((e) => console.error(e))
    
    // eslint-disable-next-line
  }, [balance_bag])

  const closeSession = () => {
    dispatch(cleanSession())
    navigate('/')
  }

  return (
    <div className='menu-header'>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className='menu-sidebar-button' onClick={() => dispatch(showSidebar(false))}>
          <ChevronLeft className='sidebar-button-icon' />
        </div>
        <div className='container-header-logo'>
          <img alt='logo' className='header-logo' src={Image[theme].logo} />
        </div>
      </div>
      {
        loading ? (
          <Grid container direction={'row'} columnSpacing={2} alignItems={'center'}>
            <Grid item >
              <Skeleton variant="rectangular" width={210} height={25} style={{ marginBottom: 4 }} />
              <Skeleton variant="rectangular" width={210} height={15} style={{ marginBottom: 4 }} />
              <Skeleton variant="rectangular" width={210} height={15} style={{ marginBottom: 4 }} />
              <Skeleton variant="rectangular" width={210} height={25} />
            </Grid>
          </Grid>
        ) : (
          <div className='menu-header-wrapper'>
            <div>
              <IconUser name={hoster ? hosterName : nameUser} />
            </div>
            <div className='menu-header-info'>
              <span className='menu-header-info-name'>{hoster ? hosterName : nameUser}</span>
              <span className='menu-header-info-name'>{email}</span>
              <span className='menu-header-info-user'>{rol?.name}</span>
              {
                (accountBalance === 0 || accountBalance) && (
                  <span className='menu-header-account-balance'>Saldo: {currencyFormatter.format(accountBalance)}</span>
                )
              }
              <span className='menu-header-info-close-session' onClick={closeSession}>Cerrar sesión</span>
            </div>
          </div>
        )
      }
    </div>
  )
}

export default MenuHeader;