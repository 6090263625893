import { Edit } from "@mui/icons-material";
import { CardBox } from "components";
import { errorAlert } from "helpers/alerts";
import React, { useEffect } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCurrentPINProduct, setCurrentPINSegment, setPINProducts, setPINSegments } from "redux/slices/admin/productSlice";
import { getPINProductsService, getPINSegmentsService } from "services/admin/productService";

const PINScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    pinSegments,
    pinProducts,
    currentPINSegment,
    currentPINProduct,
  } = useSelector((store) => store.products);

  useEffect(() => {
    getPINSegmentsService().then(list => {
      dispatch(setPINSegments(list));
    }).catch(error => errorAlert(error))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (currentPINSegment)
      getPINProductsService(currentPINSegment.id).then(list => {
        dispatch(setPINProducts(list));
      }).catch(error => errorAlert(error))
    // eslint-disable-next-line
  }, [currentPINSegment]);


  const handlerClickSegment = (segment) => {
    if (segment.id !== currentPINSegment?.id) {
      batch(() => {
        dispatch(setCurrentPINSegment(segment));
        dispatch(setCurrentPINProduct());
      })
    }
  };

  const handlerClickProduct = (product) => {
    if (product.id !== currentPINProduct?.id) {
      dispatch(setCurrentPINProduct(product));
    }
  };

  return (
    <div>
      <CardBox
        name={"Segmentos"}
        values={pinSegments}
        handleSelect={handlerClickSegment}
        isSelect={currentPINSegment?.id}
        buttons={[
          {
            icon: <Edit sx={{ ml: 1 }} />,
            key: "segments",
            name: "Administrar",
            click: () => navigate("segmentos"),
          },
        ]}
        formatter={item => ({
          ...item,
          image: `/v3/file/${item.fd}/`,
        })}
      />
      {
        currentPINSegment && (
          <CardBox
            name={"Servicios"}
            values={pinProducts?.filter(({ state }) => state)}
            handleSelect={handlerClickProduct}
            isSelect={currentPINProduct?.id}
            buttons={[
              {
                icon: <Edit sx={{ ml: 1 }} />,
                key: "products",
                name: "Administrar",
                click: () => navigate("productos"),
              },
            ]}
            formatter={item => ({
              ...item,
              image: `/v3/file/${item.fd}/`,
            })}

          />
        )
      }
    </div >
  );
};

export default PINScreen;
