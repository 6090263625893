const version = 1;
export const loadState = () => {
  try {
    const currentVersion = localStorage.getItem('version')
    if(currentVersion != version)
      return undefined;
    const serializedData = localStorage.getItem('state')
    if (serializedData === null) {
      return undefined
    }
    const state = JSON.parse(serializedData);
    return state
  } catch (error) {
    return undefined
  }
}
export const saveState = (state) => {
  try {
    let serializedData = JSON.stringify(state)
    localStorage.setItem('version', version)
    localStorage.setItem('state', serializedData)
  } catch (error) {

  }
}
