import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentLada: null,
  ladas: []
}

export const ladasSlice = createSlice({
  name: 'ladas',
  initialState,
  reducers: {
    setLadas: (state, action) => ({
      ...state,
      ladas: action.payload
    }),
    addLada: (state, action) => ({
      ...state,
      ladas: [
        ...state.ladas,
        action.payload
      ],
    }),
    updateLada: (state, action) => ({
      ...state,
      ladas: state.ladas.map((lada) => {
        if (lada.id === action.payload.id)
          return { ...lada, ...action.payload.data };
        return lada;
      }),
    }),
    removeLada: (state, action) => ({
      ...state,
      ladas: state.ladas.filter((lada) => {
        return lada.id !== action.payload;
      }),
    }),
    setCurrentLada: (state, action) => ({
      ...state,
      currentLada: action.payload
    })
  }

})
export const {
  setLadas,
  addLada,
  updateLada,
  removeLada,
  setCurrentLada
} = ladasSlice.actions

export default ladasSlice.reducer