import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import OperatorForm from "../../../../components/admin/OperatorForm";
import { errorAlert } from '../../../../helpers/alerts';
import { hasSameValues } from '../../../../helpers/util';
import { updateOperator } from '../../../../redux/slices/admin/operatorSlice';
import { updateOperatorService } from '../../../../services/admin/operatorServices';

const ProductEditOperatorScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentOperator } = useSelector((store) => store.operators);
  const values = {
    name: currentOperator.name,
    carrierId: currentOperator.carrierId,
    file: currentOperator.fd
  }

  const handlerBack = () => navigate(-1);

  const handlerSubmit = async (data) => {
    updateOperatorService(currentOperator.id, data)
      .then(response => {
        dispatch(updateOperator({ id: currentOperator.id, data: response }))
        navigate(-1);
      })
      .catch(error => errorAlert(error))
  }

  const validate = (data) => !hasSameValues(values, data)

  return (
    <OperatorForm
      title="Editar Operadora"
      goBack={handlerBack}
      defaultValues={values}
      submit={handlerSubmit}
      validate={validate}
    />
  );
}

export default ProductEditOperatorScreen