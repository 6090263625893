import RootRoutes from './routes/RootRoutes';
import { title } from './config/theme';
import { useEffect } from 'react';
import Images from 'assets/images';

function App() {

  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    link.href = Images[title.toLocaleLowerCase()].favicon;
    document.title = title;
  }, []);

  return (
    <div className='bg'>
      <RootRoutes />
    </div>
  );
}

export default App;
