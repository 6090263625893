import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { confirmAlert, errorAlert } from "../../../helpers/alerts";
import { BagOperationForm } from "../../../components";
import { createBagOperationService, getBagService } from "../../../services/client/bagService";
import { useEffect } from "react";
import { setLoading, setTitle } from "../../../redux/slices/uiSlice";
import { setSession } from "../../../redux/slices/sessionSlice";
import moment from "moment";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { currencyFormatter } from "helpers/util";

const BagOperationsScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { balance_bag } = useSelector(store => store.session);
  const { currentBag } = useSelector((store) => store.bags);

  useEffect(() => {
    dispatch(setTitle('Operación en bolsa'));
    // eslint-disable-next-line
  }, [])

  const values = {
    payment: "",
    reference: "",
    type: "",
  };
  const handlerBack = () => {
    navigate(-1);
  };

  const handlerSubmit = async (result) => {
    dispatch(setLoading(true));
    createBagOperationService(
      currentBag._id, {
      reference: result.reference,
      abono: result.payment,
      tipo_abono: result.type.toUpperCase(),
    }).then((data) => {
      navigate(-1);
      if (balance_bag)
        getBagService(balance_bag).then(({ saldo }) => {
          dispatch(setSession({ accountBalance: saldo }))
        }).catch((e) => console.error(e))
      if (result.type.toUpperCase() === 'ABONO') {
        confirmAlert({
          icon: "success",
          title: "Nuevo abono",
          html: `<div id="abono">
          <h3>Comercio: ${currentBag.hoster?.username}</h3>
          <table width="100%">
            <tr>
              <td style="text-align: right">Fecha y Hora:</td>
              <td>${moment().format('DD/MM/YYYY HH:mm:ss')}</td>
            </tr>
            <tr>
              <td style="text-align: right">Saldo Inicial:</td>
              <td>${currencyFormatter.format(data.saldo_anterior)}</td>
            </tr>
            <tr>
              <td style="text-align: right">Importe Abonado:</td>
              <td>${currencyFormatter.format(result.payment)}</td>
            </tr>
            <tr>
              <td style="text-align: right">Abono de Comisión:</td>
              <td>${currencyFormatter.format(data.commission)}</td>
            </tr>
            <tr>
              <td style="text-align: right">Saldo Final:</td>
              <td>${currencyFormatter.format(data.saldo_final)}</td>
            </tr>
          </table>
          </div>`,
          showCancelButton: false,
          showDenyButton: true,
          denyButtonColor: "#ED3726",
          confirmButtonText: "Descargar comprobante",
          denyButtonText: "Cerrar",
          preConfirm: () => {
            return html2canvas(document.querySelector('.swal2-show')).then(
              canvas => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF();
                pdf.addImage(imgData, 'PNG', 25, 0);
                pdf.save('comprobante.pdf');
              }
            );
          }
        })
      }
    }).catch((e) => errorAlert(e))
      .finally(() => dispatch(setLoading(false)));
  };

  return (
    <BagOperationForm
      goBack={handlerBack}
      submit={handlerSubmit}
      values={values}
    />
  );
};

export default BagOperationsScreen;
