import service from "../service";

export const loginService = ({ username, password }) => (
  service('post', `v3/seguridad/usuario/login`, {
    // contentType: "multipart/form-data",
    data: {
      username,
      password,
    }
  })
)

export const getUserLoginService = () => (
  service('get', `v3/seguridad/usuario/token`)
)

export const refreshSessionTimeService = () => (
  service('get', `v3/seguridad/usuario/token/refrescar`)
)

export const getUserPermissionsService = (userId) => (
  service('get', `v3/seguridad/usuario/${userId}/permisos`)
)

export const getUsersService = () => (
  service('post', `v3/seguridad/usuarios`)
)

export const getCommerceUsersService = (data) => (
  service('post', `v3/seguridad/usuarios/comercio`, { data })
)

export const createUserService = (data) => (
  service('post', `v3/seguridad/usuario`, {
    data
  })
)

export const updateUserService = (userId, data) => (
  service('patch', `v3/seguridad/usuario/${userId}`, {
    data
  })
)

export const updateUserStateService = (userId, data) => (
  service('patch', `v3/seguridad/usuario/${userId}/estado`, {
    data
  })
)

export const removeUserService = (userId) => (
  service('delete', `v3/seguridad/usuario/${userId}`)
)

export const recoverPassword = (email) => (
  service('post', `v3/seguridad/usuario/password/recuperar`, {
    data: { email }
  })
)
export const resetPassword = (id) => (
  service('get', `v3/seguridad/usuario/password/reset/${id}`)
)

export const resetPasswordByToken = (token, data) => (
  service('patch', `v3/seguridad/usuario/password/token`, {
    data: {
      token,
      ...data,
    }
  })
)
