import React from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { hasSameValues } from "../../../helpers/util";
import { PermissionForm } from '../../../components';
import { updatePermissionService } from '../../../services/security/permissionService';
import { errorAlert } from '../../../helpers/alerts';

const EditPermissionScreen = () => {
  const navigate = useNavigate();
  const { current } = useSelector((store) => store.permissions);
  const values={
    name: current.name,
    category: current.category,
  };

  const handlerBack = () => {
    navigate("/seguridad/permisos");
  };

  const handlerSubmit = async (result) => {
    try{
      result.state = current.state;
      await updatePermissionService(current.id, result);
      navigate("/seguridad/permisos");
    }catch(error){
      errorAlert(error);
    }
   
  };

  const validate = (data) => {
    return !hasSameValues(values, data);
  }
  return (
    <PermissionForm
      title={"Editar Permiso"}
      goBack={handlerBack}
      defaultValues={values}
      submit={handlerSubmit}
      validate={validate}
    />
  )
}

export default EditPermissionScreen