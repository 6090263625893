import React from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateUserService } from "../../../services/security/userServices";
import { hasSameValues } from "../../../helpers/util";
import { UserForm } from '../../../components';
import { errorAlert } from "../../../helpers/alerts";

const EditUserScreen = ({ type = 'commerce' }) => {
  const navigate = useNavigate();
  const { current } = useSelector((store) => store.users);
  const values = {
    nameUser: current.nameUser,
    name: current.name,
    lastname: current.lastname,
    email: current.email,
    rol: current.rol.id,
  }

  const handlerBack = () => navigate(`/seguridad/${type === 'admin' ? 'administradores' : 'usuarios'}`);

  const handlerSubmit = (result) => {
    updateUserService(current.id, result)
      .then(() => navigate(`/seguridad/${type === 'admin' ? 'administradores' : 'usuarios'}`))
      .catch((e) => errorAlert(e))
  };

  const validate = (data) => !hasSameValues(values, data);

  return (
    <UserForm
      title={type === 'admin' ? 'Administradores' : 'Usuarios'}
      goBack={handlerBack}
      defaultValues={values}
      submit={handlerSubmit}
      validate={validate}
      data={{ type }}
    />
  )
}

export default EditUserScreen