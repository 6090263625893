import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AddCircleOutline } from '@mui/icons-material';
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import { CustomButton, TableData } from '../../../components';
import { confirmAlert, errorAlert } from '../../../helpers/alerts';
import { getGroupsService, removeGroupService } from '../../../services/client/groupService';
import { removeGroup, setCurrentGroup, setGroups } from '../../../redux/slices/client/groupSlice';

const GroupScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { groups } = useSelector(store => store.group);

  useEffect(() => {
    getGroupsService()
      .then((response) => dispatch(setGroups(response)))
      .catch((e) => errorAlert(e))
    // eslint-disable-next-line
  }, []);

  const handlerAdd = () => {
    navigate("crear");
  };

  const handlerEdit = (data) => {
    dispatch(setCurrentGroup(data));
    navigate("editar");
  };

  const handlerDelete = ({ id, name }) => {
    confirmAlert({
      title: "Estás seguro que deseas eliminar este grupo?",
      text: `El grupo: ${name} será eliminado.`,
      onConfirm: async () => {
        removeGroupService(id)
          .then(() => dispatch(removeGroup(id)))
          .catch((e) => errorAlert(e))
      }
    })
  };

  return (
    <>
      <TableData
        dataRows={groups}
        headerButtons={[
          <CustomButton
            icon={<AddCircleOutline fontSize="large" />}
            key={'addGroup'}
            name={"Grupo"}
            click={handlerAdd}
          />
        ].filter(Boolean)}
        dataTitle={[
          "Id",
          "Nombre",
        ]}
        formatter={({ identification, name }) => [
          identification,
          name,
        ]}
        actions={[
          {
            label: "Editar",
            icon: <EditIcon fontSize="medium" />,
            onClick: (data) => {
              handlerEdit(data);
            },
          },
          {
            label: "Eliminar",
            icon: <DeleteForeverIcon fontSize="medium" />,
            onClick: (data) => {
              handlerDelete(data);
            },
          },
        ]}
      />
    </>
  )
}

export default GroupScreen