import service from "../service";

export const getExceptionsService = () => (
  service('get', `v3/ruteo/excepciones`)
)

export const createExceptionService = (data) => (
  service('post', `v3/ruteo/excepciones`, {
    data: { ...data }
  })
)

export const updateExceptionService = (idException, data) => (
  service('patch', `v3/ruteo/excepciones/${idException}`, {
    data: { ...data }
  })
)

export const removeExceptionService = (idException) => (
  service('delete', `v3/ruteo/excepciones/${idException}`)
)
