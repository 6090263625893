import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Button, Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { InputSelectFieldGrid, InputTextFieldGrid, TableData } from '../../../components';
import { getBagDenominationsService, getBagOperatorService, getBagSegmentService, getBagService, getBagsService } from '../../../services/client/bagService';
import { confirmAlert, errorAlert } from '../../../helpers/alerts';
import xml from '../../../helpers/xml';
import { getCarriersListService, getProvidersService } from '../../../services/admin/providerService';
import { getSimulatorParams, setSimulatorParams } from '../../../services/roteo/simulatorService';

const SimulatorScreen = () => {
  const dispatch = useDispatch();
  const { control, watch, setValue, handleSubmit, setError, clearErrors } = useForm();
  const [operators, setOperators] = useState([])
  const [carriers, setCarriers] = useState([])
  const [providers, setProviders] = useState([])
  const [segments, setSegments] = useState([])
  const [denominations, setDenominations] = useState([])
  const [bags, setBags] = useState([])
  const [running, setRunning] = useState(false)
  const [transactions, setTransactions] = useState([])
  const [bag, setBag] = useState(null)
  // const [provider, setBag] = useState(null)
  const { operator, balanceBag, provider, segment } = watch();

  const simulator = useMemo(() => {
    const worker = new Worker(new URL('../../../helpers/simulatorWorker.js', import.meta.url))
    worker.onmessage = ({ data: { action, data } }) => {
      console.log(action, data);
      switch (action) {
        case 'stop':
          setRunning(false)
          break;
        case 'response':
          handlerResponse(data)
          break;
        default:
          break;
      }
    }
    return worker;
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getBagsService().then((response) => setBags(response))
      .catch((error) => errorAlert(error));
    getProvidersService().then((response) => setProviders(response))
      .catch((error) => errorAlert(error));
    getCarriersListService().then((response) => setCarriers(response))
      .catch((error) => errorAlert(error));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    clearErrors()
    if (provider) {
      const providerData = providers.find(({ _id }) => _id === provider)
      getSimulatorParams(providerData.host).then((response) => {
        setValue('timeToResponse', response.timeToResponse)
        setValue('timeout', response.timeout)
        setValue('errorCode', response.errorCode)
      }).catch((e) => errorAlert(e))
    }
    // eslint-disable-next-line
  }, [provider])

  useEffect(() => {
    setValue('operator', '')
    if (balanceBag) {
      getBagService(balanceBag).then((response) => {
        setBag(response)
        setValue('balance', response.saldo)
      }).catch((e) => errorAlert(e))
      getBagOperatorService(balanceBag).then((response) => {
        setOperators(response.filter(({ commerce }) => commerce))
      }).catch((error) => errorAlert(error));
    }
    // eslint-disable-next-line
  }, [balanceBag])

  useEffect(() => {
    setValue('segment', '')
    if (operator) {
      getBagSegmentService(balanceBag, operator, false).then((result) => {
        setSegments(result)
        if (result.length === 1)
          setValue('segment', result[0]._id)
      })
    }
    // eslint-disable-next-line
  }, [operator]);

  useEffect(() => {
    setValue('amount', '')
    if (segments.length !== 0 && segment) {
      getBagDenominationsService(balanceBag, operator, false, segment).then((result) => {
        setDenominations(result)
        if (result.length === 1)
          setValue('amount', result[0]._id)
      })
    }
    // eslint-disable-next-line
  }, [segment, segments]);

  const handlerSetProvider = () => {
    const providerData = providers.find(({ _id }) => _id === provider)
    const { timeToResponse, timeout, errorCode } = watch()
    let error = false
    if (!timeToResponse) {
      setError('timeToResponse', { type: 'required', message: 'Debes ingresar el tiempo de respuesta' })
      error = true
    }
    if (!timeout) {
      setError('timeout', { type: 'required', message: 'Debes ingresar el tiempo limite' })
      error = true
    }
    if (error)
      return
    setSimulatorParams(providerData.host, {
      timeToResponse, timeout, errorCode
    }).then(() => {
      confirmAlert({
        title: '¡Éxito!',
        text: 'Se han establecido los parametros del proveedor correctamente',
        icon: "success",
        showCancelButton: false,
      })
    }).catch((e) => errorAlert(e))
  }

  const onSubmit = (data) => {
    const segment = segments.find(({ _id }) => _id === data.segment)
    data.product = segment.code;
    data.balanceBag = bag
    data.operator = segment.carrierId
    if (running) {
      simulator.postMessage({ action: 'stop' });
    } else {
      setRunning(true)
      simulator.postMessage({ action: 'start', data });
    }
  }

  const handlerResponse = (response) => {
    let xmlResponse
    let resposeProvider
    let error = response.error
    if (error) {
      error = "Error"
    } else {
      xmlResponse = new xml(response.xml)
      const xmlResponseError = xmlResponse.getElementValue("phon:ProviderResponseCode");
      if (xmlResponseError !== '00')
        error = xmlResponseError
      else
        resposeProvider = {
          ProviderReference: xmlResponse.getElementValue("phon:ProviderReference"),
          AuthCode: xmlResponse.getElementValue("phon:AuthCode"),
          ProviderResponseMessage: xmlResponse.getElementValue("phon:ProviderResponseMessage")
        }
    }
    console.log('carriers', response.operator, carriers);
    setTransactions((transactions) => [...transactions, {
      externalTrace: response.externalTrace,
      operator: response.operator,
      product: response.product,
      number: response.number,
      amount: response.amount,
      elapsed_time: response.elapsed_time,
      resposeProvider,
      error
    }])
  }

  return (
    <>
      <Grid container >
        <Grid container item flex={1} justifyItems={'center'} alignItems={'center'} spacing={2} p={2} mt={2} style={{ borderRight: 'solid 1px #888888' }}>
          <Grid item xs={12} mb={5}>
            <Typography variant='h4' textAlign={'center'}>Cliente</Typography>
          </Grid>
          <Grid item xs={9}>
            <InputSelectFieldGrid
              name={"balanceBag"}
              control={control}
              required={"Debes seleccionar una bolsa"}
              label={"Seleccionar bolsa"}
              data={bags}
              disabled={running}
              formatter={(data) =>
                data?.map(({ _id, hashtag, username }) => ({ id: _id, name: hashtag || username }))
              }
            />
          </Grid>
          <Grid item xs={3}>
            <InputTextFieldGrid
              name={"balance"}
              control={control}
              label={"Saldo"}
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <InputTextFieldGrid
              name={"tps"}
              control={control}
              label={"Transacciónes por segundo"}
              required={"Debes ingresar cuantas transacciones por segundo"}
              inputProps={{ type: 'number' }}
              inputType={"number"}
              step={"any"}
              disabled={running}
            />
          </Grid>
          <Grid item xs={6}>
            <InputTextFieldGrid
              name={"seconds"}
              control={control}
              label={"Segundos"}
              required={"Debes ingresar por cuantos segundos se ejecutará el simulador"}
              inputProps={{ type: 'number' }}
              inputType={"number"}
              step={"any"}
              disabled={running}
            />
          </Grid>
          <Grid item xs={12}>
            <InputSelectFieldGrid
              name={"operator"}
              control={control}
              required={"Debes seleccionar una operadora"}
              label={"Compañia"}
              data={operators.map(({ _id: id, name }) => ({ id, name }))}
              disabled={!balanceBag || running}
            />
          </Grid>
          <Grid item xs={12}>
            <InputSelectFieldGrid
              name={"segment"}
              control={control}
              label={"Tipo"}
              required={"Debes seleccionar una operadora"}
              data={segments.map(({ _id: id, name }) => ({ id, name }))}
              disabled={!operator || running}
            />
          </Grid>
          <Grid item xs={12}>
            <InputSelectFieldGrid
              name={"amount"}
              control={control}
              required={"Debes seleccionar un monto"}
              label={"Monto"}
              data={denominations.map(({ imp: id, denomination: name }) => ({ id, name }))}
              disabled={!segment || running}
            />
          </Grid>
          <Grid item xs={12}>
            <InputTextFieldGrid
              name={"number"}
              control={control}
              label={"Número"}
              rules={{
                required: { value: true, message: "Debes agregar el número celular" },
                maxLength: { value: 10, message: "El número celular es de máximo 10 dígitos" },
                pattern: { value: /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, message: "Número celular inválido" },
              }}
              inputType={'tel'}
              inputProps={{
                type: 'tel',
                maxLength: 10
              }}
              disabled={running}
            />
          </Grid>
          <Grid item xs={12} justifyItems={'center'} alignItems={'center'} textAlign={'center'} m={2}>
            <Button
              color={running ? 'error' : 'primary'}
              size='large'
              onClick={handleSubmit(onSubmit)}
              variant="contained"
            >
              {running ? 'Detener' : 'Iniciar'}
            </Button>
          </Grid>
        </Grid>
        <Grid container item flex={1} alignContent={'baseline'} spacing={2} p={2} mt={2}>
          <Grid item xs={12} mb={5}>
            <Typography variant='h4' textAlign={'center'}>Proveedor</Typography>
          </Grid>
          <Grid item xs={12}>
            <InputSelectFieldGrid
              name={"provider"}
              control={control}
              label={"Seleccionar proveedor"}
              data={providers.filter(({ container_name: name }) => name.toLowerCase().includes('sandbox'))}
              formatter={(data) =>
                data?.map(({ _id: id, container_name: name }) => ({ id, name }))
              }
            />
          </Grid>
          <Grid item xs={4}>
            <InputTextFieldGrid
              name={"timeToResponse"}
              control={control}
              label={"Tiempo de respuesta (ms)"}
              // required={"Debes ingresar el tiempo de respuesta"}
              inputProps={{ type: 'number' }}
              inputType={"number"}
              step={"any"}
            />
          </Grid>
          <Grid item xs={4}>
            <InputTextFieldGrid
              name={"timeout"}
              control={control}
              label={"Tiempo limite (ms)"}
              // required={"Debes ingresar el tiempo limite"}
              inputProps={{ type: 'number' }}
              inputType={"number"}
              step={"any"}
            />
          </Grid>
          <Grid item xs={4}>
            <InputTextFieldGrid
              name={"errorCode"}
              control={control}
              label={"Código de error"}
            />
          </Grid>
          <Grid item xs={12} justifyItems={'center'} alignItems={'center'} textAlign={'center'} m={2}>
            <Button
              color={'primary'}
              size='large'
              onClick={handlerSetProvider}
              disabled={provider === undefined}
              variant="contained"
            >
              Establecer
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12}>
        <TableData
          rowsPerPageOpt={100}
          dataRows={transactions.map((item) => ({
            ...item,
            style: item.error && { color: 'red' }
          }))}
          dataTitle={[
            "Referencia",
            "Operator",
            "Product",
            "Number",
            "Amount",
            "Time",
            "Status",
            "Referencia Proveedor",
            "Autorización",
          ]}
          formatter={({
            externalTrace,
            operator,
            product,
            number,
            amount,
            elapsed_time,
            error,
            resposeProvider
          }) => ([
            externalTrace,
            carriers.find(({ carrier_id }) => carrier_id == operator)?.name,
            product + amount,
            number,
            amount,
            elapsed_time,
            error ? error : resposeProvider?.ProviderResponseMessage,
            resposeProvider?.ProviderReference,
            resposeProvider?.AuthCode,
          ])}
        />
      </Grid>
    </>
  )
}

export default SimulatorScreen