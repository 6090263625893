import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DenominationExtraForm } from '../../../../components';
import { errorAlert } from '../../../../helpers/alerts';
import { hasSameValues } from '../../../../helpers/util';
import { setExtraDenominations } from '../../../../redux/slices/admin/extraDenominationSlice';
import { updateExtraDenominationService } from '../../../../services/admin/extraDenominationService';

const EditExtraDenominationScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentDenomination: { id: idDenomination } } = useSelector((store) => store.denominations);
  const { currentExtraDenomination: current } = useSelector((store) => store.extraDenominations)
  const values = {
    extra_data: current.datos,
    validity: current.vig,
    name: current.nombre,
    description: current.description,
    gb: current.gb,
    file: current.fd
  };

  const handlerBack = () => navigate(-1);

  const handlerSubmit = async (data) => {
    if (typeof data.file !== 'object') {
      data.image = false
      delete data.file
    }
    data = {
      ...data,
      productoTae: idDenomination,
      datos: data.extra_data,
      nombre: data.name,
      vig: data.validity,
    };
    try {
      const response = await updateExtraDenominationService(current.id, data);
      dispatch(setExtraDenominations(response));
      navigate(-1);
    } catch (error) {
      errorAlert(error);
    }
  };
  const validate = (data) => !hasSameValues(values, data)
  return (
    <DenominationExtraForm
      title="Crear Denominación Extra"
      goBack={handlerBack}
      defaultValues={values}
      submit={handlerSubmit}
      validate={validate}
    />
  );
}

export default EditExtraDenominationScreen