import { Button, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { errorAlert } from '../../helpers/alerts'
import { validateEmptyFields } from '../../helpers/util'
import { setRegions } from '../../redux/slices/admin/regionsSlice'
import BackButton from '../BackButton'
import InputSelectFieldGrid from '../common/InputSelectFieldGrid'
import InputTextFieldGrid from '../common/InputTextFieldGrid'
import { getRegionsService } from '../../services/admin/ladasService'

const LadaForm = ({title,goBack,defaultValues,submit,validate}) => {
    const {control,handleSubmit}= useForm({defaultValues});
    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(true);
    const {regions}=useSelector(store => store.regions);
    const watch = useWatch({ control });

    useEffect(()=>{
        (async()=>{
            try{
                const result = await getRegionsService();
                dispatch(setRegions(result))
            }
            catch(error){
                errorAlert(error);
            }
        })();
        // eslint-disable-next-line
    },[]);

  useEffect(() => {
    setDisabled(validateEmptyFields(watch, { validate }));
    // eslint-disable-next-line
  }, [watch]);
  return (
    <>
      <BackButton name={title} click={goBack} />
      <Grid container justifyContent="center" alignItems="center" mt={"40px"}>
        <Grid width={"70%"} container spacing={3}>
          <InputTextFieldGrid
            xs={6}
            name={"lada"}
            control={control}
            required={"Debes agregar la lada"}
            label={"Lada"}
          />
          <InputSelectFieldGrid
            xs={6}
            name={"region"}
            control={control}
            required={"Debes seleccionar una región"}
            label={"Seleccionar región"}
            data={regions}
            formatter = {(data) => data.map(({id,nombre})=>({id:id,name:nombre}))}
            disabled={validate !== undefined}
          />
          <Grid container justifyContent="center" mt={"40px"}>
            <Button
              sx={{ m: 2, fontWeight: "bold", backgroundColor: "#0E73ED" }}
              onClick={handleSubmit(submit)}
              disabled={disabled}
              variant="contained"
            >
              Guardar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default LadaForm