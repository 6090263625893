import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { TableData } from '../../../../components';
import { errorAlert } from '../../../../helpers/alerts';
import { setPanel } from '../../../../redux/slices/route/routeSlice';
import { getPINRuteoService, setPINProviderCredentialService } from '../../../../services/roteo/routeService';
import { setLoading } from 'redux/slices/uiSlice';
import { getAllCredPINProductsService } from 'services/admin/providerService';
import { setPINProducts } from 'redux/slices/admin/productSlice';
import { getAllPINProductsService } from 'services/admin/productService';

const PINRouteScreen = () => {
  const dispatch = useDispatch();
  const { panel } = useSelector(store => store.route)
  const { pinProducts } = useSelector((state) => state.products);
  const [providers, setProviders] = useState([])

  useEffect(() => {
    getAllPINProductsService()
      .then(data => dispatch(setPINProducts(data)))
      .catch(error => errorAlert(error))
    getAllCredPINProductsService()
      .then(response => setProviders(response))
      .catch(error => errorAlert(error))
    getPINRuteoService()
      .then(response => dispatch(setPanel(response)))
      .catch(error => errorAlert(error));
    // eslint-disable-next-line
  }, []);

  const handlerChangePrimaryProvider = async (idCredential, product) => {
    dispatch(setLoading(true));
    setPINProviderCredentialService({ idCredential, idProduct: product.id })
      .then(response => dispatch(setPanel(response)))
      .catch(error => errorAlert(error))
      .finally(() => dispatch(setLoading(false)));
  }

  const setList = (product, otherProvider) => {
    const result = providers.filter(({ idProduct }) => (
      idProduct.id == product
    )).map(({ idCredential }) => ({
      id: idCredential.id,
      name: idCredential.name_connector
    }))
    return [
      { id: 'null', name: 'Sin asignar', operadoras: [] },
      ...result
    ]
  }
  return (
    <>
      <TableData
        dataRows={pinProducts.map(item => ({
          ...item,
          primaryProvider: panel?.find(({ product }) => product?.id == item?.id)?.primaryProvider,
        }))}
        headerButtons={[
        ]}
        dataTitle={[
          "Logo",
          "Nombre",
          "Segmento",
          "Proveedor Primario",
        ]}
        formatter={({ id, fd, name, idSegment, primaryProvider }) => [
          { type: "img", value: `/v3/file/${fd}/` },
          name,
          idSegment.name,
          {
            name: "provider_primary",
            type: "select_button",
            label: 'Proveedor',
            list: setList(id),
            value: providers.length == 0 ? null : (primaryProvider || 'null'),
            onChange: handlerChangePrimaryProvider
          },
        ]}
      />
    </>
  )
}

export default PINRouteScreen