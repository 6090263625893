import { Chip, FormHelperText, Grid, InputLabel, TextField, styled } from '@mui/material'
import React, { useState } from 'react'
import { Controller } from 'react-hook-form'

const CssTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: 0
    },
    '&:hover fieldset': {
      borderColor: '#B2BAC2',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#6F7E8C',
    },
  },
});
const InputChipFieldGrid = ({ xs, sx, name, control, required, label, disabled = false, multiline, placeholder, size = "small", rules = {}, inputProps, onTextChange = () => { }, defaultValue = "" }) => {
  const [text, setText] = useState('')
  return (
    <Grid
      item
      xs={xs}
      alignContent="center"
      justifyContent={"center"}
      style={{ width: '100%' }}
    >
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={!disabled && {
          ...rules,
          required
        }}
        render={({
          field: { value, onChange },
          fieldState: { error },
        }) => (
          <Grid container spacing={1} direction={'column'}>
            <Grid item>
              <InputLabel>{label}</InputLabel>
            </Grid>
            <Grid item border={1} borderColor={'#C4C4C4'} p={.5} mt={1}>
              {
                (value || []).map((item, index) => (
                  <Chip
                    sx={{ m: 0.5 }}
                    key={index}
                    label={item}
                    onDelete={() => {
                      onChange(value.filter((_, i) => i !== index))
                    }}
                  />
                ))
              }
              <CssTextField
                required={!!required}
                size={size}
                value={text}
                onChange={(e) => {
                  setText(e.target.value)
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                    if (text.trim()) {
                      onChange([...value, ...text.split(',').map(item => item.trim())])
                      setText('')
                    }
                  }
                }}
                onBlur={() => {
                  if (text.trim()) {
                    onChange([...value, ...text.split(',').map(item => item.trim())])
                    setText('')
                  }
                }}
                error={!!error}
                helperText={error && <span> {error.message}</span>}
                disabled={disabled}
                placeholder={placeholder}
                sx={sx}
                inputProps={inputProps}
              />
            </Grid>
            <Grid item>
              <FormHelperText error={!!error}>{error && error.message}</FormHelperText>
            </Grid>
          </Grid>
        )}
      />
    </Grid>
  )
}

export default InputChipFieldGrid