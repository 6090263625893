import service from "../service";

export const getExtraDenominationByDenominationService = (idDenomination) => (
  service('get', `v3/producto/denominacion/extra/${idDenomination}`)
)

export const createExtraDenominationService = (data) => (
  service('post', `v3/producto/denominacion/extra`, {
    contentType: "multipart/form-data",
    form: data
  })
)
export const updateExtraDenominationService = (idExtraDenomination, data) => (
  service('patch', `v3/producto/denominacion/extra/${idExtraDenomination}`, {
    contentType: "multipart/form-data",
    form: data
  })
)
export const removeExtraDenominationService = (idExtraDenomination) => (
  service('delete', `v3/producto/denominacion/extra/${idExtraDenomination}`)
)
