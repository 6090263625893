import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DenominationExtraForm } from "../../../../components";
import { errorAlert } from "../../../../helpers/alerts";
import { setExtraDenominations } from "../../../../redux/slices/admin/extraDenominationSlice";
import { createExtraDenominationService } from "../../../../services/admin/extraDenominationService";

const CreateExtraDenominationScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentDenomination: { id: idDenomination } } = useSelector((store) => store.denominations);
  const values = {
    extra_data: "",
    validity: "",
    name: "",
    description: "",
    gb: "",
    file: ""
  };

  const handlerBack = () => navigate(-1);

  const handlerSubmit = async (data) => {
    data = {
      ...data,
      productoTae: idDenomination,
      datos: data.extra_data,
      nombre: data.name,
      vig: data.validity,
    };
    try {
      const response = await createExtraDenominationService(data);
      dispatch(setExtraDenominations(response));
      navigate(-1);
    } catch (error) {
      errorAlert(error);
    }
  };
  return (
    <DenominationExtraForm
      title="Crear Denominación Extra"
      goBack={handlerBack}
      defaultValues={values}
      submit={handlerSubmit}
    />
  );
};

export default CreateExtraDenominationScreen;
