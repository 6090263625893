import { Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { batch, useDispatch, useSelector } from "react-redux";
import { errorAlert } from "../../helpers/alerts";
import { getProvidersService, getProvidersToRuteoV2Service } from "../../services/admin/providerService";
import { getCommercesService } from "../../services/client/commerceService";
import { getOperatorsService } from "../../services/admin/operatorServices";
import { getSegmentsByOperatorService } from "../../services/admin/segmentServices";
import { getDenominationBySegmentService } from "../../services/admin/denominationServices";
import {
  setOperators,
  setSegments,
  setRegions,
  setDenominations,
} from "../../redux/slices/route/exceptionSlice";
import { setProviders } from "../../redux/slices/admin/providerSlice";
import { setCommerces } from "../../redux/slices/client/commerceSlice";
import BackButton from "../BackButton";
import InputSelectFieldGrid from "../common/InputSelectFieldGrid";
import InputTextFieldGrid from "../common/InputTextFieldGrid";
import { validateEmptyFields } from "../../helpers/util";
import { getRegionsService } from "../../services/admin/ladasService";

const ExceptionForm = ({ title, goBack, defaultValues, submit, validate }) => {
  const { control, handleSubmit, setValue } = useForm({ defaultValues });
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(true);
  const [disabledRegions, setDisabledRegions] = useState(false);
  const watch = useWatch({ control });
  const {
    operators,
    regions,
    segments,
    denominations,
  } = useSelector((store) => store.exceptions);

  const { commerces } = useSelector((store) => store.commerces);
  const { operator, segment, secondaryProvider, primaryProvider } = watch;
  const [providers, setProviders] = useState([])

  useEffect(() => {
    (async () => {
      try {
        const operators_ = await getOperatorsService();
        const commerces_ = await getCommercesService();
        const providers_ = await getProvidersToRuteoV2Service();
        batch(() => {
          dispatch(setOperators(operators_));
          dispatch(setCommerces(commerces_));
          setProviders(providers_);
        });
      } catch (error) {
        errorAlert(error);
      }
    })();
    return () => {
      dispatch(setRegions([]));
      dispatch(setSegments([]));
      dispatch(setDenominations([]));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (operator) {
      const item = operators.find(({ id }) => id === operator);
      getSegmentsByOperatorService(operator)
        .then((response) => dispatch(setSegments(response)))
        .catch(error => errorAlert(error))
      if (item?.regions === true) {
        getRegionsService()
          .then((response) => {
            dispatch(setRegions(response));
            setDisabledRegions(false);
          }).catch(error => errorAlert(error))
      } else {
        dispatch(setRegions([]));
        setDisabledRegions(true);
      }
    } else {
      dispatch(setSegments([]))
      dispatch(setRegions([]));
    }
    // eslint-disable-next-line
  }, [operator]);

  useEffect(() => {
    if (segment)
      getDenominationBySegmentService(segment)
        .then((response) => dispatch(setDenominations(response)))
        .catch(error => errorAlert(error))
    else {
      dispatch(setDenominations([]));
    }
    // eslint-disable-next-line
  }, [segment]);

  useEffect(() => {
    setDisabled(
      validateEmptyFields(watch, {
        validate,
        skip_fields: [
          "operator",
          "region",
          "segment",
          "denomination",
          "commerce",
          "secondaryProvider",
          "amount"
        ],
      })
    );
    // eslint-disable-next-line
  }, [watch]);

  const validateProviders = (otherProvider) => ([
    { _id: null, container_name: ''},
    ...providers.filter(({ _id, operators }) => (
      operators.some((id => id === operator)) && _id !== otherProvider
    ))
  ])

  return (
    <>
      <BackButton name={title} click={goBack} />
      <Grid container justifyContent="center" alignItems="center" mt={"40px"}>
        <Grid width={"70%"} container spacing={3}>
          <InputSelectFieldGrid
            name={"operator"}
            xs={6}
            control={control}
            required={"Debes seleccionar una operadora"}
            label={"Seleccionar operadora"}
            data={operators}
            formatter={(data) =>
              data?.map(({ id, name }) => ({ id: id, name: name }))
            }
            onSelect={() => {
              setValue('region', '')
              setValue('segment', '')
              setValue('denomination', '')
            }}
          />
          <InputSelectFieldGrid
            name={"region"}
            xs={6}
            control={control}
            label={"Seleccionar región"}
            displayEmpty={true}
            data={[{ id: '', nombre: 'Todas' }, ...regions]}
            formatter={(data) =>
              data?.map(({ id, nombre }) => ({ id: id, name: nombre }))
            }
            disabled={disabledRegions}
            onSelect={() => {
              setValue('segment', '')
              setValue('denomination', '')
            }}
          />
          <InputSelectFieldGrid
            name={"segment"}
            xs={6}
            control={control}
            // required={"Debes seleccionar un segmento"}
            label={"Seleccionar segmento"}
            displayEmpty={true}
            data={[{ id: '', name: 'Todos' }, ...segments]}
            formatter={(data) =>
              data?.map(({ id, name }) => ({ id: id, name: name }))
            }
            onSelect={() => {
              setValue('denomination', '')
            }}
          />
          <InputSelectFieldGrid
            name={"denomination"}
            xs={6}
            control={control}
            // required={"Debes seleccionar una denominación"}
            label={"Seleccionar denominación"}
            displayEmpty={true}
            data={[{ id: '', denomination: 'Todas' }, ...denominations]}
            formatter={(data) =>
              data.map((item) => ({ id: item.id, name: item.denomination }))
            }
          />
          <InputSelectFieldGrid
            name={"commerce"}
            xs={12}
            control={control}
            // required={"Debes seleccionar un comercio"}
            label={"Seleccionar comercio"}
            displayEmpty={true}
            data={[{ id: '', username: 'Todos' }, ...commerces]}
            formatter={(data) =>
              data.map((item) => ({ id: item.id, name: item.username }))
            }
          />
          <InputSelectFieldGrid
            name={"primaryProvider"}
            xs={6}
            control={control}
            required={"Debes seleccionar el proverdo primario"}
            label={"Selecciona el proveerdor primario"}
            disabled={operator === ''}
            data={validateProviders(secondaryProvider)}
            formatter={(data) =>
              data?.map((item) => ({ id: item._id, name: item.container_name }))
            }
          />
          <InputSelectFieldGrid
            name={"secondaryProvider"}
            xs={6}
            control={control}
            // required={"Debes seleccionar el proverdo secundario"}
            label={"Selecciona el proveerdor secundario"}
            disabled={operator === ''}
            data={validateProviders(primaryProvider)}
            formatter={(data) =>
              data?.map((item) => ({ id: item._id, name: item.container_name }))
            }
          />
          <Grid container justifyContent="center" mt={3}>
            <InputTextFieldGrid
              name={"amount"}
              xs={4}
              control={control}
              // required={"Debes agregar la cantidad"}
              label={"Monto"}
              inputProps={{ type: 'number' }}
              inputType={"number"}
              step={"any"}
            />
          </Grid>
          <Grid container justifyContent="center" mt={5}>
            <Button
              sx={{ m: 2, fontWeight: "bold", backgroundColor: "#0E73ED" }}
              onClick={handleSubmit(submit)}
              disabled={disabled}
              variant="contained"
            >
              Guardar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ExceptionForm;
