import axios from "axios";
import service from "../service";
import soap from "../soap";

export const getNextExternalTrace = (amount) => (
  service('post', 'v3/venta/consecutivo', { data: { amount } })
)

export const getFavorites = () => (
  service('get', 'v3/venta/favoritos')
)

export const getLastMovements = () => (
  service('get', 'v3/venta/ultimos_movimentos')
)

export const sendTiketByEmail = (email, data) => (
  service('post', 'v3/venta/enviar_ticket', {
    data: {
      email,
      data
    }
  })
)

export const getVersionService = () => (
  soap('GetVersion', 'tae', {
    xml: (`<phon:GetVersion/>`)
  })
)

export const doStoreSale = async({ username, password, provider, amount, number, storeId, productDetail, externalTrace, salepointId }) => {
  let ip = await axios.get('https://api.ipify.org');
  return soap('DoStoreSale', 'tae', {
    xml: (`
    <phon:DoStoreSale>
      <phon:user>${username}</phon:user>
      <phon:password>${password}</phon:password>
      <phon:storeId>${storeId}</phon:storeId>
      <phon:providerId>${provider}</phon:providerId>
      <phon:amount>${amount}</phon:amount>
      <phon:rechargeNumber>${number}</phon:rechargeNumber>
      <phon:externalTrace>${externalTrace}</phon:externalTrace>
      <phon:productDetail>${productDetail}</phon:productDetail>
    </phon:DoStoreSale>
    `),
    headers: {
      'HEBE-Platform': 'web',
      'HEBE-Version': '1.0.0',
      'HEBE-IP': ip.data,
      'HEBE-Salepoint': salepointId
    }
  })
}