import { AddCircleOutline } from "@mui/icons-material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BackButton, CustomButton, TableData } from "../../../../components";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import { confirmAlert, errorAlert } from "../../../../helpers/alerts";
import { Grid } from "@mui/material";
import { removePINProduct, setCurrentPINProduct, setPINProducts, updatePINProduct } from "redux/slices/admin/productSlice";
import { getPINProductsService, removePINProductService, updatePINProductService } from "services/admin/productService";

const ProductsPINScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pinProducts, currentPINSegment } = useSelector((state) => state.products);

  useEffect(() => {
    getPINProductsService(currentPINSegment.id)
      .then(data => dispatch(setPINProducts(data)))
      .catch(error => errorAlert(error))
    // eslint-disable-next-line
  }, []);

  const handlerEdit = (row) => {
    dispatch(setCurrentPINProduct(row));
    navigate("editar");
  };

  const handlerCheck = async ({ id }, currentValue, col) => {
    const value = !currentValue;
    updatePINProductService(currentPINSegment.id, id, { [col]: value })
      .then(() => dispatch(updatePINProduct({ id, data: { [col]: value } })))
      .catch(error => errorAlert(error))

  };

  const handlerDelete = ({ id, name }) => {
    confirmAlert({
      title: "Estas seguro que deseas eliminar este segmento?",
      text: `El segmento ${name} será eliminado.`,
      onConfirm: async () => {
        try {
          await removePINProductService(currentPINSegment.id, id,);
          dispatch(removePINProduct(id));
        }
        catch (error) {
          errorAlert(error)
        }
      }
    })
  };

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <div>
          <BackButton
            name={"Volver"}
            click={() => navigate(-1)}
          />
        </div>
        <div className="container-title">
          <div className="title">Pines Electrónicos</div>
          <div className="subtitle">{currentPINSegment?.name}</div>
        </div>
        <div>
          <CustomButton
            icon={<AddCircleOutline fontSize="large" />}
            additional_class={"headerButton"}
            key="add"
            name={"PIN"}
            click={() => navigate("crear")}
          />
        </div>
      </Grid>
      <TableData
        dataRows={pinProducts}
        dataTitle={["Logo", "Nombre", "Código", "Estado"]}
        formatter={({ name, code, state, fd }) => [
          { type: "img", value: `/v3/file/${fd}/` },
          name,
          code,
          { name: "state", type: "boolean", value: state },
        ]}
        onChecked={handlerCheck}
        actions={[
          {
            label: "Editar",
            icon: <EditIcon fontSize="medium" />,
            onClick: (data) => {
              handlerEdit(data);
            },
          },
          {
            label: "Eliminar",
            icon: <DeleteForeverIcon fontSize="medium" />,
            onClick: (data) => {
              handlerDelete(data);
            },
          },
        ]}
      />
    </>
  );
};

export default ProductsPINScreen;
