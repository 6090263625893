import { AddCircleOutline } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CustomButton, TableData } from 'components';
import { getHebeCodesService, deleteHebeCodesService } from 'services/admin/providerService';
import EditIcon from '@mui/icons-material/Edit';
import { Close } from '@mui/icons-material';
import { setHebeCodeSelected } from 'redux/slices/admin/providerSlice';
import { validatePermission } from 'helpers/authentication';
import { errorAlert, confirmAlert } from 'helpers/alerts';

const HebeCodesScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [update, setUpdate] = useState(0);
  const [hebeCodes, setHebeCodes] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        console.log("getHebeCodesService");
        const response = await getHebeCodesService();
        console.log("getHebeCodesService", response);
        setHebeCodes(response);
      } catch (error) {
        errorAlert(error);
      }
    })();

    // eslint-disable-next-line
  }, [update]);


  return (
    <TableData
      dataRows={hebeCodes}
      headerButtons={[
        validatePermission('proveedores', 'proveedores', ['agregar']) &&
        <CustomButton
          icon={<AddCircleOutline fontSize="large" />}
          key="addProvider"
          name={"Nuevo Código"}
          click={() => {
            navigate("create");
          }}
        />,
      ]}
      dataTitle={[
        "Nombre",
        "Descipción",
        "Código"
      ]}
      formatter={({ name, description, code }) => ([
        name,
        description,
        code,
      ])}
      actions={[
        validatePermission('proveedores', 'proveedores', ['editar']) &&
        {
          label: "Editar",
          icon: <EditIcon fontSize="medium" />,
          onClick: (row) => {
            dispatch(setHebeCodeSelected(row));
            navigate("edit")
          },
        },
        validatePermission('proveedores', 'productos', ['editar']) &&
        {
          label: "Eliminar",
          icon: <Close fontSize="medium" />,
          onClick: (data) => {
            confirmAlert({
              title: "Estás seguro que deseas eliminar este código?",
              text: `Código: ${data.name} será eliminado.`,
              onConfirm: async () => {
                try {
                  await deleteHebeCodesService(data.id);
                  setUpdate(update + 1);
                }
                catch (error) {
                  errorAlert(error);
                }
              }
            })
          },
        },
      ]}
    />
  )
}

export default HebeCodesScreen