import { Edit } from "@mui/icons-material";
import React, { useEffect } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DenominationDataAndExtras } from "../../../../components";
import CardBox from "../../../../components/common/CardBox";
import { errorAlert } from "../../../../helpers/alerts";
import { validatePermission } from "../../../../helpers/authentication";
import { setCurrentDenomination, setDenominations } from "../../../../redux/slices/admin/denominationSlice";
import { setExtraDenominations } from '../../../../redux/slices/admin/extraDenominationSlice';
import {
  setCurrentOperator,
  setOperators,
} from "../../../../redux/slices/admin/operatorSlice";
import {
  setCurrentSegment,
  setSegments,
} from "../../../../redux/slices/admin/segmentSlice";
import { getDenominationBySegmentService } from "../../../../services/admin/denominationServices";
import { getOperatorsService } from "../../../../services/admin/operatorServices";
import { getSegmentsByOperatorService } from "../../../../services/admin/segmentServices";

const ProductScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { operators, currentOperator } = useSelector((store) => store.operators);
  const { segments, currentSegment } = useSelector((store) => store.segments);
  const { denominations, currentDenomination } = useSelector((store) => store.denominations);

  useEffect(() => {
    getOperatorsService()
      .then(response => dispatch(setOperators(response)))
      .catch(error => errorAlert(error))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (currentOperator)
      getSegmentsByOperatorService(currentOperator.id)
        .then(response => dispatch(setSegments(response)))
        .catch(error => errorAlert(error))
    // eslint-disable-next-line
  }, [currentOperator]);

  useEffect(() => {
    if (currentSegment)
      getDenominationBySegmentService(currentSegment.id)
        .then(response => dispatch(setDenominations(response)))
        .catch(error => errorAlert(error))
    // eslint-disable-next-line
  }, [currentSegment]);

  const handlerClickOperator = (operator) => {
    if (operator.id !== currentOperator?.id)
      batch(() => {
        dispatch(setCurrentOperator(operator));
        dispatch(setCurrentSegment(null));
        dispatch(setCurrentDenomination(null));
        dispatch(setExtraDenominations());
      })
  };

  const handlerClickSegment = (segment) => {
    if (segment.id !== currentSegment?.id)
      batch(() => {
        dispatch(setCurrentSegment(segment));
        dispatch(setCurrentDenomination(null));
        dispatch(setExtraDenominations())
      })
  };

  const handlerClickDenomination = (denomination) => {
    if (denomination.id !== currentDenomination?.id)
      batch(() => {
        dispatch(setCurrentDenomination(denomination));
        dispatch(setExtraDenominations())
      })
  };

  return (
    <div>
      <CardBox
        name={"Operadoras"}
        buttons={
          [
            validatePermission('productos', 'productos', ['agregar', 'editar', 'eliminar']) &&
            {
              icon: <Edit sx={{ ml: 1 }} />,
              key: "operators",
              name: "Editar",
              click: () => {
                navigate("operadoras");
              },
            },
          ]}
        values={operators.filter(({ state }) => state)}
        formatter={(item) => ({
          ...item,
          image: `/v3/file/${item.fd}/`
        })}
        handleSelect={handlerClickOperator}
        isSelect={currentOperator && currentOperator.id}
      />
      {currentOperator && (
        <CardBox
          name={"Segmentos"}
          buttons={[
            validatePermission('productos', 'productos', ['agregar', 'editar', 'eliminar']) &&
            {
              icon: <Edit sx={{ ml: 1 }} />,
              key: "segments",
              name: "Editar",
              click: () => {
                navigate("segmentos");
              },
            },
          ]}
          values={segments.filter(({ state }) => state)}
          formatter={(item) => ({
            ...item,
            image: `/v3/file/${item.fd}/`
          })}
          handleSelect={handlerClickSegment}
          isSelect={currentSegment && currentSegment.id}
        />
      )}
      {currentSegment && (
        <CardBox
          name={"Denominaciones"}
          buttons={[
            validatePermission('productos', 'productos', ['agregar', 'editar', 'eliminar']) &&
            {
              icon: <Edit sx={{ ml: 1 }} />,
              key: "denominations",
              name: "Editar",
              click: () => {
                navigate("denominaciones");
              },
            },
          ]}
          values={denominations?.filter(({ state }) => state)}
          formatter={(item) => ({
            ...item,
            name: item.denomination
          })}
          handleSelect={handlerClickDenomination}
          isSelect={currentDenomination && currentDenomination.id}
        />
      )}
      {
        currentDenomination && currentDenomination.id && (
          <DenominationDataAndExtras
            name={"Denominación: " + currentDenomination.name}
            data={currentDenomination}
            operatorId={currentOperator.id}
            handleSelect={handlerClickDenomination}
          />
        )
      }
    </div>
  );
};

export default ProductScreen;
