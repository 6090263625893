import React from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { errorAlert } from '../../../helpers/alerts';
import { createAlertService } from 'services/admin/alertsService';
import { addAlert } from 'redux/slices/admin/alertSlice';
import AlertForm from 'components/admin/AlertForm';

const CreateAlertScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handlerSubmit = async (result) => {
    createAlertService(result).then((response) => {
      dispatch(addAlert(response));
      navigate(-1);
    }).catch(error => errorAlert(error))
  }

  return (
    <AlertForm
      title={"Crear Alerta"}
      goBack={() => navigate(-1)}
      submit={handlerSubmit}
      defaultValues={{
        instance: 0,
      }}
    />
  )
}

export default CreateAlertScreen
