import { Visibility, VisibilityOff } from '@mui/icons-material'
import { FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material'
import React, { useState } from 'react'
import { Controller } from 'react-hook-form'

const InputPasswordFieldGrid = ({ xs, name, control, required, label, pattern, disabled = false }) => {
  const [showPassword, setShowPassword] = useState(false)
  return (
    <Grid
      item
      xs={xs}
      alignContent="center"
      justifyContent={"center"}
      style={{ width: '100%' }}
    >
      <Controller
        name={name}
        control={control}
        defaultValue=""
        rules={!disabled && { required }}
        render={({
          field: { value, onChange },
          fieldState: { error },
        }) => (
          <FormControl sx={{ mb: 2 }} variant="outlined" fullWidth>
            <InputLabel htmlFor={name}>{label}</InputLabel>
            <OutlinedInput
              id={name}
              type={showPassword ? 'text' : 'password'}
              value={value}
              onChange={onChange}
              required={!!required}
              pattern={pattern}
              disabled={disabled}
              onBlur={(e) => onChange(e.target.value.trim())}
              endAdornment={
                <InputAdornment position="end" unselectable='on'>
                  <IconButton
                    disableFocusRipple={true}
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label={label}
            />
            {
              error && <FormHelperText sx={{ color: '#d32f2f' }}>{error.message}</FormHelperText>
            }
          </FormControl>
        )}
      />
    </Grid>
  )
}

export default InputPasswordFieldGrid