import moment from "moment";
import React, { useEffect, useState } from "react";
import { TableData } from "../../components";
import { errorAlert } from "../../helpers/alerts";
import { getPaymentsService } from "../../services/panelService";

const PanelPaymentsScreen = () => {
  const [list, setList] = useState([])
  useEffect(() => {
    getPaymentsService(0, 300)
      .then((response) => setList(response))
      .catch((error) => errorAlert(error))
  }, []);

  return (
    <TableData
      dataRows={list}
      dataTitle={[
        "Fecha Hora",
        "Bolsa",
        "Tipo",
        "Importe",
        // "Comercio",
        // "Usuario",
      ]}
      formatter={({
        createdAt,
        hosterBagId,
        etagSaldo,
        operacionSaldo,
        hashtag,
        username
      }) => ([
        moment(createdAt).format('DD/MM/YYYY HH:mm:ss '),
        hosterBagId?.hashtag,
        etagSaldo,
        `$ ${Number(operacionSaldo || 0).toFixed(2)}`,
        // hashtag,
        // username
      ])}
    />
  );
};

export default PanelPaymentsScreen;
