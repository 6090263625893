import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SegmentForm } from '../../../../components'
import { errorAlert } from '../../../../helpers/alerts';
import { hasSameValues } from '../../../../helpers/util';
import { updateSegment } from '../../../../redux/slices/admin/segmentSlice';
import { updateSegmentService } from '../../../../services/admin/segmentServices';

const ProductEditSegmentScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentSegment } = useSelector((store) => store.segments)
  const values = {
    name: currentSegment.name,
    description: currentSegment.description,
    code: currentSegment.code,
    carrierId: currentSegment.carrierId,
    file: currentSegment.fd
  }

  const handlerBack = () => navigate(-1);

  const handlerSubmit = async (data) => {
    updateSegmentService(currentSegment.idOperator, currentSegment.id, data)
      .then(response => {
        dispatch(updateSegment({
          id: currentSegment.id,
          data: response
        }))
        navigate(-1);
      }).catch(error => errorAlert(error))
  }

  const validate = (data) => !hasSameValues(values, data)

  return (
    <SegmentForm
      title="Editar Segmento"
      goBack={handlerBack}
      defaultValues={values}
      submit={handlerSubmit}
      validate={validate}
    />
  )
}

export default ProductEditSegmentScreen